import React, { useEffect, useMemo, useState } from "react";
import Grid from "@mui/material/Grid";
import { Box, Typography } from "@mui/material";
// import { Link } from "react-scroll"; // Import Link from react-scroll

import {
  GoogleMap,
  InfoWindowF,
  MarkerF,
  useLoadScript,
} from "@react-google-maps/api";
import { CareProviderProps, LocationProps } from "../../models/care.details";
import { compareStartTimeDesc, limitString } from "../../utils/utils";
import BackdropLoader from "../../components/Common/Loading/BackdropLoader";
import { clearCookie, getCookie } from "../../hooks/use-cookie";
import { useHistory } from "react-router-dom";
import MHLink from "../../components/Common/Link/MHLink";

import MuiLink from "@mui/material/Link";
import { Link } from "react-router-dom";
import CareMapCard from "../../components/CareProvider/global/CareMapCard";
import Pagination from "../../components/Common/UI/Pagination";
import CareCard from "../../components/CareProvider/global/CareCard";
import MiniFooter from "../Landing/MiniFooter";
import { MHPagination } from "../../components/Common/UI/MHPagination";
import { fetchState } from "../../hooks/get-location";

type Props = {};

const CareProviderMap = (props: Props) => {
  const [selectedMarkerId, setSelectedMarkerId] = useState<number | null>(null);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: (process.env.REACT_APP_GOOGLE_API_KEY as string) || "",
  });
  // Ikeja Lat & Long
  // const defaultCenter = {
  //   lat: 6.605874,
  //   lng: 3.349149,
  // };

  const resetLocation = () => {
    clearCookie("_mhLatCookie");
    clearCookie("_mhLngCookie");
    window.location.reload();
  };

  // const [selectedMarkerId, setSelectedMarkerId] = React.useState<number | null>(
  //   null
  // );

  const handleMarkerClick = (id: number) => {
    setSelectedMarkerId(id);

    // Scroll to the corresponding CareMapCard
    document.getElementById(`careCard_${id}`)?.scrollIntoView({
      behavior: "smooth",
    });
    // console.log("Map ID", id);
  };

  let history = useHistory();

  const [careData, setCareData] = useState<CareProviderProps[]>([]);

  var careUrl = `${process.env.REACT_APP_CARE_CENTER_API}/search?status=ACTIVE`;

  const getCare = async () => {
    try {
      const response = await fetch(careUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      let jsonData = await response.json();

      setCareData(jsonData.data);
      // console.log("jsonData Map", jsonData.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getCare();
    // multipleCare();
  }, []);

  // Filtered resources using startTime

  const existingLat = Number(getCookie("_mhLatCookie"));
  const existingLng = Number(getCookie("_mhLngCookie"));

  const userState = localStorage.getItem("userState");

  fetchState(Number(existingLat), Number(existingLng));
  console.log("userState", userState);

  // Filter careData based on userState and sort based on verified status
  const filteredCareData = careData
    // .filter((careProvider) => careProvider.State === userState)
    .filter((careProvider) => {
      // Ensure userState is not null before checking includes
      if (!userState) return false;
      return `${careProvider.State} ${careProvider.address}`.includes(
        userState
      );
    })
    .sort((a, b) => {
      // Check if both careProviders are verified or not
      if (a.verified === b.verified) return 0; // If both are same, maintain their order
      return a.verified ? -1 : 1; // Verified first, then unverified
    });

  const dateFilteredData = filteredCareData.sort(compareStartTimeDesc);

  console.log("dateFilteredData", dateFilteredData);

  const [currentPage, setCurrentPage] = React.useState(1);
  const [postsPerPage, setPostsPerPage] = React.useState(30);
  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const currentPosts = dateFilteredData.slice(firstPostIndex, lastPostIndex);

  let itemsPerPage = 30;
  let noOfPage = Math.ceil((dateFilteredData?.length || 0) / itemsPerPage);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
  };

  // console.log("careData", careData);

  // const { lat, lng } = useGeolocation();
  // const center = useMemo(() => ({ lat: lat, lng: lng }), [lat, lng]);
  // const center = useMemo(() => ({ lat: 6.4276595, lng: 3.3306633 }), [0]);

  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [zoomLevel, setZoomLevel] = useState(12); // Initial zoom level, can be any value
  const [locationAvailable, setLocationAvailable] = useState(false);

  const mainCenter = useMemo(
    () => ({ lat: existingLat, lng: existingLng }),
    [existingLat, existingLng]
  );

  useEffect(() => {
    if (existingLat !== 0 && existingLng !== 0) {
      setLocationAvailable(true);
    }
  }, [existingLat, existingLng]);

  // const onLoad = (mapInstance: google.maps.Map) => {
  //   setMap(mapInstance);

  //   if (locationAvailable) {
  //     const bounds = new google.maps.LatLngBounds();
  //     filteredCareData.forEach(({ locations }) => {
  //       if (locations) {
  //         locations.forEach((location: LocationProps) => {
  //           if (location.latitude && location.longitude) {
  //             const latLng = new google.maps.LatLng(
  //               Number(existingLat),
  //               Number(existingLng)
  //             );
  //             bounds.extend(latLng);
  //           }
  //         });
  //       }
  //     });

  //     mapInstance.fitBounds(bounds);

  //     // Set the mapId after loading the map instance
  //     mapInstance.setOptions({
  //       mapId: "9979eef602f240c8", // Your custom Map ID
  //     });
  //   }
  // };

  // const onMapIdle = () => {
  //   if (locationAvailable && map) {
  //     // Set the zoom level after the map has fully loaded and the bounds are set
  //     map.setZoom(zoomLevel);
  //   }
  // };

  const onLoad = (mapInstance: google.maps.Map) => {
    setMap(mapInstance);
    setZoomLevel(12); // Explicitly set zoom level to 12 on load

    // Set the mapId after loading the map instance
    mapInstance.setOptions({
      mapId: "9979eef602f240c8", // Your custom Map ID
    });
  };

  const onZoomChanged = () => {
    if (map) {
      const newZoomLevel = map.getZoom() || 12; // Get the current zoom level
      setZoomLevel(newZoomLevel); // Update the zoomLevel state dynamically
    }
  };

  // console.log("Load Data", existingLat, existingLng, center.lat, center.lng);

  if (existingLat === 0 || existingLng === 0) {
    history.push("/");
  }
  if (careData.length === 0 || !isLoaded) {
    // console.log("Data", careData.length, existingLat, existingLng, !isLoaded);
    return <BackdropLoader open={true} />;
  }

  return (
    <React.Fragment>
      {/* Web */}

      <Grid container spacing={0} className=" hidden md:flex">
        <Grid
          item
          xs={8}
          sm={8}
          md={6}
          lg={6}
          style={{ height: "100vh", overflowY: "auto" }}
          className=" border-r-2 border-blue-300"
        >
          <Box mt={2} className=" md:ml-4 lg:ml-[75px]">
            <Typography
              variant="h1"
              fontSize={{ xs: "20px", sm: "30px", md: "30px" }}
              textTransform="capitalize"
              sx={{
                mb: 1,
              }}
            >
              {careData && filteredCareData.length} Results
            </Typography>
            <Typography
              variant="body1"
              color="primary.main"
              fontSize={{ sm: "14px", md: "16px" }}
              lineHeight={"150%"}
              letterSpacing={"0.02em"}
              mx="auto"
            >
              Find and book the best child care near you
            </Typography>

            <MHLink
              type="default"
              onClick={resetLocation}
              className=" no-underline"
            >
              <Typography
                variant="caption"
                fontFamily="Area-Normal-Black"
                color="primary.main"
                align="left"
                fontSize="0.8rem"
                lineHeight="120%"
                mb={2}
                gutterBottom
                className="pt-3 line-clamp-2 capitalize !no-underline"
              >
                Reset location
              </Typography>
            </MHLink>
          </Box>
          <Box className="h-fit">
            <Grid
              container
              //   spacing={{ xs: 0, sm: 0, md: 0 }}
              className=" text-center py-4 mx-auto place-content-center items-center"
              alignContent={"center"}
              alignItems={"center"}
              alignSelf={"center"}
            >
              {currentPosts &&
                currentPosts.map((data: any, index: any) => (
                  <Grid item xs={12} sm={12} md={10} lg={10} key={index}>
                    <Box
                      key={data.customerId}
                      id={`careCard_${data.customerId}`}
                    >
                      {/* Add a unique id for each CareMapCard */}
                      {/* <Link
                        to={`careCard_${data.customerId}`}
                        smooth
                        className="no-underline"
                      > */}
                      {/* Create a Link to scroll to the CareMapCard */}
                      <CareMapCard
                        id={data.id}
                        legalBusinessName={data.legalBusinessName}
                        // busRating={data.busRating}
                        city={data.city}
                        address={data.address}
                        State={data.State}
                        availability={data.startDay + " - " + data.endDay}
                        openingTime={data.openingTime}
                        closingTime={data.closingTime}
                        // charges={data.charges}
                        slug={data.slug}
                        customerId={data.customerId}
                        documentList={data.documentList}
                        verified={data.verified}
                      />
                      {/* </Link> */}
                    </Box>
                  </Grid>
                ))}
            </Grid>
          </Box>

          {/* <Pagination
            totalPosts={careData.length}
            postsPerPage={postsPerPage}
            onClick={() => {
              setCurrentPage(currentPage - 1);
            }}
            onClicked={() => {
              setCurrentPage(currentPage + 1);
            }}
            currentPage={currentPage}
          /> */}
          <Box display={"flex"} justifyContent={"center"} my={2}>
            <MHPagination
              count={noOfPage}
              variant="outlined"
              shape="rounded"
              page={currentPage}
              onChange={handlePageChange}
              color={"primary"}
            />
          </Box>
        </Grid>
        <Grid item xs={4} sm={4} md={6} lg={6}>
          {/* <Box className="h-full bg-green-200"></Box> */}
          {!existingLat ? (
            <h1>Loading...</h1>
          ) : (
            <GoogleMap
              mapContainerClassName="map-container"
              onLoad={onLoad}
              onIdle={onZoomChanged} // Call onZoomChanged whenever the user zooms
              center={mainCenter}
              zoom={zoomLevel}
              mapTypeId="terrain" // Set the map type to terrain
            >
              {/* Add a pin at the center location */}
              <MarkerF
                position={mainCenter}
                icon={{
                  url: "https://maps.google.com/mapfiles/ms/icons/red-dot.png", // Default Google Maps red pin
                  scaledSize: {
                    width: 40,
                    height: 40,
                    equals: (otherSize: any | null) => {
                      if (!otherSize) return false; // handle null case
                      return otherSize.width === 40 && otherSize.height === 40;
                    },
                  },
                }}
              />
              {currentPosts.map(
                ({
                  locations,
                  customerId,
                  legalBusinessName,
                  verified,
                  State,
                  slug,
                }) => {
                  const markers = locations?.map((location, index) => {
                    const position = {
                      lat: Number(location.latitude),
                      lng: Number(location.longitude),
                    };

                    return (
                      <MarkerF
                        key={`${customerId}_${index}`}
                        position={position}
                        onClick={() => handleMarkerClick(customerId)}
                        onMouseOver={() => setSelectedMarkerId(customerId)}
                        onMouseOut={() => setSelectedMarkerId(null)}
                        icon={{
                          url:
                            verified === true
                              ? "https://mh-resources-production.s3.us-west-1.amazonaws.com/caf-home-icon.png"
                              : "https://mh-resources-production.s3.us-west-1.amazonaws.com/cb-home-icon2.png",
                          scaledSize: {
                            width: 40,
                            height: 40,
                            equals: (otherSize: any | null) => {
                              if (!otherSize) return false; // handle null case
                              return (
                                otherSize.width === 40 &&
                                otherSize.height === 40
                              );
                            },
                          },
                        }}
                      >
                        {selectedMarkerId === customerId && (
                          <InfoWindowF
                            position={position}
                            options={{
                              disableAutoPan: true, // Optional: Prevent map from auto-panning when the InfoWindow opens
                            }}
                          >
                            <Box sx={{}} width="200px" height="fit">
                              <MuiLink
                                component={Link}
                                to="#"
                                underline="none"
                                onClick={() => {
                                  history.push(
                                    `/view-care/${slug}/${customerId}`
                                  );
                                }}
                                className="removeUnderline"
                              >
                                <Box
                                  sx={{
                                    backgroundColor: "white",
                                    padding: "8px",
                                    borderRadius: "8px",
                                    textDecoration: "none",
                                  }}
                                >
                                  <Typography
                                    variant="caption"
                                    fontSize={{
                                      xs: "13px",
                                      sm: "13px",
                                      md: "13px",
                                    }}
                                    lineHeight={"130%"}
                                    letterSpacing={"-0.04em"}
                                    sx={{
                                      fontWeight: "bold",
                                      marginBottom: "4px",
                                      textDecoration: "none",
                                    }}
                                    className="removeUnderline line-clamp-2 hover:text-blue-100"
                                  >
                                    {legalBusinessName}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    fontSize={{
                                      xs: "14px",
                                      sm: "14px",
                                      md: "14px",
                                    }}
                                    lineHeight={"130%"}
                                    letterSpacing={"-0.04em"}
                                    className="removeUnderline"
                                  >
                                    {State}
                                  </Typography>
                                </Box>
                              </MuiLink>
                              <style>
                                {`
                                /* Target the close button in Google Maps InfoWindow */
                                .gm-style-iw button[aria-label="Close"] {
                                  display: none !important;
                                }
                              `}
                              </style>
                            </Box>
                          </InfoWindowF>
                        )}
                      </MarkerF>
                    );
                  });

                  return (
                    <React.Fragment key={customerId}>{markers}</React.Fragment>
                  );
                }
              )}
            </GoogleMap>
          )}
        </Grid>
      </Grid>

      {/* Mobile */}
      <Grid
        container
        //   spacing={{ xs: 0, sm: 0, md: 0 }}
        className=" text-center px-6 py-4 mx-auto place-content-center items-center md:hidden"
        alignContent={"center"}
        alignItems={"center"}
        alignSelf={"center"}
      >
        <Box mt={5} className="">
          <Typography
            variant="h1"
            fontSize={{ xs: "25px", sm: "30px", md: "40px" }}
            textTransform="capitalize"
            sx={{
              mb: 1,
            }}
          >
            {careData && filteredCareData.length} Results
          </Typography>
          <Typography
            variant="body1"
            color="primary.main"
            fontSize={{ sm: "14px", md: "16px" }}
            lineHeight={"150%"}
            letterSpacing={"0.02em"}
            mx="auto"
          >
            Find and book the best child care near you
          </Typography>
        </Box>
        {currentPosts &&
          currentPosts.map((data, index) => (
            <Grid item xs={12} sm={6} md={3} lg={3} key={index}>
              <CareCard
                legalBusinessName={data.legalBusinessName}
                busRating={data.busRating}
                city={data.city}
                address={data.address}
                State={data.State}
                ageRange={limitString(data.ageRange, 30)}
                availability={data.startDay + " - " + data.endDay}
                openingTime={data.openingTime}
                closingTime={data.closingTime}
                charges={data.charges}
                slug={data.slug}
                customerId={data.customerId}
                // feat1={data.feat1}
                // feat2={data.feat2}
                // feat3={data.feat3}
                // feat4={data.feat4}
                // imageList={imageListArray}
                documentList={data.documentList}
                verified={data.verified}
              />
            </Grid>
          ))}

        {/* <Pagination
          totalPosts={careData.length}
          postsPerPage={postsPerPage}
          onClick={() => {
            setCurrentPage(currentPage - 1);
          }}
          onClicked={() => {
            setCurrentPage(currentPage + 1);
          }}
          currentPage={currentPage}
        /> */}
        <Box display={"flex"} justifyContent={"center"} my={2}>
          <MHPagination
            count={noOfPage}
            variant="outlined"
            shape="rounded"
            page={currentPage}
            onChange={handlePageChange}
            color={"primary"}
          />
        </Box>
      </Grid>
      <MiniFooter />
    </React.Fragment>
  );
};

export default CareProviderMap;
