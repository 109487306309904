import React, { useState, createContext, ReactNode } from "react";

interface FormData {
  where: string;
  careCategory?: string;
  selectedCareType?: string;
  startDate: string;
  endDate: string;
  startDay: string;
  endDay: string;
  startTime: string | any;
  endTime: string | any;
  ageRange: string;
  careProviderName: string;
  careProviderId: number;
  availableDays: number;
  careHours: number;
  userId: number;
}

interface DataContextProps {
  formData: FormData;
  setFormData: React.Dispatch<React.SetStateAction<FormData>>;
  filledState: boolean;
  setFilledState: React.Dispatch<React.SetStateAction<boolean>>;
}

interface DataProviderProps {
  children: ReactNode;
}

export const DataContext = createContext<DataContextProps | any>({
  formData: {
    startDate: "",
    endDate: "",
    startDay: "",
    endDay: "",
    startTime: "",
    endTime: "",
    where: "",
    careCategory: "",
    selectedCareType: "",
    ageRange: "",
    careProviderName: "",
    careProviderId: 0,
    userId: 0,
    availableDays: 0,
    careHours: 0,
  },
  setFormData: () => {}, // Provide a dummy function as an initial value
  filledState: false,
  setFilledState: () => {}, // Provide a dummy function as an initial value
});

export default function DataProvider({
  children,
}: DataProviderProps): JSX.Element {
  const [formData, setFormData] = useState<FormData>({
    startDate: "",
    endDate: "",
    startDay: "",
    endDay: "",
    startTime: "",
    endTime: "",
    where: "",
    careCategory: "",
    selectedCareType: "",
    ageRange: "",
    careProviderName: "",
    careProviderId: 0,
    userId: 0,
    availableDays: 0,
    careHours: 0,
  });

  const [filledState, setFilledState] = useState<boolean>(false);

  return (
    <DataContext.Provider
      value={{
        formData,
        setFormData,
        filledState,
        setFilledState,
      }}
    >
      {children}
    </DataContext.Provider>
  );
}
