import React, { useState } from "react";
import { styled } from "@mui/system";
import { grey } from "@mui/material/colors";
import InputLabel from "@mui/material/InputLabel";
import AutoComplete from "react-google-autocomplete";

interface GoogleAutocompleteInputProps {
  placeholder?: string | undefined;
  onLocationSelected?: (location: string) => void;
}

// Styled Components
const StyledAutoComplete = styled(AutoComplete)(({ theme }) => ({
  fontFamily: "Area-Normal-Semibold",
  fontSize: "12px",
  boxSizing: "border-box",
  height: "calc(1.5em + 26px)",
  width: "185px",
  border: `1px solid ${grey[300]}`,
  borderRadius: "inherit",
  background: theme.palette.mode === "dark" ? grey[900] : "#fff",
  marginBottom: "1.25rem",
  padding: "12px 12px",
  textAlign: "left",
  lineHeight: 1.5,
  color: theme.palette.mode === "dark" ? grey[300] : grey[900],
  display: "flex",
  alignItems: "center",
  overflowX: "hidden",
  "&:hover": {
    borderColor: theme.palette.mode === "dark" ? grey[700] : grey[300],
  },
}));

const CustomInputLabel = styled(InputLabel)(({ theme }) => ({
  fontFamily: "Area-Normal-Semibold",
  fontSize: "12px",
  color: grey[700],
}));

// Component
const GoogleAutocompleteInput: React.FC<GoogleAutocompleteInputProps> = ({
  placeholder,
  onLocationSelected,
}) => {
  const [location, setLocation] = useState<string>("");

  const handlePlaceSelected = (place: google.maps.places.PlaceResult) => {
    // Extract the formatted address or relevant details
    const address = place.formatted_address || place.name || "";
    setLocation(address);

    // Return the location via a callback if needed
    if (onLocationSelected) {
      onLocationSelected(address);
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {/* <CustomInputLabel htmlFor="google-autocomplete">
        Enter a Location
      </CustomInputLabel> */}
      <StyledAutoComplete
        apiKey={process.env.REACT_APP_GOOGLE_API_KEY!}
        id="google-autocomplete"
        onPlaceSelected={handlePlaceSelected}
        options={{
          types: ["geocode"], // Change to "address" or other types if necessary
        }}
        defaultValue={location}
        placeholder={placeholder}
      />
    </div>
  );
};

export default GoogleAutocompleteInput;
