import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import MHButton from "../../components/Common/Button/MHButton";
import { useHistory } from "react-router-dom";
import Lottie from "react-lottie";

import * as animationData from "../../static/json-animate/Baby.json";
import BookingDrawer from "../../components/Layout/BookingDrawer";

type Props = {};

const Home = (props: Props) => {
  const verbiage = [
    "Hire, Vet, Onboard, Train, And Manage Household Contractors",
    "Pay Salary, Provide Benefits, And Retain Quality Worker",
    "Raise The Standard Of Care For Your Family and Household",
  ];

  let history = useHistory();

  const [textIndex, setTextIndex] = React.useState(0);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setTextIndex((prevIndex) => (prevIndex + 1) % verbiage.length);
    }, 3000);

    // Clear the interval when the component unmounts
    return () => clearInterval(interval);
  }, []);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  // Media query to check if the screen size matches mobile dimensions
  const isMobile = useMediaQuery("(max-width:600px)");

  const handleButtonClick = () => {
    if (isMobile) {
      handleDrawerOpen(); // Call the mobile handler
    } else {
      handleDrawerOpen(); // Call the default handler
    }
  };

  return (
    <React.Fragment>
      <div id="home"></div>

      <Grid
        container
        spacing={2}
        sx={{
          paddingTop: "20px",
          justifyContent: "center",
          alignItems: "center",
          // paddingTop: "80px",
          // paddingBottom: "100px",
          height: { xs: "800px", sm: "520px", md: "650px" },
          backgroundColor: "#ffffff",
          overflow: "hidden",
          overscrollBehaviorY: "hidden",
        }}
      >
        <Grid item xs={11} sm={5.5} md={4.8}>
          <Typography
            variant="subtitle1"
            color="primary"
            fontSize={{ xs: "30px", sm: "30px", md: "42px", xl: "55px" }}
            lineHeight={{ xs: "160.5%", sm: "120.5%" }}
            letterSpacing={"-4%"}
            className=" capitalize mx-auto md:mx-0 text-center md:text-start md:order-first order-last"
            width={{ xs: "80%", sm: "75%", md: "70%", xl: "70%" }}
          >
            {/* quality care and the peace of mind that comes with knowing they are
            in capable hands. */}
            We are closing the childcare gap for good.
            <br />
          </Typography>

          <Typography
            variant="body1"
            color="primary"
            fontSize={{ xs: "14px", sm: "12px", md: "24px" }}
            lineHeight={{ xs: "200%", sm: "120%" }}
            letterSpacing={"-4%"}
            className="py-8 capitalize mx-auto md:mx-0 text-center md:text-start h-32"
            width={{ xs: "90%", sm: "75%" }}
          >
            {/* Perfect for nannies, drivers, housekeepers, home tutors, and other
            household contractors you employ */}
            {verbiage[textIndex]} <br />
          </Typography>

          <Box className=" mx-auto flex gap-4">
            <MHButton
              // color="primary"
              sx={{}}
              type="submit"
              color="secondary"
              className="mb-12  lg:mb-24 uppercase w-full md:w-[220px] h-[54px]"
              // startIcon={<PlusIcon />}
              onClick={handleButtonClick}
              // onClick={() => history.push("/auth/onboard")}
            >
              {/* Start Free Trial */}
              <Typography
                variant="caption"
                color="primary"
                fontSize={{ xs: "14px", sm: "14px", md: "14px" }}
                lineHeight={{ xs: "200%", sm: "120%" }}
                // letterSpacing={"-4%"}
                className=" capitalize "
                // width={{ xs: "90%", sm: "75%" }}
              >
                Find Care
              </Typography>
            </MHButton>
            <MHButton
              color="primary"
              sx={{}}
              type="submit"
              className="mb-12  lg:mb-24 uppercase w-full md:w-[220px] h-[54px]"
              // startIcon={<PlusIcon />}
              // onClick={handleOpenEmployee}
              onClick={() => history.push("/auth/onboard")}
            >
              {/* Start Free Trial */}
              {/* Get Started */}
              <Typography
                variant="caption"
                // color="#fff"
                fontSize={{ xs: "14px", sm: "14px", md: "14px" }}
                lineHeight={{ xs: "200%", sm: "120%" }}
                // letterSpacing={"-4%"}
                className=" capitalize text-white hover:text-navy-900"
                // width={{ xs: "90%", sm: "75%" }}
              >
                Onboard
              </Typography>
            </MHButton>
          </Box>
        </Grid>
        <Grid
          item
          xs={11}
          sm={5.5}
          md={4.8}
          sx={{ display: { xs: "grid", sm: "grid", justifyContent: "center" } }}
          className=" md:order-last order-first"
        >
          {/* <img src="mama.png" alt="CaringBlocks" className=" lg:h-[469px]" /> */}
          <Lottie options={defaultOptions} height={"100%"} />
        </Grid>
      </Grid>

      <BookingDrawer open={drawerOpen} onClose={handleDrawerClose} />
      {/* <MobileBookingDrawer
        open={drawerMobOpen}
        onClose={handleDrawerMobClose}
      /> */}
    </React.Fragment>
  );
};

export default Home;
