import { Box, Divider, Grid, TextField, Typography } from "@mui/material";
import React from "react";

import MHFormControl from "../../Common/Form/MHFormControl";
import InputAdornment from "../../Common/Form/InputAdornment";
import * as validators from "../../../utils/validators";
import { ReactComponent as EditIcon } from "../../../static/svg/edit-black.svg";
import { ReactComponent as Visibility } from "../../../static/svg/visibility.svg";
import { ReactComponent as VisibilityOff } from "../../../static/svg/visibility-off.svg";

import useInput from "../../../hooks/use-input";
import MHTextInput from "../../Common/Form/MHTextInput";
import MHPasswordControl from "../../Common/Form/MHPasswordControl";
import MHButton from "../../Common/Button/MHButton";
import AuthContext from "../../../store/context/auth-context";
import DashboardContext from "../../../store/context/dashboard.context";
import useDialog from "../../../hooks/use-dialog";
import PinDialog from "./PinDialog";
import { Organization } from "../../../models/employer.model";
import useHttp from "../../../hooks/use-http";
import { HttpResponse } from "../../../models/api.interface";
import SnackbarContext from "../../../store/context/snackbar.context";
import { CareProviderProps } from "../../../models/care.details";
import { convertUTCToDateTime } from "../../../utils/utils";

import dayjs, { Dayjs } from "dayjs";
// import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import {
  LocalizationProvider,
  TimePicker,
  // AdapterDayjs,
} from "@mui/x-date-pickers";
import MHSelectMultiple from "../../Common/Form/MHSelectMultiple";
import { childcareAge, WEEKDAYS } from "../../../utils/careprovider";
import MHAutocomplete, { Option } from "../../Common/Form/MHAutocomplete";

type Props = {};

const AccountSettings = (props: Props) => {
  const authCtx = React.useContext(AuthContext);
  const { user } = authCtx;

  const snackbarCtx = React.useContext(SnackbarContext);
  const { toast } = snackbarCtx;

  const dashboardCtx = React.useContext(DashboardContext);
  const { carecenter, updateCenterRecord } = dashboardCtx;

  // fetchCareCenterData(1568463112);
  const caredata = carecenter?.careCenter?.[0];

  // console.log("caredata", caredata);

  const [disabledState, setDisabledState] = React.useState({
    fname: true,
    state: true,
    email: true,
    phone: true,
    address: true,
    city: true,
    web: true,
    bio: true,
  });

  const {
    value: enteredFirstName,
    valid: enteredFirstNameIsValid,
    error: firstNameInputHasError,
    onChange: firstNameInputChangeHandler,
    onBlur: firstNameInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredCity,
    valid: enteredCityIsValid,
    error: cityInputHasError,
    onChange: cityInputChangeHandler,
    onBlur: cityInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredState,
    valid: enteredStateIsValid,
    error: bioInputHasError,
    onChange: stateInputChangeHandler,
    onBlur: stateInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredEmail,
    valid: enteredEmailIsValid,
    error: enteredEmailHasError,
    onChange: emailInputChangeHandler,
    onBlur: emailInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
    {
      validator: (value: string) => validators.email(value),
    },
  ]);

  const {
    value: enteredAddress,
    valid: enteredAddressIsValid,
    error: addressInputHasError,
    onChange: addressInputChangeHandler,
    onBlur: addressInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredPhone,
    valid: enteredPhoneIsValid,
    error: phoneInputHasError,
    onChange: phoneInputChangeHandler,
    onBlur: phoneInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredWeb,
    valid: enteredWebIsValid,
    error: webInputHasError,
    onChange: webInputChangeHandler,
    onBlur: webInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const handleCancel = () => {
    console.log("Reset all inputs");
  };

  // fetchCareCenterData(1568463112);

  // console.log("carecenter", carecenter);

  const handleSaveAccount = async () => {
    // console.log("Save new account settings");
    // console.log("Save new account settings");

    // console.log("enteredPhone", enteredPhone);
    // console.log("enteredAddress", enteredAddress);

    if (!updateCenterRecord) {
      console.error("updateCenterRecord is not defined in DashboardContext");
      return;
    }

    const updatedCenter: CareProviderProps = {
      ...caredata,
      legalBusinessName: enteredFirstName,
      state: enteredState,
      State: enteredState,
      businessEmail: enteredEmail,
      address: enteredAddress,
      features: enteredWeb,
      careProviderBio: enteredCity,
      businessTelephone: enteredPhone,
      // Add other properties as needed
    };

    console.log(updatedCenter);

    // updateCenterRecord(updatedCenter);
    await updateCenterRecord(updatedCenter);
    toast({
      message: "Center updated successfully.",
      variant: "success",
    });
    window.location.reload();
  };

  return (
    <React.Fragment>
      <Box className="bg-white w-full drop-shadow-lg py-6" id="accountsetting">
        <Box className="pt-3 pl-6 lg:pl-12">
          <Typography
            variant="body1"
            mb={1}
            color="#28404A"
            gutterBottom
            // display={"flex"}
            // alignSelf={"start"}
            fontSize={{ xs: "15px", sm: "15px", md: "15px" }}
            className=""
          >
            Business Information
          </Typography>
          <Typography
            variant="body1"
            mb={2}
            color="#A9A9A9"
            gutterBottom
            // display={"flex"}
            // alignSelf={"start"}
            fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
            className=""
          >
            The information here can be edited
          </Typography>
        </Box>

        <Divider />

        <Box className=" lg:flex lg:flex-row lg:justify-center gap-6 pt-8 px-4 md:px-4 lg:px-0 space-y-3 md:space-y-0">
          <Box className="col-span-1 md:col-span-1 lg:basis-[28%]">
            <label
              className=""
              style={{
                color: "#21392E",
              }}
            >
              Business Name
            </label>
            <MHTextInput
              id="firstName"
              type="text"
              // label="First Name"
              placeholder=""
              value={
                enteredFirstName === ""
                  ? caredata?.legalBusinessName
                  : enteredFirstName
              }
              onChange={firstNameInputChangeHandler}
              onBlur={firstNameInputBlurHandler}
              disabled={disabledState.fname}
              className={`${
                disabledState.fname === true ? "bg-[#D9D9D9]" : "bg-white"
              }`}
              endAdornment={
                <InputAdornment
                  className="bg-[#D9D9D9] md:h-[43px] text-navy-900 cursor-pointer"
                  onClick={() => {
                    setDisabledState({
                      ...disabledState,
                      fname: !disabledState.fname,
                    });
                  }}
                >
                  <EditIcon className=" text-navy-900 2-6 h-6" />
                </InputAdornment>
              }
              required
              autoFocus
            />
          </Box>

          <Box className=" col-span-1 md:col-span-1 lg:basis-[28%]">
            <label
              className=""
              style={{
                color: "#21392E",
              }}
            >
              Business Email
            </label>
            <MHTextInput
              id="email"
              type="email"
              // label="Email"
              placeholder=""
              value={caredata?.businessEmail}
              // onChange={roleInputChangeHandler}
              // onBlur={roleInputBlurHandler}
              disabled={disabledState.email}
              className={`${
                disabledState.email === true ? "bg-[#D9D9D9]" : "bg-white"
              }`}
              // endAdornment={
              //   <InputAdornment
              //     className="bg-[#D9D9D9] md:h-[43px] text-navy-900 cursor-pointer"
              //     onClick={() => {
              //       setDisabledState({
              //         ...disabledState,
              //         fname: !disabledState.fname,
              //       });
              //     }}
              //   >
              //     <EditIcon className=" text-navy-900 2-6 h-6" />
              //   </InputAdornment>
              // }
              required
              autoFocus
            />
          </Box>

          <Box className=" col-span-1 md:col-span-1 lg:basis-[28%] -mt-6 lg:mt-0">
            <label
              className=""
              style={{
                color: "#21392E",
              }}
            >
              Phone number
            </label>
            <MHTextInput
              id="text"
              type="text"
              // label="phone"
              placeholder=""
              value={
                enteredPhone === "" ? caredata?.businessTelephone : enteredPhone
              }
              onChange={phoneInputChangeHandler}
              onBlur={phoneInputBlurHandler}
              disabled={disabledState.phone}
              className={`${
                disabledState.phone === true ? "bg-[#D9D9D9]" : "bg-white"
              }`}
              endAdornment={
                <InputAdornment
                  className="bg-[#D9D9D9] md:h-[43px] text-navy-900 cursor-pointer"
                  onClick={() => {
                    setDisabledState({
                      ...disabledState,
                      phone: !disabledState.phone,
                    });
                  }}
                >
                  <EditIcon className=" text-navy-900 2-6 h-6" />
                </InputAdornment>
              }
              required
              autoFocus
            />
          </Box>
        </Box>

        <Box className=" lg:flex lg:flex-row lg:justify-center gap-6 py-8 px-4 md:px-4 lg:px-0 space-y-3 md:space-y-0">
          <Box className=" col-span-1 md:col-span-1 lg:basis-[28%]">
            <label
              className=""
              style={{
                color: "#21392E",
              }}
            >
              Address
            </label>
            <MHTextInput
              id="text"
              type="text"
              // label="address"
              placeholder=""
              value={enteredAddress === "" ? caredata?.address : enteredAddress}
              onChange={addressInputChangeHandler}
              onBlur={addressInputBlurHandler}
              disabled={disabledState.address}
              className={`${
                disabledState.address === true ? "bg-[#D9D9D9]" : "bg-white"
              }`}
              endAdornment={
                <InputAdornment
                  className="bg-[#D9D9D9] md:h-[43px] text-navy-900 cursor-pointer"
                  onClick={() => {
                    setDisabledState({
                      ...disabledState,
                      address: !disabledState.address,
                    });
                  }}
                >
                  <EditIcon className=" text-navy-900 2-6 h-6" />
                </InputAdornment>
              }
              required
              autoFocus
            />
          </Box>

          <Box className=" col-span-1 md:col-span-1 lg:basis-[28%]">
            <label
              className=""
              style={{
                color: "#21392E",
              }}
            >
              Website
            </label>
            <MHTextInput
              id="text"
              type="text"
              // label="role"
              placeholder=""
              value={enteredWeb === "" ? caredata?.features : enteredWeb}
              onChange={webInputChangeHandler}
              onBlur={webInputBlurHandler}
              disabled={disabledState.web}
              className={`${
                disabledState.web === true ? "bg-[#D9D9D9]" : "bg-white"
              }`}
              endAdornment={
                <InputAdornment
                  className="bg-[#D9D9D9] md:h-[43px] text-navy-900 cursor-pointer"
                  onClick={() => {
                    setDisabledState({
                      ...disabledState,
                      web: !disabledState.web,
                    });
                  }}
                >
                  <EditIcon className=" text-navy-900 2-6 h-6" />
                </InputAdornment>
              }
              required
              autoFocus
            />
          </Box>

          <Box className=" col-span-1 md:col-span-1 lg:basis-[28%]">
            <label
              className=""
              style={{
                color: "#21392E",
              }}
            >
              State
            </label>
            <MHTextInput
              id="lastName"
              type="text"
              // label="First Name"
              placeholder=""
              value={enteredState === "" ? caredata?.State : enteredState}
              onChange={stateInputChangeHandler}
              onBlur={stateInputBlurHandler}
              disabled={disabledState.state}
              className={`${
                disabledState.state === true ? "bg-[#D9D9D9]" : "bg-white"
              }`}
              endAdornment={
                <InputAdornment
                  className="bg-[#D9D9D9] md:h-[43px] text-navy-900 cursor-pointer"
                  onClick={() => {
                    setDisabledState({
                      ...disabledState,
                      state: !disabledState.state,
                    });
                  }}
                >
                  <EditIcon className=" text-navy-900 2-6 h-6" />
                </InputAdornment>
              }
              required
              autoFocus
            />
          </Box>
        </Box>

        {/* <Box className=" lg:flex lg:flex-row lg:justify-center gap-6 pt-0 px-4 md:px-4 lg:px-0 space-y-3 md:space-y-0">
          <Box className=" col-span-1 md:col-span-1 lg:basis-[28%]">
            <label
              className=""
              style={{
                color: "#21392E",
              }}
            >
              Website
            </label>
            <MHTextInput
              id="lastName"
              type="text"
              // label="First Name"
              placeholder=""
              value={enteredWeb === "" ? caredata?.features : enteredWeb}
              onChange={webInputChangeHandler}
              onBlur={webInputBlurHandler}
              disabled={disabledState.web}
              className={`${
                disabledState.web === true ? "bg-[#D9D9D9]" : "bg-white"
              }`}
              endAdornment={
                <InputAdornment
                  className="bg-[#D9D9D9] md:h-[43px] text-navy-900 cursor-pointer"
                  onClick={() => {
                    setDisabledState({
                      ...disabledState,
                      web: !disabledState.web,
                    });
                  }}
                >
                  <EditIcon className=" text-navy-900 2-6 h-6" />
                </InputAdornment>
              }
              required
              autoFocus
            />
          </Box>
        </Box> */}

        <Box className=" lg:flex lg:flex-row lg:justify-center gap-6 pb-6 px-4 md:px-4 lg:px-0 space-y-3 md:space-y-0">
          <Box className=" col-span-1 md:col-span-1 lg:basis-[90%]">
            <label
              className=""
              style={{
                color: "#21392E",
              }}
            >
              About
            </label>
            <MHTextInput
              id="text"
              type="text"
              // label="address"
              multiline
              rows={4}
              placeholder=""
              value={
                enteredCity === "" ? caredata?.careProviderBio : enteredCity
              }
              onChange={cityInputChangeHandler}
              onBlur={cityInputBlurHandler}
              disabled={disabledState.bio}
              className={`${
                disabledState.bio === true ? "bg-[#D9D9D9]" : "bg-white"
              }`}
              endAdornment={
                <InputAdornment
                  className="bg-[#D9D9D9] md:h-[43px] text-navy-900 cursor-pointer"
                  onClick={() => {
                    setDisabledState({
                      ...disabledState,
                      bio: !disabledState.bio,
                    });
                  }}
                >
                  <EditIcon className=" text-navy-900 2-6 h-6" />
                </InputAdornment>
              }
              required
              autoFocus
            />
          </Box>
        </Box>

        <Divider />

        <Box
          display={{ xs: "flex", sm: "flex" }}
          justifyContent="end"
          width={{ xs: "full", sm: "full" }}
          className="gap-4 md:!pr-[50px] py-6 px-4 md:px-0 "
        >
          <MHButton
            sx={{ height: "38px", width: "158px", color: "white" }}
            onClick={handleCancel}
            className=" bg-white text-navy-900 ring-1 ring-navy-900 rounded-sm"
            fullWidth
          >
            Cancel
          </MHButton>

          <MHButton
            sx={{ height: "38px", width: { xs: "full", sm: "158px" } }}
            onClick={handleSaveAccount}
            fullWidth
            className="rounded-sm"
          >
            Save Changes
          </MHButton>
        </Box>
      </Box>
    </React.Fragment>
  );
};

const OtherSettings = (props: Props) => {
  const authCtx = React.useContext(AuthContext);
  const { user } = authCtx;

  const snackbarCtx = React.useContext(SnackbarContext);
  const { toast } = snackbarCtx;

  const dashboardCtx = React.useContext(DashboardContext);
  const { carecenter, updateCenterRecord } = dashboardCtx;

  // fetchCareCenterData(1568463112);
  const caredata = carecenter?.careCenter?.[0];

  // console.log("caredata", carecenter);

  const [disabledState, setDisabledState] = React.useState({
    fname: true,
    state: true,
    email: true,
    phone: true,
    address: true,
    city: true,
  });

  const [openingValue, setOpeningValue] = React.useState<Dayjs | null>(
    dayjs("2022-04-17T15:30")
  );
  const [closingValue, setClosingValue] = React.useState<Dayjs | null>(
    dayjs("2022-04-17T15:30")
  );
  // console.log(openingValue?.format());
  const timecheck = (date: any) => {
    const mydate = dayjs("2022-04-17T15:30").format().toString();
    let datestring = date.format().toString();
    // console.log("my date", mydate === datestring);
    return datestring === mydate;
  };

  const [ageRange, setAgeRange] = React.useState<string>("");

  const handleChildrenInput = (selected: string[]) => {
    setAgeRange(
      selected ? selected.join(", ") : "" // Convert array back to string
    );
  };

  // console.log(ageRange);
  const {
    value: enteredState,
    valid: enteredStateIsValid,
    error: lastNameInputHasError,
    onChange: stateInputChangeHandler,
    onBlur: stateInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredAddress,
    valid: enteredAddressIsValid,
    error: addressInputHasError,
    onChange: addressInputChangeHandler,
    onBlur: addressInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredInfo,
    valid: enteredInfoIsValid,
    error: infoInputHasError,
    onChange: infoInputChangeHandler,
    onBlur: infoInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredPhone,
    valid: enteredPhoneIsValid,
    error: phoneInputHasError,
    onChange: phoneInputChangeHandler,
    onBlur: phoneInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const handleCancel = () => {
    console.log("Reset all inputs");
  };

  const weekdaysOption: Option[] = WEEKDAYS.map((role) => ({
    label: role as string,
    value: role as string,
  }));

  const handleSaveAccount = async () => {
    // console.log("Save new account settings");
    // console.log("Save new account settings");

    // console.log("enteredPhone", enteredPhone);
    // console.log("enteredAddress", enteredAddress);

    if (!updateCenterRecord) {
      console.error("updateCenterRecord is not defined in DashboardContext");
      return;
    }

    const updatedCenter: CareProviderProps = {
      ...caredata,
      openingTime:
        timecheck(openingValue) === true ? caredata?.openingTime : openingValue,
      closingTime:
        timecheck(closingValue) === true ? caredata?.closingTime : closingValue,
      registrationNo:
        enteredPhone === "" ? caredata?.registrationNo : enteredPhone,
      startDay: enteredAddress === "" ? caredata?.startDay : enteredAddress,
      endDay: enteredInfo === "" ? caredata?.endDay : enteredInfo,
      ageRange: ageRange === "" ? caredata?.ageRange : ageRange,
      verified: caredata?.verified,
      // Add other properties as needed
    };

    console.log(updatedCenter);

    // updateCenterRecord(updatedCenter);
    await updateCenterRecord(updatedCenter);
    toast({
      message: "Center updated successfully.",
      variant: "success",
    });
    window.location.reload();
  };

  return (
    <React.Fragment>
      <Box className="bg-white w-full drop-shadow-lg py-6" id="accountsetting">
        <Box className="pt-3 pl-6 lg:pl-12">
          <Typography
            variant="body1"
            mb={1}
            color="#28404A"
            gutterBottom
            // display={"flex"}
            // alignSelf={"start"}
            fontSize={{ xs: "15px", sm: "15px", md: "15px" }}
            className=""
          >
            Extra Information
          </Typography>
          <Typography
            variant="body1"
            mb={2}
            color="#A9A9A9"
            gutterBottom
            // display={"flex"}
            // alignSelf={"start"}
            fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
            className=""
          >
            Please review extra informations.
          </Typography>
        </Box>

        <Divider />

        <Box className=" lg:flex lg:flex-row lg:justify-center gap-6 pt-8 px-4 md:px-4 lg:px-0 space-y-3 md:space-y-0">
          <Box className="col-span-1 md:col-span-1 lg:basis-[28%]">
            <label
              className=""
              style={{
                color: "#21392E",
              }}
            >
              Opening Hours
            </label>
            <br />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                // label="Opening Hours"
                value={
                  timecheck(openingValue) === true
                    ? dayjs(caredata?.openingTime)
                    : openingValue
                }
                onChange={(newValue) => {
                  if (newValue) {
                    setOpeningValue(newValue);
                    console.log("Selected Time:", newValue.toISOString());
                  }
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Box>

          <Box className=" col-span-1 md:col-span-1 lg:basis-[28%]">
            <label
              className=""
              style={{
                color: "#21392E",
              }}
            >
              Closing Hours
            </label>
            <br />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                // label="Closing Hours"
                value={
                  timecheck(closingValue) === true
                    ? dayjs(caredata?.closingTime)
                    : closingValue
                }
                onChange={(newValue) => {
                  if (newValue) {
                    setClosingValue(newValue);
                    console.log("Selected Time:", newValue.toISOString());
                  }
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Box>

          <Box className=" col-span-1 md:col-span-1 lg:basis-[28%]">
            <label
              className=""
              style={{
                color: "#21392E",
              }}
            >
              Age Range
            </label>
            <br />

            <MHSelectMultiple
              label=" "
              options={childcareAge}
              selectedOptions={ageRange ? ageRange.split(", ") : []}
              onChange={handleChildrenInput}
            />
          </Box>
        </Box>

        <Box className=" lg:flex lg:flex-row lg:justify-center gap-6 py-8 px-4 md:px-4 lg:px-0 space-y-3 md:space-y-0">
          <Box className=" col-span-1 md:col-span-1 lg:basis-[28%] -mt-6 lg:mt-0">
            <label
              className=""
              style={{
                color: "#21392E",
              }}
            >
              BN / RC (Optional)
            </label>
            <MHTextInput
              id="text"
              type="text"
              // label="phone"
              placeholder=""
              value={
                enteredPhone === "" ? caredata?.registrationNo : enteredPhone
              }
              onChange={phoneInputChangeHandler}
              onBlur={phoneInputBlurHandler}
              disabled={disabledState.phone}
              className={`${
                disabledState.phone === true ? "bg-[#D9D9D9]" : "bg-white"
              }`}
              endAdornment={
                <InputAdornment
                  className="bg-[#D9D9D9] md:h-[43px] text-navy-900 cursor-pointer"
                  onClick={() => {
                    setDisabledState({
                      ...disabledState,
                      phone: !disabledState.phone,
                    });
                  }}
                >
                  <EditIcon className=" text-navy-900 2-6 h-6" />
                </InputAdornment>
              }
              required
              autoFocus
            />
          </Box>

          <Box className=" col-span-1 md:col-span-1 lg:basis-[28%]">
            <label
              className=""
              style={{
                color: "#21392E",
              }}
            >
              Start Day
            </label>
            {/* <MHTextInput
              id="text"
              type="text"
              // label="address"
              placeholder=""
              value={
                enteredAddress === "" ? caredata?.startDay : enteredAddress
              }
              onChange={addressInputChangeHandler}
              onBlur={addressInputBlurHandler}
              disabled={disabledState.address}
              className={`${
                disabledState.address === true ? "bg-[#D9D9D9]" : "bg-white"
              }`}
              endAdornment={
                <InputAdornment
                  className="bg-[#D9D9D9] md:h-[43px] text-navy-900 cursor-pointer"
                  onClick={() => {
                    setDisabledState({
                      ...disabledState,
                      address: !disabledState.address,
                    });
                  }}
                >
                  <EditIcon className=" text-navy-900 2-6 h-6" />
                </InputAdornment>
              }
              required
              autoFocus
            /> */}
            <MHAutocomplete
              label=""
              placeholder="Start Day"
              options={weekdaysOption}
              onInputChange={(val) => addressInputChangeHandler(val as string)}
            />
          </Box>

          <Box className=" col-span-1 md:col-span-1 lg:basis-[28%]">
            <label
              className=""
              style={{
                color: "#21392E",
              }}
            >
              End Day
            </label>
            {/* <MHTextInput
              id="text"
              type="text"
              // label="role"
              placeholder=""
              value={enteredInfo === "" ? caredata?.endDay : enteredInfo}
              onChange={infoInputChangeHandler}
              onBlur={infoInputBlurHandler}
              disabled={disabledState.city}
              className={`${
                disabledState.city === true ? "bg-[#D9D9D9]" : "bg-white"
              }`}
              endAdornment={
                <InputAdornment
                  className="bg-[#D9D9D9] md:h-[43px] text-navy-900 cursor-pointer"
                  onClick={() => {
                    setDisabledState({
                      ...disabledState,
                      city: !disabledState.city,
                    });
                  }}
                >
                  <EditIcon className=" text-navy-900 2-6 h-6" />
                </InputAdornment>
              }
              required
              autoFocus
            /> */}
            <MHAutocomplete
              label=""
              placeholder="Start Day"
              options={weekdaysOption}
              onInputChange={(val) => infoInputChangeHandler(val as string)}
            />
          </Box>
        </Box>

        <Divider />

        <Box
          display={{ xs: "flex", sm: "flex" }}
          justifyContent="end"
          width={{ xs: "full", sm: "full" }}
          className="gap-4 md:!pr-[50px] py-6 px-4 md:px-0 "
        >
          <MHButton
            sx={{ height: "38px", width: "158px", color: "white" }}
            onClick={handleCancel}
            className=" bg-white text-navy-900 ring-1 ring-navy-900 rounded-sm"
            fullWidth
          >
            Cancel
          </MHButton>

          <MHButton
            sx={{ height: "38px", width: { xs: "full", sm: "158px" } }}
            onClick={handleSaveAccount}
            fullWidth
            className="rounded-sm"
          >
            Save Changes
          </MHButton>
        </Box>
      </Box>
    </React.Fragment>
  );
};

const CenterSettings = (props: Props) => {
  return (
    <React.Fragment>
      <Box className="space-y-8">
        <AccountSettings />
        <OtherSettings />
        {/* <PinSettings /> */}
      </Box>
    </React.Fragment>
  );
};

export default CenterSettings;
