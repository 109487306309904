import { Avatar, Box, Typography } from "@mui/material";
import React from "react";
import { AboutLikeProps } from "../../../models/care.details";

type Props = {};

const DirectorBio = (props: AboutLikeProps) => {
  // console.log(
  //   "Avatar",
  //   `https://careafrica.motherhonestly.info/assets/${props.directorsAvatar?.documentName}`
  // );

  // Check if directorsBio is defined before accessing its properties
  const directorsName = props.directorsBio?.directorsName || "";
  const directorsDetails = props.directorsBio?.details || "";

  // console.log("directorsDetails", props.directorsBio?.directorsName);

  return (
    <React.Fragment>
      <Typography
        variant="h2"
        fontSize={{ xs: "20px", sm: "30px", md: "30px" }}
        lineHeight={"120.5%"}
        letterSpacing={"-0.04em"}
        className="pb-4 text-start capitalize mt-5"
      >
        {/* Tolani Adebola */}
        {directorsName}
      </Typography>
      <Box className=" inline-flex items-baseline">
        <Typography
          variant="body1"
          color="primary"
          fontSize={{ xs: "12px", sm: "14px", md: "14px" }}
          lineHeight={{ xs: "180%", sm: "180%" }}
          letterSpacing={{ xs: "0.05em", sm: "0.06em" }}
          className="pb-4 text-start "
        >
          <Avatar
            src={`${
              props.directorsAvatar &&
              `https://careafrica.motherhonestly.info/assets/${props?.directorsAvatar.documentName}`
            } `}
            // src="https://bumo-care.s3.us-west-1.amazonaws.com/Toluca+Lake+Preschool2.webp"
            variant="square"
            className="float-left h-[200px] w-[200px] mr-4 rounded-md"
          />
          {/* Hi, My name is Ms. Amey and I am the Director at Joyce's Preschool!My
          family is from Chile and the Philippines, but I was born in Los
          Angeles, and raised in Morro Bay, California. My husband Johnny and I
          are parents to our daughter Isabella, and fur babies Foxxy and Lion-o.
          I find joy living each day with a grateful heart! I also serve at my
          church, I am an essential oil advocate, and I have a passion for all
          things Disney - more specific, Haunted Mansion, Nightmare Before
          Christmas and Marie the Aristocat. I have had the privilege of being a
          part of Joyce’s since 2015. After spending 2 years teaching, I was
          excited to expand my role as Director. Together with a team of amazing
          and creative teachers, I am committed to providing our families with a
          loving and developmentally sound foundation for a lifetime of
          learning. I am so grateful to be a part of Joyce’s as the impact I
          make in our students’ lives makes for an even bigger and completely
          gratifying impact in my own life. */}

          {directorsDetails}
        </Typography>
      </Box>
    </React.Fragment>
  );
};

export default DirectorBio;
