import { Box, Grid, Typography } from "@mui/material";
import React from "react";

import { ReactComponent as PayIcon } from "../../static/svg/pay.svg";
import { ReactComponent as PayWhiteIcon } from "../../static/svg/pay-white.svg";
import { ReactComponent as ManageIcon } from "../../static/svg/manage.svg";
import { ReactComponent as ManageWhiteIcon } from "../../static/svg/manage-white.svg";
import { ReactComponent as HireIcon } from "../../static/svg/hire.svg";
import { ReactComponent as HireWhiteIcon } from "../../static/svg/hire-white.svg";

type Props = {};

const HowItWorks = (props: Props) => {
  const [value, setValue] = React.useState(0);
  return (
    <React.Fragment>
      <Box
        sx={{
          paddingTop: "40px",
          justifyContent: "center",
          alignItems: { sm: "start", md: "center" },
          // paddingTop: "80px",
          // paddingBottom: "100px",
          height: { xs: "670px", sm: "620px", md: "785px" },
          backgroundColor: "#f6f6f6",
          overflow: "hidden",
          overscrollBehaviorY: "hidden",
        }}
        className=" hidden md:block"
      >
        <div id="how-it-works"></div>
        <Grid
          container
          spacing={2}
          sx={{
            // marginTop: "20px",
            justifyContent: "center",
            alignItems: { sm: "start", md: "center" },
            // paddingTop: "80px",
            // paddingBottom: "100px",
            height: { xs: "500px", sm: "520px", md: "610px" },
            backgroundColor: "#f6f6f6",
          }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            sx={{
              marginTop: "20px",
              paddingBottom: "60px",
              // justifyContent: "center",
              // alignItems: "center",
              textAlign: "center",
            }}
          >
            <Typography
              variant="subtitle1"
              color="primary"
              fontSize={{ xs: "22px", sm: "22px", md: "29px" }}
              lineHeight={{ xs: "160.5%", sm: "160.5%" }}
              letterSpacing={"-4%"}
              className=" capitalize"
            >
              How It Works
            </Typography>

            <Typography
              variant="body1"
              color="primary"
              fontSize={{ xs: "12px", sm: "12px", md: "14px" }}
              lineHeight={{ xs: "200%", sm: "200%" }}
              letterSpacing={"-4%"}
              className="w-[85%] md:w-[40%] capitalize mx-auto"
            >
              Let’s take the hassle out of onboarding, paying, and training your
              household.
            </Typography>
          </Grid>
          <Grid item xs={10} sm={5.5} md={3.2}>
            <Box
              component={"button"}
              sx={{ textAlign: "start" }}
              onClick={() => setValue(0)}
              // width={{ xs: "500px" }}
              className=" flex gap-2 items-start pb-12"
            >
              {value === 0 ? <HireIcon /> : <HireWhiteIcon />}
              <Box>
                <Typography
                  variant="subtitle2"
                  color="primary"
                  fontSize={{ xs: "18px", sm: "18px", md: "21px" }}
                  lineHeight={{ xs: "160.5%", sm: "160.5%" }}
                  letterSpacing={"-4%"}
                  className={`w-full  mr-10  capitalize ${
                    value === 0 ? "text-navy-900" : "text-[#D9D9D9]"
                  }`}
                >
                  Find vetted care:{"                       "}
                </Typography>
                {value === 0 ? (
                  <Typography
                    variant="subtitle2"
                    color="primary"
                    fontSize={{ xs: "10px", sm: "10px", md: "12px" }}
                    lineHeight={{ xs: "160.5%", sm: "160.5%" }}
                    letterSpacing={"-4%"}
                    className="w-full pt-2  capitalize"
                  >
                    get access to quality childcare
                  </Typography>
                ) : null}
              </Box>
            </Box>

            <Box
              component={"button"}
              sx={{ textAlign: "start" }}
              onClick={() => setValue(1)}
              className=" flex gap-2 items-start pb-12"
            >
              {value === 1 ? <PayIcon /> : <PayWhiteIcon />}
              <Box>
                <Typography
                  variant="subtitle2"
                  color="primary"
                  fontSize={{ xs: "18px", sm: "18px", md: "20px" }}
                  lineHeight={{ xs: "160.5%", sm: "160.5%" }}
                  letterSpacing={"-4%"}
                  className={`w-full mr-10  capitalize ${
                    value === 1 ? "text-navy-900" : "text-[#D9D9D9]"
                  }`}
                >
                  Workers payroll & management: {"                       "}
                </Typography>
                {value === 1 ? (
                  <Typography
                    variant="subtitle2"
                    color="primary"
                    fontSize={{ xs: "10px", sm: "10px", md: "12px" }}
                    lineHeight={{ xs: "160.5%", sm: "160.5%" }}
                    letterSpacing={"-4%"}
                    className="w-full pt-2  capitalize"
                  >
                    Salaries, payslips, tax certificates, trainings, health
                    assessment, background checks, and employment contracts
                  </Typography>
                ) : null}
              </Box>
            </Box>
            <Box
              component={"button"}
              sx={{ textAlign: "start" }}
              onClick={() => setValue(2)}
              className=" flex gap-2 items-start pb-12"
            >
              {value === 2 ? <ManageIcon /> : <ManageWhiteIcon />}
              <Box>
                <Typography
                  variant="subtitle2"
                  color="primary"
                  fontSize={{ xs: "18px", sm: "18px", md: "21px" }}
                  lineHeight={{ xs: "160.5%", sm: "160.5%" }}
                  letterSpacing={"-4%"}
                  className={`w-full mr-10 capitalize ${
                    value === 2 ? "text-navy-900" : "text-[#D9D9D9]"
                  }`}
                >
                  Upskill & train:{"                       "}
                </Typography>
                {value === 2 ? (
                  <Typography
                    variant="subtitle2"
                    color="primary"
                    fontSize={{ xs: "10px", sm: "10px", md: "12px" }}
                    lineHeight={{ xs: "160.5%", sm: "160.5%" }}
                    letterSpacing={"-4%"}
                    className="w-[90%] pt-2  capitalize"
                  >
                    performance reviews, bonuses, routine assessments, and
                    certifications
                  </Typography>
                ) : null}
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={10}
            sm={5.5}
            md={6.2}
            sx={{
              display: {
                xs: "grid",
                sm: "grid",
              },
              height: "100%",
            }}
          >
            {value === 0 ? (
              <img
                src="https://mh-resources-production.s3.us-west-1.amazonaws.com/ViewCentres2.jpg"
                draggable={false}
                alt="CaringBlocks"
                className=" rounded-xl"
              />
            ) : value === 1 ? (
              <img
                src="https://mh-resources-production.s3.us-west-1.amazonaws.com/ViewTwo.jpg"
                draggable={false}
                alt="CaringBlocks"
                // className="h-[480px]"
              />
            ) : value === 2 ? (
              <img
                src="https://mh-resources-production.s3.us-west-1.amazonaws.com/ViewThree.jpg"
                draggable={false}
                alt="CaringBlocks"
                // className="h-[480px]"
              />
            ) : null}
          </Grid>
        </Grid>
      </Box>

      <Box
        mt={4}
        sx={{
          justifyContent: "center",
          alignItems: "center",
          height: "1350px",
          overflow: "hidden",
          overscrollBehaviorY: "hidden",
        }}
        className="  md:hidden"
      >
        <Box
          sx={{
            height: "400px",
            backgroundColor: "#194049",
          }}
        >
          <Typography
            variant="subtitle2"
            color="#fff"
            fontSize={"28px"}
            lineHeight={"160.5%"}
            letterSpacing={"-4%"}
            className=" pt-6 mx-auto text-center"
            width={"90%"}
          >
            Hire And Onboard
          </Typography>
          <Typography
            variant="body1"
            color="#fff"
            fontSize={"14px"}
            lineHeight={"200%"}
            letterSpacing={"-4%"}
            className="pb-6 pt-2 capitalize mx-auto md:mx-0 text-center md:text-start"
            width={"80%"}
          >
            health assessment, background checks, employment contracts
          </Typography>

          <img
            src="https://mh-resources-production.s3.us-west-1.amazonaws.com/View.png"
            draggable={false}
            alt="CaringBlocks"
            className="mx-auto pt-14 rounded-xl"
            width={"90%"}
          />
        </Box>
        <Box
          pt={6}
          sx={{
            height: "450px",
            backgroundColor: "#D2E0CB",
          }}
        >
          <Typography
            variant="subtitle2"
            color="primary"
            fontSize={"28px"}
            lineHeight={"160.5%"}
            letterSpacing={"-4%"}
            className=" pt-6 mx-auto text-center"
            width={"90%"}
          >
            Pay And Train
          </Typography>
          <Typography
            variant="body1"
            color="primary"
            fontSize={"14px"}
            lineHeight={"200%"}
            letterSpacing={"-4%"}
            className="pb-6 pt-2 capitalize mx-auto md:mx-0 text-center md:text-start"
            width={"80%"}
          >
            Salaries, payslips, tax certificates, trainings, and certifications{" "}
          </Typography>

          <img
            src="https://mh-resources-production.s3.us-west-1.amazonaws.com/ViewTwo.jpg"
            draggable={false}
            alt="CaringBlocks"
            className="mx-auto pt-10"
            width={"90%"}
          />
        </Box>
        <Box
          pt={4}
          sx={{
            height: "550px",
            backgroundColor: "#194049",
          }}
        >
          <Typography
            variant="subtitle2"
            color="#fff"
            fontSize={"28px"}
            lineHeight={"160.5%"}
            letterSpacing={"-4%"}
            className=" pt-6 mx-auto text-center"
            width={"90%"}
          >
            Manage And Retain
          </Typography>
          <Typography
            variant="body1"
            color="#fff"
            fontSize={"14px"}
            lineHeight={"200%"}
            letterSpacing={"-4%"}
            className="pb-6 pt-2 capitalize mx-auto md:mx-0 text-center md:text-start"
            width={"80%"}
          >
            performance reviews, bonuses, routine assessments
          </Typography>

          <img
            src="https://mh-resources-production.s3.us-west-1.amazonaws.com/ViewThree.jpg"
            draggable={false}
            alt="CaringBlocks"
            className="mx-auto pt-6"
            width={"90%"}
          />
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default HowItWorks;
