import { useState, useEffect } from "react";
import { getCookie, setCookie } from "./use-cookie";

interface GeocodeResponse {
  status: string;
  results: Array<{
    address_components: Array<{
      long_name: string;
      types: string[];
    }>;
  }>;
}

// Custom hook for getting geolocation
export function useGeolocation() {
  const [lat, setLat] = useState<number | null>(null);
  const [lng, setLng] = useState<number | null>(null);

  useEffect(() => {
    const existingLat = getCookie("_mhLatCookie");
    const existingLng = getCookie("_mhLngCookie");

    // console.log("Our Lat and Lng Cookies",existingLat, existingLng);

    if (!existingLat || !existingLng) {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;

            // Set the state values
            setLat(latitude);
            setLng(longitude);

            // Set the cookie values
            setCookie("_mhLatCookie", latitude.toString(), 30);
            setCookie("_mhLngCookie", longitude.toString(), 30);
            console.log("Cookie set successfully");
          },
          (error) => {
            console.error("Error getting geolocation:", error.message);
          }
        );
      } else {
        console.error("Geolocation is not supported by your browser");
      }
    }
  }, []); // Empty dependency array ensures the effect runs only once

  return { lat, lng };
}



export const getStateFromCoordinates = async (lat: number, lng: number): Promise<string | null> => {
  const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;  // Replace with your API key
  const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`;

  try {
    const response = await fetch(url);
    const data: GeocodeResponse = await response.json();

    if (data.status === 'OK') {
      const addressComponents = data.results[0].address_components;
      const stateComponent = addressComponents.find(component =>
        component.types.includes('administrative_area_level_1')
      );

      if (stateComponent) {
        return stateComponent.long_name;  // Return state name
      } else {
        throw new Error('State not found');
      }
    } else {
      throw new Error('Geocode failed');
    }
  } catch (error) {
    console.error('Error getting state from coordinates:', error);
    return null;
  }
};


export const fetchState = async (lat: number | null, lng: number | null) => {
  if (lat === null || lng === null) {
    console.log("Latitude or Longitude is null");
    return;
  }

  try {
    const state = await getStateFromCoordinates(lat, lng);
    if (state) {
      // console.log("The state is:", state);
      localStorage.setItem("userState", state);

    } else {
      console.log("State not found.");
    }
  } catch (error) {
    console.error("Error while fetching state:", error);
  }
};