import React from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import useMediaQuery from "@mui/material/useMediaQuery";
import { styled, useTheme } from "@mui/material/styles";

import ContentCard from "./ContentCard";
import MHSlider, {
  MHSliderItem,
} from "../../components/Common/SliderCarousel/CBSlider/MHSlider";
import MHButton from "../../components/Common/Button/MHButton";

const CONTENT = [
  {
    imageSrc:
      "https://mh-resources-production.s3.us-west-1.amazonaws.com/Proffessional+Growth+and+Development.png",
    title: "Professional Growth and Development",
    description:
      "At Caring Blocks, we offer you more than just a job. We provide access to ongoing training and development through our Caring Blocks Institute, ensuring that you are always growing and enhancing your skills. We also offer certifications that can help you stand out and advance in your career.",
  },
  {
    imageSrc:
      "https://mh-resources-production.s3.us-west-1.amazonaws.com/Seamless+Onboard.png",
    title: "Seamless Onboarding and Support",
    description:
      "Our onboarding process is designed with you in mind. We handle everything from background checks and health assessments to contract signing and setting up your payroll. Our goal is to make your transition into a new role as smooth as possible, so you can focus on doing what you do best - providing excellent care.",
  },
  {
    imageSrc:
      "https://mh-resources-production.s3.us-west-1.amazonaws.com/Competitive+Pay+And+Benefits.png",
    title: "Competitive Pay and Benefits",
    description:
      "We understand the importance of fair compensation. Caring Blocks ensures that you receive competitive pay, on-time payments, and access to benefits that recognize your hard work. Your salary will be deposited directly into your Caring Blocks wallet, where you can easily transfer it to your bank account.",
  },
  {
    imageSrc:
      "https://mh-resources-production.s3.us-west-1.amazonaws.com/Care+Support2.png",
    title: "24/7 Care Concierge Service",
    description:
      "As a member of Caring Blocks, you'll have access to our 24/7 care concierge service. Whether you need support with your job or have a concern that needs addressing, we're here to help at any time, day or night.",
  },
  {
    imageSrc:
      "https://mh-resources-production.s3.us-west-1.amazonaws.com/16.png",
    title: "Safe and Trusted Work Environment",
    description:
      "We prioritize your safety and well-being. All families you are matched with have undergone thorough vetting, and we ensure a respectful and professional work environment. Your privacy and security are of utmost importance to us.",
  },
];

const CareProviderHeroSlider = () => {
  const theme = useTheme();
  const matchesMdDown = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div>
      <Box width={{ xs: "90%", sm: "75%" }} mx="auto" mt={7}>
        <Typography
          variant="h1"
          fontSize={{ xs: "30px", sm: "40px", md: "50px" }}
          textTransform="capitalize"
          align="center"
          sx={{
            mb: 4,
          }}
          paragraph
        >
          {/* extend care beyond the company’s walls, creating a win-win scenario
          where you and <br /> your employees thrive and prosper. */}
          Why Partner with Caring Blocks?
        </Typography>
        {/* <Typography
          variant="body1"
          color="primary.main"
          width={{ xs: "100%", md: "70%" }}
          fontSize="13px"
          lineHeight="185%"
          letterSpacing="0.02em"
          align="center"
          mx="auto"
          paragraph
        >
          Personalized care solutions that support the mental, physical, and
          financial aspects of a distributed workforce.
        </Typography> */}
      </Box>

      {/* <Box py="3rem" px={{ xs: 0, md: "4rem" }}>
        <MHSlider slidesToShow={3} slidesToScroll={1}>
          {CONTENT.map((item, index) => (
            <MHSliderItem width="100%" key={index}>
              <ContentCard
                imageSrc={item.imageSrc}
                title={item.title}
                description={item.description}
                onMouseClick={() => {}}
              />
            </MHSliderItem>
          ))}
        </MHSlider>
      </Box> */}

      <Grid
        container
        spacing={2}
        sx={{
          //   paddingTop: "20px",
          justifyContent: "center",
          alignItems: "center",

          // paddingTop: "80px",
          // paddingBottom: "100px",
          height: { xs: "full", sm: "520px", md: "600px" },
          backgroundColor: "#ffffff",
          overflow: "hidden",
          overscrollBehaviorY: "hidden",
        }}
      >
        <Grid
          item
          xs={10.5}
          sm={5.5}
          md={4.8}
          sx={{ display: { xs: "grid", sm: "grid" } }}
          height={{ xs: "full", sm: "450px", md: "580px" }}
          className=" items-center order-first"
        >
          <img
            src="https://mh-resources-production.s3.us-west-1.amazonaws.com/Increase+Enrollment+with+Real-Time+Visibility.jpg"
            alt="Care Providers"
            className=" rounded-xl object-cover h-fit md:h-[400px] lg:h-[500px]"
            draggable={false}
          />
        </Grid>
        <Grid
          item
          xs={10.5}
          sm={5.5}
          md={4.8}
          className=" flex items-center order-last"
        >
          <Box>
            <Typography
              variant="subtitle2"
              color="primary"
              fontSize={{ xs: "24px", sm: "25px", md: "25px" }}
              lineHeight={{ xs: "120%", sm: "130.5%" }}
              letterSpacing={"-4%"}
              textAlign={{ xs: "center", sm: "start" }}
              className=" capitalize"
            >
              Increase Enrollment with Real-Time Visibility
            </Typography>

            <Typography
              variant="body1"
              color="primary"
              fontSize={{ xs: "14px", sm: "12px", md: "14px" }}
              lineHeight={{ xs: "200%", sm: "200%" }}
              letterSpacing={"-4%"}
              className="pt-3 pb-6 mx-auto capitalize text-center md:text-start"
              width={{ xs: "90%", sm: "100%" }}
            >
              &bull; We connect your center to families and employers seeking
              high-quality childcare solutions.
              <br />
              • Each childcare center receives a dedicated page on our platform,
              showcasing up-to-date enrollment availability, services offered,
              and contact details to simplify the registration process.
              <br />• Our employer partnerships drive demand, ensuring your open
              spots are filled efficiently.
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        sx={{
          //   paddingTop: "20px",
          justifyContent: "center",
          alignItems: "center",

          // paddingTop: "80px",
          // paddingBottom: "100px",
          height: { xs: "full", sm: "520px", md: "600px" },
          backgroundColor: "#ffffff",
          overflow: "hidden",
          overscrollBehaviorY: "hidden",
        }}
      >
        <Grid
          item
          xs={10.5}
          sm={5.5}
          md={4.8}
          className=" flex items-center order-last md:order-first"
        >
          <Box>
            <Typography
              variant="subtitle2"
              color="primary"
              fontSize={{ xs: "24px", sm: "25px", md: "25px" }}
              lineHeight={{ xs: "120%", sm: "130.5%" }}
              letterSpacing={"-4%"}
              textAlign={{ xs: "center", sm: "start" }}
              className=" capitalize"
            >
              Vet, Pay, and Manage Your Employees
            </Typography>

            <Typography
              variant="body1"
              color="primary"
              fontSize={{ xs: "14px", sm: "12px", md: "14px" }}
              lineHeight={{ xs: "200%", sm: "200%" }}
              letterSpacing={"-4%"}
              className="pt-3 pb-6 mx-auto capitalize text-center md:text-start"
              width={{ xs: "90%", sm: "100%" }}
            >
              &bull; Caring Blocks simplifies your HR processes, offering tools
              to vet, pay, and manage your childcare staff.
              <br />
              • Conduct background checks, health assessments, and reference
              verifications directly on the platform to ensure your team meets
              the highest standards.
              <br /> • Use our payroll services to handle salary payments, tax
              withholdings, and benefits for your staff, reducing administrative
              burdens.
              <br />• Gain access to performance management tools to track and
              support employee growth and productivity.
            </Typography>
          </Box>
        </Grid>

        <Grid
          item
          xs={10.5}
          sm={5.5}
          md={4.8}
          sx={{ display: { xs: "grid", sm: "grid" } }}
          height={{ xs: "full", sm: "450px", md: "580px" }}
          className=" items-center  order-first md:order-last"
        >
          <img
            src="https://mh-resources-production.s3.us-west-1.amazonaws.com/Vet%2C+Pay%2C+and+Manage+Your+Employees.jpg"
            alt="Care Providers"
            className=" rounded-xl object-cover h-fit md:h-[400px] lg:h-[500px]"
            draggable={false}
          />
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        sx={{
          //   paddingTop: "20px",
          justifyContent: "center",
          alignItems: "center",

          // paddingTop: "80px",
          // paddingBottom: "100px",
          height: { xs: "full", sm: "520px", md: "600px" },
          backgroundColor: "#ffffff",
          overflow: "hidden",
          overscrollBehaviorY: "hidden",
        }}
      >
        <Grid
          item
          xs={10.5}
          sm={5.5}
          md={4.8}
          sx={{ display: { xs: "grid", sm: "grid" } }}
          height={{ xs: "full", sm: "450px", md: "580px" }}
          className=" items-center order-first"
        >
          <img
            src="https://mh-resources-production.s3.us-west-1.amazonaws.com/Training+and+Development.jpg"
            alt="Care Providers"
            className=" rounded-xl object-cover h-fit md:h-[400px] lg:h-[500px]"
            draggable={false}
          />
        </Grid>
        <Grid
          item
          xs={10.5}
          sm={5.5}
          md={4.8}
          className=" flex items-center order-last"
        >
          <Box>
            <Typography
              variant="subtitle2"
              color="primary"
              fontSize={{ xs: "24px", sm: "25px", md: "25px" }}
              lineHeight={{ xs: "120%", sm: "130.5%" }}
              letterSpacing={"-4%"}
              textAlign={{ xs: "center", sm: "start" }}
              className=" capitalize"
            >
              Training and Development for Your Team
            </Typography>

            <Typography
              variant="body1"
              color="primary"
              fontSize={{ xs: "14px", sm: "12px", md: "14px" }}
              lineHeight={{ xs: "200%", sm: "200%" }}
              letterSpacing={"-4%"}
              className="pt-3 pb-6 mx-auto capitalize text-center md:text-start"
              width={{ xs: "90%", sm: "100%" }}
            >
              • Caring Blocks provides professional development opportunities
              for your staff through specialized training programs.
              <br />
              • Topics include child development, safety protocols, caregiver
              professionalism, and operational excellence, ensuring your team
              stays ahead of industry standards.
              <br /> • Access our Caring Blocks Institute resources to
              continually upskill your workforce.
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        sx={{
          //   paddingTop: "20px",
          justifyContent: "center",
          alignItems: "center",

          // paddingTop: "80px",
          // paddingBottom: "100px",
          height: { xs: "full", sm: "520px", md: "600px" },
          backgroundColor: "#ffffff",
          overflow: "hidden",
          overscrollBehaviorY: "hidden",
        }}
      >
        <Grid
          item
          xs={10.5}
          sm={5.5}
          md={4.8}
          className=" flex items-center order-last md:order-first"
        >
          <Box>
            <Typography
              variant="subtitle2"
              color="primary"
              fontSize={{ xs: "24px", sm: "25px", md: "25px" }}
              lineHeight={{ xs: "120%", sm: "130.5%" }}
              letterSpacing={"-4%"}
              textAlign={{ xs: "center", sm: "start" }}
              className=" capitalize"
            >
              Operational Support and Solutions
            </Typography>

            <Typography
              variant="body1"
              color="primary"
              fontSize={{ xs: "14px", sm: "12px", md: "14px" }}
              lineHeight={{ xs: "200%", sm: "200%" }}
              letterSpacing={"-4%"}
              className="pt-3 pb-6 mx-auto capitalize text-center md:text-start"
              width={{ xs: "90%", sm: "100%" }}
            >
              &bull; We help streamline your operations, offering tools and
              guidance to enhance efficiency and optimize your center’s daily
              workflow.
              <br />• Gain insights into managing parent relationships, creating
              enriched learning environments, and running a safe and organized
              facility.
            </Typography>
          </Box>
        </Grid>

        <Grid
          item
          xs={10.5}
          sm={5.5}
          md={4.8}
          sx={{ display: { xs: "grid", sm: "grid" } }}
          height={{ xs: "full", sm: "450px", md: "580px" }}
          className=" items-center  order-first md:order-last"
        >
          <img
            src="https://mh-resources-production.s3.us-west-1.amazonaws.com/Operational+Support+and+Solutions.jpg"
            alt="Care Providers"
            className=" rounded-xl object-cover h-fit md:h-[400px] lg:h-[500px]"
            draggable={false}
          />
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        sx={{
          //   paddingTop: "20px",
          justifyContent: "center",
          alignItems: "center",

          // paddingTop: "80px",
          // paddingBottom: "100px",
          height: { xs: "full", sm: "520px", md: "600px" },
          backgroundColor: "#ffffff",
          overflow: "hidden",
          overscrollBehaviorY: "hidden",
        }}
      >
        <Grid
          item
          xs={10.5}
          sm={5.5}
          md={4.8}
          sx={{ display: { xs: "grid", sm: "grid" } }}
          height={{ xs: "full", sm: "450px", md: "580px" }}
          className=" items-center order-first"
        >
          <img
            src="https://mh-resources-production.s3.us-west-1.amazonaws.com/Build+Credibility+and+Trust.jpg"
            alt="Care Providers"
            className=" rounded-xl object-cover h-fit md:h-[400px] lg:h-[500px]"
            draggable={false}
          />
        </Grid>
        <Grid
          item
          xs={10.5}
          sm={5.5}
          md={4.8}
          className=" flex items-center order-last"
        >
          <Box>
            <Typography
              variant="subtitle2"
              color="primary"
              fontSize={{ xs: "24px", sm: "25px", md: "25px" }}
              lineHeight={{ xs: "120%", sm: "130.5%" }}
              letterSpacing={"-4%"}
              textAlign={{ xs: "center", sm: "start" }}
              className=" capitalize"
            >
              Build Credibility and Trust
            </Typography>

            <Typography
              variant="body1"
              color="primary"
              fontSize={{ xs: "14px", sm: "12px", md: "14px" }}
              lineHeight={{ xs: "200%", sm: "200%" }}
              letterSpacing={"-4%"}
              className="pt-3 pb-6 mx-auto capitalize text-center md:text-start"
              width={{ xs: "90%", sm: "100%" }}
            >
              • Partnering with Caring Blocks means joining a network of
              childcare providers committed to excellence.
              <br />• Our vetting and verification process adds a layer of
              trust, assuring families and employers that your center meets the
              highest standards of care and safety.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default CareProviderHeroSlider;
