export class User {
  constructor(
    public uuid: number,
    public firstName: string,
    public lastName: string,
    public email: string,
    public employerRefId: number,
    private _token: Token,
    private _tokenExpirationDate: Date
  ) {}

  get token() {
    if (!this._tokenExpirationDate || new Date() > this._tokenExpirationDate) {
      return null;
    }

    return this._token;
  }
}

export interface Token {
  accessToken: string;
  refreshToken: string;
}

export type Employee =  {
  id?:               number;
  uuid?:             number | any;
  firstName?:        string | any;
  lastName?:         string | any;
  middleName?:       string | any;
  company?:          string | any;
  workDepartment?:   string | any;
  salary?:           number | any;
  userId?:           number;
  employerId?:       number;
  employerRefId?:    number | any;
  businessId?:    number | any;
  workEmail?:        string | any;
  email?:        string | any;
  Accomodation?:        string | any;
  transportation?:        string | any;
  meal?:        string | any;
  airtimeOrData?:        string | any;
  position?:         string | any;
  jobTitle?:         string | any;
  onboarded?:        boolean;
  pulledFromMerge?:  boolean;
  status?:           string;
  verified?:         boolean;
  password?:         string;
  startDate:         Date;
  endDate:           Date;
  createdDate:      Date;
  createdBy:        string;
  lastActivityBy:   string;
  lastActivityDate: Date;
}