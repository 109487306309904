import React from "react";
import { useHistory } from "react-router-dom";

import Box from "@mui/material/Box";
import MHDialog from "./MHDialog";

const ReusedModal = ({
  open,
  onClose,
  header,
  message,
  children,
  marginTop,
  marginBottom,
  marginLeft,
  marginRight,
  maxWidth,
}: {
  open: boolean;
  onClose: () => void;
  header?: string;
  message?: string;
  maxWidth?: any | undefined;
  marginTop?: number;
  marginBottom?: number;
  marginLeft?: number;
  marginRight?: number;
  children?: React.ReactNode;
}) => {
  const history = useHistory();

  return (
    <MHDialog
      title={" "}
      open={open}
      handleClose={onClose}
      scroll="paper"
      maxWidth={maxWidth}
      actions={null}
      fullWidth
      sx={{
        marginTop: marginTop,
        marginBottom: marginBottom,
        marginLeft: marginLeft,
        marginRight: marginRight,
        zIndex: 1299,
      }}
    >
      <Box
        sx={{
          "& > svg": {
            textAlign: "center",
            mx: "auto",
            my: 2,
          },
        }}
      >
        {children}
      </Box>
    </MHDialog>
  );
};

export default ReusedModal;
