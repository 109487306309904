// // Reusable Levenshtein Distance-based similarity function
// export const calculateSimilarity = (a: string, b: string): number => {
//     const matrix: number[][] = [];
  
//     // Initialize the matrix
//     for (let i = 0; i <= a.length; i++) {
//       matrix[i] = [i];
//     }
//     for (let j = 0; j <= b.length; j++) {
//       matrix[0][j] = j;
//     }
  
//     // Fill the matrix
//     for (let i = 1; i <= a.length; i++) {
//       for (let j = 1; j <= b.length; j++) {
//         const cost = a[i - 1] === b[j - 1] ? 0 : 1;
//         matrix[i][j] = Math.min(
//           matrix[i - 1][j] + 1, // Deletion
//           matrix[i][j - 1] + 1, // Insertion
//           matrix[i - 1][j - 1] + cost // Substitution
//         );
//       }
//     }
  
//     // Levenshtein distance is in the bottom-right cell
//     const levenshteinDistance = matrix[a.length][b.length];
  
//     // Normalize similarity to a score between 0 and 1
//     const maxLen = Math.max(a.length, b.length);
//     return maxLen === 0 ? 1 : (maxLen - levenshteinDistance) / maxLen;
//   };
  

// Tokenized matching function for similarity
export const calculateSimilarity = (input: string, target: string): number => {
    const normalize = (str: string) =>
      str.toLowerCase().replace(/\s+/g, " ").trim();
  
    const inputTokens = new Set(normalize(input).split(" "));
    const targetTokens = new Set(normalize(target).split(" "));
  
    const commonTokens = new Set(
      [...inputTokens].filter((token) => targetTokens.has(token))
    );
  
    const totalTokens = new Set([...inputTokens, ...targetTokens]);
    return commonTokens.size / totalTokens.size;
  };
  


  export const checkSimilarity = (input: string, target: string): number => {
    const normalize = (str: string) =>
      str.toLowerCase().replace(/[^\w\s]/g, "").replace(/\s+/g, " ").trim();
  
    const inputTokens = new Set(normalize(input).split(" "));
    const targetTokens = new Set(normalize(target).split(" "));
  
    const commonTokens = new Set(
      [...inputTokens].filter((token) => targetTokens.has(token))
    );
  
    const totalTokens = new Set([...inputTokens, ...targetTokens]);
  
    // Boost similarity for exact matches with single-word inputs
    const isSingleWordMatch = inputTokens.size === 1 && commonTokens.size > 0;
  
    return isSingleWordMatch
      ? 1 // Full match if single-word input matches any token
      : commonTokens.size / totalTokens.size;
  };
  