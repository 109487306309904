import React from "react";
import {
  Redirect,
  Route,
  Switch,
  useRouteMatch,
  useLocation,
} from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import SignIn from "../pages/Auth/SignIn";
import ForgotPassword from "../pages/Auth/ForgotPassword";
// import ResetLinkSuccess from "../pages/Auth/ResetLinkSuccess";
import PasswordReset from "../pages/Auth/PasswordReset";
import ResetSuccess from "../pages/Auth/ResetSuccess";

import MHLogo from "../theme/icons/MHLogo";
import { ReactComponent as OrgLogo } from "../static/svg/unilever-logo.svg";
import { FnComponent } from "../models/component.model";
import { theme } from "../theme/mui/dashboard.theme";
// import { BGImage } from "../models/background-image.model";
import Layout from "../components/Layout/Layout";
import SignUp from "../pages/Auth/SignUp";
import AuthContext from "../store/context/auth-context";
import EmployeeCreatePassword from "../pages/Auth/EmployeeCreatePassword";
import ClaimCenter from "../pages/Auth/ClaimCenter";

const AuthNavigator: FnComponent = () => {
  // const [image, setImage] = React.useState<BGImage>({} as BGImage);
  const backgroundColor = React.useRef(theme.palette.background.default);

  const { path } = useRouteMatch();
  const location = useLocation();

  // const changeImageHandler = React.useCallback(
  //   ({ imageSrc, imageAlt, background }: BGImage) => {
  //     setImage({
  //       imageSrc,
  //       imageAlt,
  //     });
  //     backgroundColor.current = !background
  //       ? theme.palette.background.default
  //       : (background as string);
  //   },
  //   []
  // );

  const authCtx = React.useContext(AuthContext);

  // Redirect if user is signed in already
  if (authCtx.isAuthenticated === true && authCtx.role === "Employer") {
    return <Redirect to="/organization/dashboard" />; //should redirect to household if role is employee
  } else if (authCtx.isAuthenticated === true && authCtx.role === "Employee") {
    return <Redirect to="/organization/employee/dashboard" />; //should redirect to household if role is employee
  }

  return (
    <React.Fragment>
      <Layout>
        <Box sx={{ flexGrow: 1 }} bgcolor="#FAFAFA">
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            spacing={0}
            sx={{ minHeight: "100vh" }}
          >
            <Grid
              item
              xs={12}
              sm={10}
              md={5}
              sx={{
                // backgroundColor: backgroundColor.current,
                position: "relative",
                px: 2,
              }}
            >
              <TransitionGroup style={{ width: "100%" }}>
                <CSSTransition
                  unmountOnExit
                  key={location.pathname}
                  classNames="fade"
                  timeout={400}
                >
                  <Switch location={location}>
                    <Route path={`${path}/center/claim`}>
                      <ClaimCenter
                        // onRouteChange={changeImageHandler}
                        title="Claim Center"
                      />
                    </Route>
                    <Route path={`${path}/onboard`}>
                      <SignUp
                        // onRouteChange={changeImageHandler}
                        title="Sign up"
                      />
                    </Route>
                    <Route path={`${path}/sign-in`}>
                      <SignIn
                        // onRouteChange={changeImageHandler}
                        title="Sign in "
                      />
                    </Route>
                    <Route path={`${path}/employee/create-password`} exact>
                      <EmployeeCreatePassword
                        // onRouteChange={changeImageHandler}
                        title="Create Password"
                      />
                    </Route>
                    <Route path={`${path}/forgot-password`} exact>
                      <ForgotPassword
                        // onRouteChange={changeImageHandler}
                        title="Forgot Password"
                      />
                    </Route>
                    {/* <Route path={`${path}/forgot-password/reset-link`} exact>
                      <ResetLinkSuccess
                      // onRouteChange={changeImageHandler}
                      />
                    </Route> */}
                    <Route path={`${path}/reset-password`} exact>
                      <PasswordReset
                      // onRouteChange={changeImageHandler}
                      />
                    </Route>
                    <Route path={`${path}/reset-password/:id/success`} exact>
                      <ResetSuccess
                      // onRouteChange={changeImageHandler}
                      />
                    </Route>

                    <Route path={`${path}`} exact>
                      <Redirect to={`${path}/sign-in`} />
                    </Route>
                    <Route path="*">
                      <Redirect to={`${path}/sign-in`} />
                    </Route>
                  </Switch>
                </CSSTransition>
              </TransitionGroup>
            </Grid>
          </Grid>
        </Box>
      </Layout>
    </React.Fragment>
  );
};

export default AuthNavigator;
