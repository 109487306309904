import React from "react";
import { useHistory } from "react-router-dom";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";

import MHFormControl from "../Form/MHFormControl";
import MHButton from "../Button/MHButton";
import InputAdornment from "../Form/InputAdornment";
import IconButton from "../Button/IconButtonStyled";
import useHttp from "../../../hooks/use-http";

import { ReactComponent as LockIcon } from "../../../static/svg/lock.svg";
import { ReactComponent as VisibilityIcon } from "../../../static/svg/visibility.svg";
import { ReactComponent as VisibilityOffIcon } from "../../../static/svg/visibility-off.svg";
import { ReactComponent as PhoneIcon } from "../../../static/svg/phone.svg";

import * as validators from "../../../utils/validators";

import useInput from "../../../hooks/use-input";
import { MHSelect } from "../Form/MHSelect";
import { decrypt, resolveErrorMessage } from "../../../utils/utils";
import { useOnboardForm } from "../../../store/context/onboard-context";
import useDialog from "../../../hooks/use-dialog";
import useForm from "../../../hooks/use-form";
import RequestConfirmationPrompt from "../Dialog/RequestConfirmation";
import {
  CenterCategories,
  NIGERIA_STATES_LCDAS,
} from "../../../utils/constants";
import { GENDER } from "../../Features/Employees/EmployeeInfo";
import SnackbarContext from "../../../store/context/snackbar.context";
import MHAutocomplete, { Option } from "../Form/MHAutocomplete";
import MHPhoneInput from "../Form/MHPhoneInput";
import usePhoneInput from "../../../hooks/use-phone";
import { splitName } from "../../../hooks/split-name";
import { convertToSlug } from "../../../hooks/get-name-slug";
import { HttpResponse } from "../../../models/api.interface";
import BackdropLoader from "../Loading/BackdropLoader";
// import BackdropLoader from "../Loading/BackdropLoader";

export interface SelectOption<T> {
  label: string;
  value: T;
}

const OtherInformation = () => {
  // const {
  //   value: enteredFirstName,
  //   valid: enteredFirstNameIsValid,
  //   onChange: firstNameInputChangeHandler,
  //   onBlur: firstNameInputBlurHandler,
  // } = useInput([
  //   {
  //     validator: (value: string) => validators.required(value),
  //   },
  // ]);

  // const {
  //   value: enteredLastName,
  //   valid: enteredLastNameIsValid,
  //   onChange: lastNameInputChangeHandler,
  //   onBlur: lastNameInputBlurHandler,
  // } = useInput([
  //   {
  //     validator: (value: string) => validators.required(value),
  //   },
  // ]);
  // const {
  //   value: enteredMiddleName,
  //   valid: enteredMiddleNameIsValid,
  //   onChange: middleNameInputChangeHandler,
  //   onBlur: middleNameInputBlurHandler,
  // } = useInput([
  //   {
  //     validator: (value: string) => validators.required(value),
  //   },
  // ]);
  // const {
  //   value: enteredGender,
  //   valid: enteredGenderIsValid,
  //   error: genderInputHasError,
  //   onChange: genderInputChangeHandler,
  //   onBlur: genderInputBlurHandler,
  // } = useInput([
  //   {
  //     validator: (value: string) => validators.required(value),
  //   },
  // ]);

  const {
    value: enteredName,
    valid: enteredNameIsValid,
    onChange: nameInputChangeHandler,
    onBlur: nameInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredWebsite,
    valid: enteredWebsiteIsValid,
    error: enteredWebsiteHasError,
    onChange: websiteInputChangeHandler,
    onBlur: websiteInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredCategory,
    valid: enteredCategoryIsValid,
    error: enteredCategoryHasError,
    onChange: categoryInputChangeHandler,
    onBlur: categoryInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  // const {
  //   value: enteredEmail,
  //   onChange: emailInputChangeHandler,
  //   onBlur: emailInputBlurHandler,
  // } = useInput([
  //   {
  //     validator: (value: string) => validators.required(value),
  //   },
  // ]);

  // const {
  //   phoneNumber,
  //   phoneInfo,
  //   phoneNumberInputChangeHandler,
  //   isValid: phoneNumberIsValid,
  // } = usePhoneInput([]);

  // const { value: enteredWhatsapp } = useInput([
  //   {
  //     validator: (value: string) => validators.required(value),
  //   },
  // ]);

  const {
    value: enteredPassword,
    valid: enteredPasswordIsValid,
    error: passwordInputHasError,
    onChange: passwordInputChangeHandler,
    onBlur: passwordInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.password(value),
    },
  ]);

  // const genderRolesOptions: Option[] = GENDER.map((role) => ({
  //   label: role.label as string,
  //   value: role.value,
  // }));

  // const stateLcdaOptions: Option[] = filteredLCDAs.map((role) => ({
  //   label: role.label as string,
  //   value: role.value,
  // }));

  // const [selectedState, setSelectedState] = React.useState<string>("");
  // const [filteredLCDAs, setFilteredLCDAs] = React.useState<
  //   SelectOption<string>[]
  // >([]);

  const [showPassword, setShowPassword] = React.useState<boolean>(false);

  const {
    loading: centerLoading,
    error: centerError,
    sendHttpRequest: onboardCenter,
  } = useHttp();

  const { loading, error, sendHttpRequest: onboardEmployer } = useHttp();

  // const { openDialog, handleOpenDialog } = useDialog();
  // const { validateForm } = useForm();

  const closure = () => {
    history.push("/auth/sign-in");
  };

  // const handleStateChange = (state: string | null) => {
  //   if (state) {
  //     setSelectedState(state);
  //     const selected = NIGERIA_STATES_LCDAS.find((s) => s.state === state);
  //     setFilteredLCDAs(
  //       selected
  //         ? selected.lcda.map((lcda) => ({ label: lcda, value: lcda }))
  //         : []
  //     );
  //   }
  // };

  let passwordErrorTip = passwordInputHasError
    ? "Minimum of 8 characters, consisting of at least an uppercase letter, a lowercase letter, a number and a special character"
    : undefined;

  const enteredUsePasswordIsValid = enteredPassword.trim().length >= 8; // Assuming a minimum of 8 characters

  // const phoneUseNumberIsValid = phoneNumber.trim() !== ""; // Or use a more complex regex check

  const formIsValid =
    // enteredFirstNameIsValid &&
    // enteredLastNameIsValid &&
    // enteredMiddleNameIsValid &&
    // enteredGenderIsValid &&
    // && enteredNameIsValid && enteredWebsiteIsValid
    enteredUsePasswordIsValid;
  // enteredNinIsValid &&
  // phoneUseNumberIsValid;

  let history = useHistory();

  const snackbarCtx = React.useContext(SnackbarContext);
  const { toast } = snackbarCtx;

  const preventDefault = (event: React.SyntheticEvent) =>
    event.preventDefault();

  const handleClickShowPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    preventDefault(event);
    setShowPassword((prevState) => !prevState);
  };

  const { form, addToOnboard, clearForm } = useOnboardForm();

  // console.log("form", form);

  // GET SUBSCRIPTION PLAN
  const subs = localStorage.getItem("subscriptionType");
  let finalSubType = "";

  if (!subs) {
    finalSubType = "Free Trial";
  } else {
    const subsType = JSON.parse(decrypt(subs));
    finalSubType = subsType.subscriptionType;
  }

  // console.log("subsType", subsType);
  // console.log("finalSubType", finalSubType);

  const { firstName, lastName } = splitName(enteredName);

  // const employerInfo = {
  //   email: form.email,
  //   whatsAppNumber: form.whatsapp ? form.whatsapp.replace(/\s+/g, "") : 0,
  //   firstName: form.firstname === "" ? firstName : form.firstname,
  //   lastName: form.lastname === "" ? lastName : form.lastname,
  //   // middleName: enteredWebsite,
  //   // sex: enteredGender,
  //   password: enteredPassword,
  //   // telephone: phoneNumber.replace(/\s+/g, ""),
  //   // subscriptionType: finalSubType,
  //   lcda: form.lcda,
  //   address: form.address,
  //   state: form.state,
  //   // nin: enteredNin,
  //   // bn: form.bn,
  //   businessId: form.centerId,
  //   verifiedCareCenter: true,
  //   role: "Employer",
  //   NumberOfEmployees: 0,
  //   status: "ACTIVE",
  //   countryCode: "NG",
  //   bvn: "",
  // };
  const [loader, setLoader] = React.useState<boolean>(false);

  const onboardEmployerHandler = async (
    event: React.ChangeEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    setLoader(true);

    if (!formIsValid) {
      toast({
        message: "Please input all required fields",
        variant: "warning",
      });
      return;
    }

    const employerInfo = {
      email: form.email,
      whatsAppNumber: form.whatsapp ? form.whatsapp.replace(/\s+/g, "") : 0,
      telephone: form.whatsapp ? form.whatsapp.replace(/\s+/g, "") : 0,
      firstName: form.firstname === "" ? firstName : form.firstname,
      lastName: form.lastname === "" ? lastName : form.lastname,
      password: enteredPassword,
      lcda: form.lcda,
      address: form.address,
      state: form.state,
      businessId: form.centerId,
      verifiedCareCenter: false,
      role: "Employer",
      NumberOfEmployees: 0,
      status: "ACTIVE",
      countryCode: "NG",
      bvn: "",
      nin: "",
    };

    // console.log("employerInfo", employerInfo);

    const proceedToOnboardEmployer = async (centerId: any) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/employer/create`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              ...employerInfo,
              businessId: centerId,
            }),
          }
        );

        if (!response.ok) {
          setLoader(false);
          throw new Error("Failed to onboard employer");
        }

        const data = await response.json();
        toast({
          message: "Successfully onboarded employer.",
          variant: "success",
        });
        setLoader(false);
        clearForm();
        history.push("/auth/login"); // Redirect to a relevant page
      } catch (error: any) {
        setLoader(false);
        toast({
          message: error.message || "Something went wrong",
          variant: "error",
        });
      }
    };

    if (form.centerId === 0) {
      const formData = new FormData();
      formData.append("businessEmail", form.email || "");
      formData.append(
        "businessTelephone",
        form.whatsapp ? form.whatsapp.replace(/\s+/g, "") : "0"
      );
      formData.append("legalBusinessName", enteredName || "");
      formData.append("lcda", form.lcda || "");
      formData.append("address", form.address || "");
      formData.append("state", form.state || "");
      formData.append("type", enteredCategory || "");
      formData.append("features", enteredWebsite || "");
      formData.append("slug", convertToSlug(enteredName) || "");
      formData.append("registrationNo", form.bn ? String(form.bn) : "");
      formData.append("verified", String(false));

      try {
        const response = await fetch(
          `${process.env.REACT_APP_CARE_CENTER_API}/join`,
          {
            method: "POST",
            body: formData,
          }
        );

        if (!response.ok) {
          setLoader(false);
          throw new Error("Failed to onboard care center");
        }

        const data = await response.json();
        toast({
          message: "Successfully onboarded care center.",
          variant: "success",
        });
        addToOnboard({ centerId: data.data.customerId });
        proceedToOnboardEmployer(data.data.customerId);
      } catch (error: any) {
        setLoader(false);
        toast({
          message: error.message || "Something went wrong",
          variant: "error",
        });
      }
    } else {
      proceedToOnboardEmployer(form.centerId);
    }
  };

  return (
    <React.Fragment>
      <BackdropLoader open={loader} />

      <Box className="text-center">
        <Typography
          variant="h1"
          fontSize={{ xs: "24px", sm: "28px", md: "28px" }}
          component="div"
          gutterBottom
          mb={5}
        >
          Enter your information
        </Typography>
      </Box>
      <Paper
        sx={{
          px: 3,
          py: 3,
          boxShadow: "0px 10px 16px rgba(154, 154, 154, 0.13)",
        }}
        className="h-full max-w-md mx-auto"
      >
        {error && (
          <Alert
            severity="error"
            sx={{
              mb: 3,
            }}
          >
            {error.message}
          </Alert>
        )}

        {/* autoComplete="off"
            noValidate */}
        <Box component={"form"} onSubmit={onboardEmployerHandler}>
          <MHFormControl
            id="name"
            type="text"
            label=""
            placeholder="Business Name"
            value={
              form.firstname === ""
                ? enteredName
                : `${form.firstname} ${form.lastname}`
            }
            onChange={nameInputChangeHandler}
            onBlur={nameInputBlurHandler}
            required
            // autoFocus
          />

          {/* <MHSelect
            label=""
            placeholder="Select State"
            options={NIGERIA_STATES_LCDAS.map((state) => ({
              label: state.state,
              value: state.state,
            }))}
            value={selectedState}
            onChange={handleStateChange}
            onBlur={() => {}}
            error={resolveErrorMessage(enteredFirstNameIsValid)(
              "Please select your state"
            )}
          /> */}

          <Box my={2}>
            {/* <MHAutocomplete
              label=""
              placeholder="Category"
              options={NIGERIA_STATES_LCDAS.map((state) => ({
                label: state.state,
                value: state.state,
              }))}
              onInputChange={handleStateChange}
            /> */}
            <MHSelect
              label="Category"
              placeholder=""
              options={CenterCategories}
              value={enteredCategory}
              onChange={(val) => categoryInputChangeHandler(val as string)}
              onBlur={categoryInputBlurHandler}
              error={resolveErrorMessage(enteredCategoryHasError)(
                "Please select a category"
              )}
            />
          </Box>

          <MHFormControl
            id="website"
            type="text"
            label=""
            placeholder="Business Website"
            value={enteredWebsite}
            onChange={websiteInputChangeHandler}
            onBlur={websiteInputBlurHandler}
            required
            // autoFocus
          />

          <MHFormControl
            id="password"
            type={showPassword ? "text" : "password"}
            label=""
            placeholder="Password"
            value={enteredPassword}
            onChange={passwordInputChangeHandler}
            onBlur={passwordInputBlurHandler}
            error={passwordErrorTip}
            startAdornment={
              <InputAdornment>
                <LockIcon width="1.2rem" />
              </InputAdornment>
            }
            endAdornment={
              <InputAdornment>
                <IconButton onClick={handleClickShowPassword}>
                  {showPassword ? (
                    <VisibilityOffIcon width="1rem" height="1rem" />
                  ) : (
                    <VisibilityIcon width="1rem" height="1rem" />
                  )}
                </IconButton>
              </InputAdornment>
            }
            required
          />

          <MHButton sx={{}} type="submit" loading={loading} fullWidth>
            Continue
          </MHButton>
        </Box>
      </Paper>

      {/* {openDialog && (
        <RequestConfirmationPrompt
          open={openDialog}
          onClose={closure}
          header="We'll be in touch soon!"
          message="Thanks for onboarding! Anticipate upcoming communications as we reach out to share more details about our platform. We're excited to connect with you soon."
        />
      )} */}
    </React.Fragment>
  );
};

export default OtherInformation;
