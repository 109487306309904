import { Alert, Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import MHFormControl from "../../Common/Form/MHFormControl";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import MHButton from "../../Common/Button/MHButton";
import useInput from "../../../hooks/use-input";
import useHttp from "../../../hooks/use-http";
import * as validators from "../../../utils/validators";

import SnackbarContext from "../../../store/context/snackbar.context";
import { db } from "../../../firebase";
// import MHPhoneInput from "../../Common/Form/MHPhoneInput";
// import usePhoneInput from "../../../hooks/use-phone";
// import { resolveErrorMessage } from "../../../utils/utils";
import MHDatePicker from "../../Common/Form/MHDatePicker";
import dayjs, { Dayjs } from "dayjs";

import { ReactComponent as MinusIcon } from "../../../static/svg/minus.svg";
import { ReactComponent as PlusIcon } from "../../../static/svg/plus.svg";

type Props = {
  careCenterId?: number | undefined;
};

interface Child {
  id: number; // A unique identifier for each child (e.g., sequential numbers or UUIDs).
  firstName: string; // The first name of the child, stored as a string.
  lastName: string; // The last name of the child, stored as a string.
  DOB: Dayjs | null; // Allow null for DOB // The date of birth of the child, represented using the `Dayjs` library for date handling.
}

const RequestCare = (props: Props) => {
  const {
    value: enteredfirstName,
    valid: enteredfirstNameIsValid,
    onChange: firstnameInputChangeHandler,
    onBlur: firstnameInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);
  const {
    value: enteredlastName,
    valid: enteredlastNameIsValid,
    onChange: lastnameInputChangeHandler,
    onBlur: lastnameInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);
  const {
    value: enteredEmail,
    valid: enteredEmailIsValid,
    onChange: emailInputChangeHandler,
    onBlur: emailInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredPhone,
    valid: enteredPhoneIsValid,
    onChange: phoneInputChangeHandler,
    onBlur: phoneInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredMessage,
    valid: enteredMessageIsValid,
    onChange: messageInputChangeHandler,
    onBlur: messageInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const [startDate, setStartDate] = React.useState<Dayjs | null>(dayjs());

  const [children, setChildren] = React.useState<Child[]>([
    { id: 1, firstName: "", lastName: "", DOB: dayjs() },
  ]);

  const addMoreChildren = () => {
    if (children.length < 5) {
      setChildren([
        ...children,
        { id: children.length + 1, firstName: "", lastName: "", DOB: dayjs() },
      ]);
    }
  };

  const removeChild = (childId: number) => {
    setChildren(children.filter((child) => child.id !== childId));
  };

  const handleInputChange = (
    childId: number,
    field: keyof Child, // Ensures the field is one of the keys in the Child type
    value: string | Dayjs
  ) => {
    setChildren(
      children.map((child) =>
        child.id === childId ? { ...child, [field]: value } : child
      )
    );
  };

  const formIsValid =
    enteredfirstNameIsValid &&
    enteredlastNameIsValid &&
    enteredEmailIsValid &&
    enteredMessageIsValid &&
    enteredPhoneIsValid;

  const resetForm = () => {
    firstnameInputChangeHandler("");
    lastnameInputChangeHandler("");
    emailInputChangeHandler("");
    phoneInputChangeHandler("");
    messageInputChangeHandler("");
  };

  const { loading, error, sendHttpRequest } = useHttp();

  const snackbarCtx = React.useContext(SnackbarContext);
  const { toast } = snackbarCtx;

  // console.log("careCenterId", props.careCenterId);
  const handleRequestSubmit = async (
    event: React.ChangeEvent<HTMLFormElement>
  ) => {
    event.preventDefault(); // Fixing the preventDefault call
    if (!formIsValid) {
      console.log("Complete Form");
      toast({
        message: "Kindly input your request",
        variant: "warning",
      });
      return;
    } else {
      // const url = process.env.REACT_APP_CARE_CENTER_BASE_API + `/care-request`;
      const requestBody = {
        firstName: enteredfirstName,
        lastName: enteredlastName,
        email: enteredEmail,
        telephone: enteredPhone,
        startDate: startDate,
        message: enteredMessage,
        children: children,
        careCenterId: props.careCenterId,
        status: "NEW-REQUEST",
      };

      await sendHttpRequest(
        process.env.REACT_APP_CARE_CENTER_BASE_API + "/care-request",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        },
        () => {
          toast({
            message:
              "Thank you for your request. We will get back to you shortly!",
            variant: "success",
          });
          // Optionally reset the form
          resetForm();
          setChildren([{ id: 1, firstName: "", lastName: "", DOB: null }]);
        }
      );
      // try {
      //   const response = await fetch(url, {
      //     method: "POST",
      //     headers: {
      //       "Content-Type": "application/json",
      //     },
      //     body: JSON.stringify(requestBody),
      //   });

      //   if (response.ok) {
      //     toast({
      //       message:
      //         "Thank you for your request. We will get back to you shortly!",
      //       variant: "success",
      //     });

      //     // Optionally reset the form
      //     resetForm();
      //     setChildren([{ id: 1, firstName: "", lastName: "", DOB: null }]);
      //   } else {
      //     toast({
      //       message: `Error submitting request`,
      //       variant: "error",
      //     });
      //   }
      // } catch (error) {
      //   console.error("Error submitting request:", error);
      //   toast({
      //     message: "An error occurred. Please try again.",
      //     variant: "error",
      //   });
      // }
    }
  };

  return (
    <React.Fragment>
      <Box

      //   className=" shadow-[3px_5px_30px_-5px_rgba(0,0,0,0.3)] mx-auto h-fit text-start"
      >
        <Grid
          container
          spacing={1}
          className=" lg:gap-2 mx-auto "
          component="form"
          onSubmit={handleRequestSubmit}
        >
          <Typography
            variant="h2"
            fontSize={{ xs: "24px", sm: "24px", md: "24px" }}
            lineHeight={"120.5%"}
            letterSpacing={"-0.04em"}
            align="center"
            className=" capitalize"
          >
            Request For Care
          </Typography>
          {error && (
            <Alert
              severity="error"
              sx={{
                mb: 3,
              }}
            >
              {error.message}
            </Alert>
          )}
          <Grid
            container
            spacing={1}
            className=" my-4 place-content-center text-start"
            // component="form"
            // onSubmit={tester}
          >
            <Grid item xs={11} sm={6} md={6} lg={6} className="">
              <MHFormControl
                id="fname"
                type="text"
                label="First Name"
                placeholder="First Name"
                value={enteredfirstName}
                onChange={firstnameInputChangeHandler}
                onBlur={firstnameInputBlurHandler}
                required
                // autoFocus
              />
            </Grid>

            <Grid item xs={11} sm={6} md={6} lg={6} className="">
              <MHFormControl
                id="lname"
                type="text"
                label="Last Name"
                placeholder="Last Name"
                value={enteredlastName}
                onChange={lastnameInputChangeHandler}
                onBlur={lastnameInputBlurHandler}
                required
                // autoFocus
              />
            </Grid>

            <Grid item xs={11} sm={6} md={6} lg={6} className="">
              <MHFormControl
                id="email"
                type="email"
                label="Email"
                placeholder="Email"
                value={enteredEmail}
                onChange={emailInputChangeHandler}
                onBlur={emailInputBlurHandler}
                required
                // autoFocus
              />
            </Grid>
            <Grid item xs={11} sm={6} md={6} lg={6} className="">
              <MHFormControl
                id="phone"
                type="tel"
                label="Phone"
                placeholder="Phone"
                value={enteredPhone}
                onChange={phoneInputChangeHandler}
                onBlur={phoneInputBlurHandler}
                required
              />
              {/* <MHPhoneInput
              value={phoneNumber}
              onChange={phoneNumberInputChangeHandler}
              label="Phone"
              errorMessage={resolveErrorMessage(phoneNumberIsValid)(
                "Please enter a valid phone number"
              )}
            /> */}
            </Grid>

            <Grid item xs={11} sm={12} md={12} lg={12} className="">
              <label className=" font-areaSemi text-xs ">Start Date</label>

              <MHDatePicker
                value={startDate}
                onChange={(newvalue) => setStartDate(newvalue)}
              />
            </Grid>

            <Grid item xs={11} sm={12} md={12} lg={12} className="">
              <MHFormControl
                id="message"
                type="text"
                label="Message"
                placeholder="Reason for submitting this request."
                value={enteredMessage}
                onChange={messageInputChangeHandler}
                onBlur={messageInputBlurHandler}
                multiline
                rows={6}
                required
                // autoFocus
              />
            </Grid>
            <Grid item xs={11} sm={12} md={12} lg={12} className="">
              {/* Add a child  */}

              {children.map((child) => (
                <Grid container spacing={2} key={child.id} className="">
                  <Grid item xs={10} sm={5}>
                    <MHFormControl
                      id={`firstName-${child.id}`}
                      type="text"
                      label="Child's First Name"
                      placeholder="Child's First Name"
                      value={child.firstName}
                      onChange={(event) =>
                        handleInputChange(
                          child.id,
                          "firstName",
                          event.target.value
                        )
                      }
                      required
                    />
                  </Grid>
                  <Grid item xs={10} sm={5}>
                    <MHFormControl
                      id={`lastName-${child.id}`}
                      type="text"
                      label="Child's Last Name"
                      placeholder="Child's Last Name"
                      value={child.lastName}
                      onChange={(event) =>
                        handleInputChange(
                          child.id,
                          "lastName",
                          event.target.value
                        )
                      }
                      required
                    />
                  </Grid>
                  <Grid item xs={8} sm={8}>
                    <label className="font-areaSemi text-xs">
                      Child's Birth Date
                    </label>
                    <MHDatePicker
                      value={child.DOB}
                      onChange={
                        (newValue) =>
                          handleInputChange(
                            child.id,
                            "DOB",
                            newValue || dayjs()
                          ) // Ensure non-null value
                      }
                    />
                  </Grid>
                  {children.length > 1 && (
                    <Grid
                      item
                      xs={2}
                      sm={2}
                      mt={3}
                      display="flex"
                      justifyContent="end"
                      justifySelf="end"
                    >
                      <button
                        onClick={() => removeChild(child.id)}
                        style={{
                          backgroundColor: "whitesmoke",
                          // paddingBottom: 0.5,
                          padding: 2,
                          borderRadius: "50%",
                          height: "30%",
                          width: "30%",
                          justifyContent: "center",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <Box>
                          <MinusIcon />
                        </Box>
                      </button>
                    </Grid>
                  )}
                </Grid>
              ))}

              {children.length < 5 && (
                <Button
                  variant="text"
                  color="primary"
                  onClick={addMoreChildren}
                  className="my-2 mx-auto gap-1 text-[#A9A9A9] flex"
                >
                  <PlusIcon /> Add another child
                </Button>
              )}
            </Grid>

            <Grid item xs={11} sm={12} md={12} lg={12} className="">
              <MHButton
                color="primary"
                sx={{}}
                type="submit"
                loading={loading}
                // onClick={tester}
                className="pt-2  capitalize w-full h-[50px]"
              >
                Submit Request
              </MHButton>
            </Grid>
          </Grid>
        </Grid>
        <Typography
          variant="body1"
          fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
          lineHeight={"130%"}
          letterSpacing={"-0.04em"}
          className=" text-start mt-2"
          color="#A9A9A9"
        >
          Your information is only shared with this provider for the purposes or
          responaing to your Inquiry.
        </Typography>
      </Box>
    </React.Fragment>
  );
};

export default RequestCare;
function preventDefault(event: React.ChangeEvent<HTMLFormElement>) {
  throw new Error("Function not implemented.");
}
