export function convertToSlug(Text:string) {
    if (typeof Text !== "string") {
      // Handle the case where Text is not a string, throw an error or return a default value
      return "";
    }

    return Text.toLowerCase()
      .replace(/ /g, "-")
      .replace(/&/g, "and")
      .replace(/[^\w-]+/g, "");
  }