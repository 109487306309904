import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useHistory, useRouteMatch } from "react-router-dom";
import useDialog from "../../hooks/use-dialog";

// type Props = {}

const CareProviderSection = () => {
  let history = useHistory();

  const handleClickOpen = () => {
    history.push(`/employers`);
  };

  const {
    openDialog: open,
    handleOpenDialog: handleOpen,
    handleCloseDialog,
  } = useDialog();

  const handleClose = () => {
    handleCloseDialog();
  };

  return (
    <React.Fragment>
      <Box className="pt-10">
        <Box className="items-center place-content-center ">
          {/* <Typography className="uppercase font-areaNorm text-[12px] text-center md:text-[11px]  lg:text-[11px] leading-[102%] tracking-[0.1em] text-navy-900 ">
            THE ENTERPRISE PLAN
          </Typography> */}

          {/* <Typography className="py-8 font-columbia text-[30px] text-center md:text-[40px]  lg:text-[50px] leading-[120.5%] tracking-[-0.04em] text-navy-900 ">
            Join the many forward-
            <br className="md:hidden block" />
            thinking companies <br /> supporting their employee caregivers
          </Typography> */}
          <Typography
            variant="h2"
            width={{ xs: "90%", md: "65%" }}
            fontSize={{ xs: "30px", sm: "40px", md: "50px" }}
            lineHeight={"120.5%"}
            letterSpacing={"-0.04em"}
            mx="auto"
            className="py-8  text-center"
          >
            How Caring Blocks Helps Your Center Thrive
          </Typography>

          {/* Web */}

          <Box
            className="hidden md:grid grid-cols-3 items-center mx-auto text-center"
            justifyContent="center"
          >
            {/* <Typography className="ml-8 mr-8 py-8 font-areaSemi text-[14px] text-center md:text-[14px]  lg:text-[16px] leading-[170%] md:leading-[150%] tracking-[0.02em] text-navy-900 ">
              Personalized Employee Work- <br /> Life Care Solutions
            </Typography> */}
            <Box className="ml-8 mr-8 py-8">
              <Typography
                variant="subtitle1"
                fontSize={{ xs: "12px", sm: "14px" }}
                lineHeight={"102%"}
                letterSpacing={"0.1em"}
                className="uppercase text-center"
              >
                Dedicated Platform Page
              </Typography>
              <Typography
                variant="body1"
                fontSize={{ xs: "12px", sm: "14px", md: "15px" }}
                lineHeight={"150%"}
                letterSpacing={"0.02em"}
                className="py-4  text-center  "
              >
                Showcase your center to families and employers with a free,
                customized page that highlights your services, staff
                credentials, real-time enrollment information, and availability.
              </Typography>
            </Box>

            <Box className="ml-8 mr-8 py-8">
              <Typography
                variant="subtitle1"
                fontSize={{ xs: "12px", sm: "14px" }}
                lineHeight={"102%"}
                letterSpacing={"0.1em"}
                className="uppercase text-center"
              >
                Enrollment Partnerships
              </Typography>
              <Typography
                variant="body1"
                fontSize={{ xs: "12px", sm: "14px", md: "15px" }}
                lineHeight={"150%"}
                letterSpacing={"0.02em"}
                className="py-4  text-center  "
              >
                Benefit from our partnerships with families and corporate
                employers who trust Caring Blocks to match them with reliable
                childcare solutions. Our platform bridges the gap, ensuring
                you're connected to the right clients.
              </Typography>
            </Box>
            <Box className="ml-8 mr-8 py-8">
              <Typography
                variant="subtitle1"
                fontSize={{ xs: "12px", sm: "14px" }}
                lineHeight={"102%"}
                letterSpacing={"0.1em"}
                className="uppercase text-center"
              >
                Ongoing Training and Support
              </Typography>
              <Typography
                variant="body1"
                fontSize={{ xs: "12px", sm: "14px", md: "15px" }}
                lineHeight={"150%"}
                letterSpacing={"0.02em"}
                className="py-4  text-center  "
              >
                Equip your team with knowledge and skills through Caring Blocks
                Institute training programs. From health and safety to early
                childhood development, we ensure your staff is always prepared.
              </Typography>
            </Box>

            <Box className="ml-8 mr-8 py-8">
              <Typography
                variant="subtitle1"
                fontSize={{ xs: "12px", sm: "14px" }}
                lineHeight={"102%"}
                letterSpacing={"0.1em"}
                className="uppercase text-center"
              >
                Employee Management Tools
              </Typography>
              <Typography
                variant="body1"
                fontSize={{ xs: "12px", sm: "14px", md: "15px" }}
                lineHeight={"150%"}
                letterSpacing={"0.02em"}
                className="py-4  text-center  "
              >
                Vet, onboard, and pay your employees all in one place. Caring
                Blocks' platform makes it easy to handle everything from initial
                hiring to payroll and ongoing performance management.
              </Typography>
            </Box>

            <Box className="ml-8 mr-8 py-8">
              <Typography
                variant="subtitle1"
                fontSize={{ xs: "12px", sm: "14px" }}
                lineHeight={"102%"}
                letterSpacing={"0.1em"}
                className="uppercase text-center"
              >
                Performance Feedback Tools
              </Typography>
              <Typography
                variant="body1"
                fontSize={{ xs: "12px", sm: "14px", md: "15px" }}
                lineHeight={"150%"}
                letterSpacing={"0.02em"}
                className="py-4  text-center  "
              >
                Receive feedback and insights from families through our platform
                to improve your services and maintain strong relationships with
                parents.
              </Typography>
            </Box>
            <Box className="ml-8 mr-8 py-8">
              <Typography
                variant="subtitle1"
                fontSize={{ xs: "12px", sm: "14px" }}
                lineHeight={"102%"}
                letterSpacing={"0.1em"}
                className="uppercase text-center"
              >
                Community of Excellence
              </Typography>
              <Typography
                variant="body1"
                fontSize={{ xs: "12px", sm: "14px", md: "15px" }}
                lineHeight={"150%"}
                letterSpacing={"0.02em"}
                className="py-4  text-center  "
              >
                Join a network of childcare centers committed to raising the
                standard of care in Nigeria. Collaborate, share best practices,
                and access exclusive resources to keep your center at the
                forefront of the industry.
              </Typography>
            </Box>
            {/* &bull; */}
          </Box>

          {/* Mobile */}

          <Box className="md:hidden block items-center place-content-center mx-auto text-center">
            <Box className="ml-8 mr-8 py-8">
              <Typography
                variant="subtitle1"
                fontSize={{ xs: "12px", sm: "14px" }}
                lineHeight={"102%"}
                letterSpacing={"0.1em"}
                className="uppercase text-center"
              >
                Dedicated Platform Page
              </Typography>
              <Typography
                variant="body1"
                fontSize={{ xs: "12px", sm: "14px", md: "15px" }}
                lineHeight={"150%"}
                letterSpacing={"0.02em"}
                className="py-4  text-center  "
              >
                Showcase your center to families and employers with a free,
                customized page that highlights your services, staff
                credentials, real-time enrollment information, and availability.
              </Typography>
            </Box>

            <Box className="ml-8 mr-8 py-8">
              <Typography
                variant="subtitle1"
                fontSize={{ xs: "12px", sm: "14px" }}
                lineHeight={"102%"}
                letterSpacing={"0.1em"}
                className="uppercase text-center"
              >
                Enrollment Partnerships
              </Typography>
              <Typography
                variant="body1"
                fontSize={{ xs: "12px", sm: "14px", md: "15px" }}
                lineHeight={"150%"}
                letterSpacing={"0.02em"}
                className="py-4  text-center  "
              >
                Benefit from our partnerships with families and corporate
                employers who trust Caring Blocks to match them with reliable
                childcare solutions. Our platform bridges the gap, ensuring
                you're connected to the right clients.
              </Typography>
            </Box>
            <Box className="ml-8 mr-8 py-8">
              <Typography
                variant="subtitle1"
                fontSize={{ xs: "12px", sm: "14px" }}
                lineHeight={"102%"}
                letterSpacing={"0.1em"}
                className="uppercase text-center"
              >
                Ongoing Training and Support
              </Typography>
              <Typography
                variant="body1"
                fontSize={{ xs: "12px", sm: "14px", md: "15px" }}
                lineHeight={"150%"}
                letterSpacing={"0.02em"}
                className="py-4  text-center  "
              >
                Equip your team with knowledge and skills through Caring Blocks
                Institute training programs. From health and safety to early
                childhood development, we ensure your staff is always prepared.
              </Typography>
            </Box>

            <Box className="ml-8 mr-8 py-8">
              <Typography
                variant="subtitle1"
                fontSize={{ xs: "12px", sm: "14px" }}
                lineHeight={"102%"}
                letterSpacing={"0.1em"}
                className="uppercase text-center"
              >
                Employee Management Tools
              </Typography>
              <Typography
                variant="body1"
                fontSize={{ xs: "12px", sm: "14px", md: "15px" }}
                lineHeight={"150%"}
                letterSpacing={"0.02em"}
                className="py-4  text-center  "
              >
                Vet, onboard, and pay your employees all in one place. Caring
                Blocks' platform makes it easy to handle everything from initial
                hiring to payroll and ongoing performance management.
              </Typography>
            </Box>

            <Box className="ml-8 mr-8 py-8">
              <Typography
                variant="subtitle1"
                fontSize={{ xs: "12px", sm: "14px" }}
                lineHeight={"102%"}
                letterSpacing={"0.1em"}
                className="uppercase text-center"
              >
                Performance Feedback Tools
              </Typography>
              <Typography
                variant="body1"
                fontSize={{ xs: "12px", sm: "14px", md: "15px" }}
                lineHeight={"150%"}
                letterSpacing={"0.02em"}
                className="py-4  text-center  "
              >
                Receive feedback and insights from families through our platform
                to improve your services and maintain strong relationships with
                parents.
              </Typography>
            </Box>
            <Box className="ml-8 mr-8 py-8">
              <Typography
                variant="subtitle1"
                fontSize={{ xs: "12px", sm: "14px" }}
                lineHeight={"102%"}
                letterSpacing={"0.1em"}
                className="uppercase text-center"
              >
                Community of Excellence
              </Typography>
              <Typography
                variant="body1"
                fontSize={{ xs: "12px", sm: "14px", md: "15px" }}
                lineHeight={"150%"}
                letterSpacing={"0.02em"}
                className="py-4  text-center  "
              >
                Join a network of childcare centers committed to raising the
                standard of care in Nigeria. Collaborate, share best practices,
                and access exclusive resources to keep your center at the
                forefront of the industry.
              </Typography>
            </Box>

            {/* <Typography
              variant="body1"
              fontSize={{ xs: "12px" }}
              lineHeight={"170%"}
              letterSpacing={"0.02em"}
              className=" py-3 text-center"
            >
              Employees need help navigating life hurdles and
              <br /> the daily transitions between caregiving,
              <br /> work and life.
            </Typography> */}
          </Box>

          {/* <Box className="mx-auto text-center py-10 md:py-0">
            <MHButton
              sx={{
                width: 'fit-content'
              }}
              onClick={handleOpen}>
              Request a Demo
            </MHButton>
          </Box> */}
        </Box>

        <hr className="hidden md:block w-[90%] mx-auto h-0 border-t-lilac-300 mt-10" />
      </Box>
    </React.Fragment>
  );
};

export default CareProviderSection;
