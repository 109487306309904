import * as CryptoJS from 'crypto-js';
import { ItemList, ResourceItemType } from '../models/resources.interface';
import { ReactNode } from 'react';


export function limitString(inputString: any,limit: any) {

  // Check if the string length exceeds the limit
  if (inputString.length > limit) {
    // If it does, limit the string and add an ellipsis
    return inputString.slice(0, limit) + "...";
  }

  // If the string doesn't exceed the limit, return the original string
  return inputString;
}

// Function to compare resources start times and return in ascending order
export function sortById(a:any, b:any) {
  const IdA = new Date(a.id);
  const IdB = new Date(b.id);

  if (IdA < IdB) {
    return -1;
  } else if (IdA > IdB) {
    return 1;
  } else {
    return 0;
  }
}
export const checkInvalidArray = <T>(arr: Array<T>): T[] => {
  if (!arr || !Array.isArray(arr)) {
    return [];
  }

  return arr;
};


// Function to compare resources start times and return in descending order
export function compareStartTimeDesc(a:any, b:any) {
  const startTimeA = new Date(a.startTime);
  const startTimeB = new Date(b.startTime);

  if (startTimeB < startTimeA) {
    return -1;
  } else if (startTimeB > startTimeA) {
    return 1;
  } else {
    return 0;
  }
}


export const formatResourceList = (
  resources: ResourceItemType[]
): Array<ResourceItemType> => {
  return checkInvalidArray(resources)
    ? resources.map((dataItem) => {
        const priorityList: string[] = dataItem.priorities.split(',');
        let newItemList: ItemList[] = [];

        for (const item of priorityList) {
          const newObject = dataItem.itemList.find(
            (it: any) => it.id === +item
          );

          if (newObject) {
            if (!newItemList.length) {
              newItemList = [newObject];
            } else {
              newItemList = [...newItemList, newObject];
            }
          }
        }

        return {
          ...dataItem,
          newItemList
        };
      })
    : [];
};

export const formatResourceItem = (
  resource: ResourceItemType
): ResourceItemType => {
  const priorityList: string[] = resource.priorities.split(',');
  let newItemList: ItemList[] = [];

  for (const item of priorityList) {
    const newObject = resource.itemList.find((it) => it.id === +item);

    if (newObject) {
      if (!newItemList.length) {
        newItemList = [newObject];
      } else {
        newItemList = [...newItemList, newObject];
      }
    }
  }

  const formattedResource: ResourceItemType = {
    ...resource,
    newItemList
  };

  return formattedResource;
}

export const isEmpty = (value: any) => {
  return value === undefined || value === null || value === '';
};

export const encrypt = (value: string): string => {
  return CryptoJS.AES.encrypt(
    value,
    process.env.REACT_APP_ENCRYPTION_KEY as string
  ).toString();
};

export const decrypt = (encryptedStr: string) => {
  return CryptoJS.AES.decrypt(
    encryptedStr,
    process.env.REACT_APP_ENCRYPTION_KEY as string
  ).toString(CryptoJS.enc.Utf8);
};

export const isHexColorBright = (color: string) => {
  const hex = color.replace('#', '');
  const c_r = parseInt(hex.substring(0, 2), 16);
  const c_g = parseInt(hex.substring(2, 4), 16);
  const c_b = parseInt(hex.substring(4, 6), 16);
  const brightness = (c_r * 299 + c_g * 587 + c_b * 114) / 1000;
  return brightness > 155;
};

export const generateRandomString = (options: { length: number }) => {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  let result = '';

  for (let i = 0; i < options.length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }

  return result;
};

export const isValidDate = (date: Date | string) => {
  date = new Date(date);
  return (
    date instanceof Date && !isNaN(Date.parse((date as unknown) as string))
  );
};

export const getURLWithQueryParams = (
  base: string,
  params: Record<string, string>
) => {
  const query = Object.entries(params)
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join('&');

  return `${base}?${query}`;
};

export const formatAmount = (amount: number, precision: number = 2) => {
  if (typeof amount === 'string') {
    amount = parseFloat(amount);
  }

  if (isNaN(amount)) {
    return '0.00';
  }

  const options = {
    style: 'currency',
    currency: 'NGN',
    minimumFractionDigits: precision
  };

  return new Intl.NumberFormat('en-NG', options).format(amount);
};

export const formatNumber = (figure: number, precision?: number) => {
  if (typeof figure === 'string') {
    figure = parseFloat(figure);
  }

  if (isNaN(figure)) {
    return '0.00';
  }

  return new Intl.NumberFormat('en-US', precision ?  { minimumFractionDigits: precision } : {} ).format(figure);
};

export const parseAmount = (amount: string): string => {
  amount = amount.replace(/,/g, '').trim();
  return amount;
};

export const constructHyphenatedDateFormat = (date: Date) => {
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
};

export function constructSlashSeperatedDateFormat(date: Date): string {
  date = date instanceof Date ? date : new Date(date);
  const month =
    date.getMonth() + 1 < 10
      ? '0' + (date.getMonth() + 1)
      : date.getMonth() + 1;
  const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
  return `${month}/${day}/${date.getFullYear()}`;
}

export const constructBillingDateFormat = (date: Date) => {
  const day = date.getDate();
  const month = date.toLocaleString('default', {
    month: 'short'
  });
  const year = date.getFullYear();
  return `${day} ${month} ${year}`;
};

export function addDaysToDate(date: Date, days: number) {
  date = new Date(date.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

export const convertFileSizeFromBytes = (size: number) => (
  unit: 'kb' | 'mb'
) => {
  if (unit === 'kb') {
    return (size / 1024).toFixed(2) + ' Kb';
  }

  return (size / 1024 / 1024).toFixed(2) + ' Mb';
};

export function formatFileSize(size: number) {
  if (size < 1024) {
    return `${size} bytes`;
  } else if (size >= 1024 && size < 1048576) {
    return `${(size / 1024).toFixed(2)} KB`;
  } else if (size >= 1048576) {
    return `${(size / 1048576).toFixed(2)} MB`;
  }
}

export const isValidFileType = (file: File, allowedTypes: string) => {
  if (!file) return false;
  const fileType = file.type;
  const allowed = allowedTypes
    .split(',')
    .map((fileType) => fileType.trim())
    .find((type) => fileType.includes(type));
  return allowed !== undefined;
};

export const isValidFileSize = (file: File, maxSize: number) => {
  if (!file) return false;
  return file.size! / 1024 / 1024 <= maxSize;
};

export const formatEmailForStorage = (email: string): string => {
  return String(email).toLowerCase().trim();
};

export const resolveErrorMessage = (error: boolean) => (message: string) => {
  if (error) {
    return message;
  }

  return undefined;
};

export function getBrowserVisibilityProp() {
  if (typeof document.hidden !== 'undefined') {
    // Opera 12.10 and Firefox 18 and later support
    return 'visibilitychange';
  } else if (typeof (document as any).msHidden !== 'undefined') {
    return 'msvisibilitychange';
  } else if (typeof (document as any).webkitHidden !== 'undefined') {
    return 'webkitvisibilitychange';
  }
}

export function getBrowserDocumentHiddenProp() {
  if (typeof document.hidden !== 'undefined') {
    return 'hidden';
  } else if (typeof (document as any).msHidden !== 'undefined') {
    return 'msHidden';
  } else if (typeof (document as any).webkitHidden !== 'undefined') {
    return 'webkitHidden';
  } else {
    return 'hidden';
  }
}

export function getIsDocumentHidden() {
  return !(document as any)[getBrowserDocumentHiddenProp()];
}

// Define the SelectOption type if it's not defined elsewhere

type SelectOption<T> = {
  label: ReactNode;
  value: T;
};

export const sortArray = (
  arr: SelectOption<string>[] | null
): SelectOption<string>[] => {
  if (!arr) {
    return []; // Return an empty array if the input is null
  }

  return arr.sort((a, b) => {
    const labelA = typeof a.label === 'string' ? a.label.toUpperCase() : '';
    const labelB = typeof b.label === 'string' ? b.label.toUpperCase() : '';

    if (labelA < labelB) {
      return -1;
    }
    if (labelA > labelB) {
      return 1;
    }

    // labels are equal
    return 0;
  });
};




export const sortListByIdAsc = (list: any[]): any[] => {
  const sortedList = list.sort((a, b) => {
    if (a.id < b.id) {
      return -1;
    }

    if (a.id > b.id) {
      return 1;
    }

    return 0;
  });

  return sortedList;
};

function get_random(list: any[]) {
  return list[Math.floor(Math.random() * list.length)];
}

export const roundToUpperPlaceValue = (figure: number) => (
  nearestPlace: number
) => {
  if (!figure || !nearestPlace) {
    return 0;
  }

  if (isNaN(figure) || isNaN(nearestPlace)) {
    return 0;
  }

  return Math.ceil(figure / nearestPlace) * nearestPlace;
};

export const getPlaceValue = (figure: number): number => {
  let place = 1;

  if (figure < 1) {
    place = 1;
  } else if (figure > 1 && figure < 10) {
    place = 1;
  } else if (figure > 10 && figure < 100) {
    place = 10;
  } else if (figure > 100 && figure < 1000) {
    place = 100;
  } else if (figure > 1000 && figure < 10000) {
    place = 1000;
  } else if (figure > 10000 && figure < 100000) {
    place = 10000;
  } else if (figure > 100000 && figure < 1000000) {
    place = 100000;
  } else if (figure > 1000000 && figure < 10000000) {
    place = 1000000;
  } else if (figure > 10000000 && figure < 100000000) {
    place = 10000000;
  }

  return place;
};

const roundToCeiling = () => () => {};

export function getRandomItemFromArray<T>(list: T[]): T {
  return list[Math.floor(Math.random() * list.length)];
}

export function getMultipleRandomItemsFromArray<T>(list: T[], count: number) {
  const randomItems: T[] = [];
  // for (let i = 0; i < 3; i++) {
  // }

  while (randomItems.length < count) {
    const rand = list[Math.floor(Math.random() * list.length)];

    if (!randomItems.includes(rand)) {
      randomItems.push(rand);
    }
  }

  return randomItems;
}


// date function e.g 12/10/2023
export function convertUTCToDate(utcTimestamp: any) {
  const date = new Date(utcTimestamp);
  const day = date.getUTCDate();
  const month = date.getUTCMonth() + 1; // Month is zero-based, so add 1
  const year = date.getUTCFullYear(); // Get the year

  const formattedDateTime = `${day}/${month}/${year}`;
  return formattedDateTime;
}



// date function e.g OCT 12, 2023
export function convertUTCToWordDate(utcTimestamp: any) {
  const months = [
    "january", "february", "march", "april", "may", "june",
    "july", "august", "september", "october", "november", "december"
  ];

  const date = new Date(utcTimestamp);
  const month = months[date.getUTCMonth()];
  const day = date.getUTCDate();
  const year = date.getUTCFullYear(); // Get the year

  const formattedDateTime = `${month?.slice(0, 3)} ${day}, ${year}`;
  return formattedDateTime;
}
// date function e.g OCTOBER
export function extractDateMonth(utcTimestamp: any) {
  const months = [
    "january", "february", "march", "april", "may", "june",
    "july", "august", "september", "october", "november", "december"
  ];

  const date = new Date(utcTimestamp);
  const month = months[date.getUTCMonth()];
  const day = date.getUTCDate();
  const year = date.getUTCFullYear(); // Get the year

  const formattedDateMonth = `${month}`;
  return formattedDateMonth;
}


export const formatCardNumber = (cardNo: string | number): string => {
  if (typeof cardNo !== 'string') {
    cardNo = cardNo.toString();
  }

  const lastFourDigits = cardNo.slice(-4);
  const maskedSection = cardNo.slice(0, -4).replace(/./g, '*');
  return maskedSection + lastFourDigits;
};

export function convertTo12Hour(time: string): string {
  let [hours, minutes] = time.split(":").map(Number);
  const period = hours >= 12 ? "PM" : "AM";

  // Convert hours to 12-hour format
  hours = hours % 12 || 12; // The `|| 12` handles the case for 0 hours (midnight) or 12 hours (noon)

  // Ensure minutes are always two digits
  const minutesStr = minutes.toString().padStart(2, '0');

  return `${hours}:${minutesStr} ${period}`;
}


// get date and time e.g September 12, 12pm
export function convertUTCToDateTime(utcTimestamp: any) {
  const months = [
    "JANUARY", "FEBRUARY", "MARCH", "APRIL", "MAY", "JUNE",
    "JULY", "AUGUST", "SEPTEMBER", "OCTOBER", "NOVEMBER", "DECEMBER"
  ];

  const date = new Date(utcTimestamp);
  const month = months[date.getUTCMonth()];
  const day = date.getUTCDate();
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();

  let period = 'AM';
  let formattedHours = hours;

  if (hours >= 12) {
    period = 'PM';
    if (hours > 12) {
      formattedHours = hours - 12;
    }
  }

  const formattedDateTime = `${month} ${day}, ${formattedHours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${period}`;
  return formattedDateTime;
}