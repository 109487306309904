import { Avatar, Box, Divider, Grid, Typography } from "@mui/material";
import React from "react";

import MHFormControl from "../../Common/Form/MHFormControl";
import InputAdornment from "../../Common/Form/InputAdornment";
import * as validators from "../../../utils/validators";
import { ReactComponent as EditIcon } from "../../../static/svg/edit-black.svg";
// import { ReactComponent as Visibility } from "../../../static/svg/visibility.svg";
// import { ReactComponent as VisibilityOff } from "../../../static/svg/visibility-off.svg";
import { ReactComponent as AvatarBadgeIcon } from "../../../static/svg/avatar-badge.svg";

import useInput from "../../../hooks/use-input";
import MHTextInput from "../../Common/Form/MHTextInput";
import MHPasswordControl from "../../Common/Form/MHPasswordControl";
import MHButton from "../../Common/Button/MHButton";
import AuthContext from "../../../store/context/auth-context";
import DashboardContext from "../../../store/context/dashboard.context";
import useDialog from "../../../hooks/use-dialog";
import PinDialog from "./PinDialog";
import { Organization } from "../../../models/employer.model";
import useHttp from "../../../hooks/use-http";
import { HttpResponse } from "../../../models/api.interface";
import SnackbarContext from "../../../store/context/snackbar.context";
import CenterAvatarDialog from "./CenterAvatarDialog";
import {
  AboutLikeProps,
  CareProviderProps,
} from "../../../models/care.details";
type Props = {};

const AccountSettings = (props: Props) => {
  const authCtx = React.useContext(AuthContext);
  const { user } = authCtx;

  const snackbarCtx = React.useContext(SnackbarContext);
  const { toast } = snackbarCtx;

  const dashboardCtx = React.useContext(DashboardContext);
  const { carecenter, updateCenterRecord, updateDirectorRecord } = dashboardCtx;

  const caredata = carecenter?.careCenter?.[0];
  const directorsBio = carecenter?.directorsBio?.[0];

  const [disabledState, setDisabledState] = React.useState({
    fname: true,
    bio: true,
    email: true,
    phone: true,
    // address: true,
    // city: true,
  });

  const {
    value: enteredFirstName,
    valid: enteredFirstNameIsValid,
    error: firstNameInputHasError,
    onChange: firstNameInputChangeHandler,
    onBlur: firstNameInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);
  const {
    value: enteredBio,
    valid: enteredBioIsValid,
    error: bioInputHasError,
    onChange: bioInputChangeHandler,
    onBlur: bioInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredEmail,
    valid: enteredEmailIsValid,
    error: enteredEmailHasError,
    onChange: emailInputChangeHandler,
    onBlur: emailInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
    {
      validator: (value: string) => validators.email(value),
    },
  ]);

  const {
    value: enteredAddress,
    valid: enteredAddressIsValid,
    error: addressInputHasError,
    onChange: addressInputChangeHandler,
    onBlur: addressInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredPhone,
    valid: enteredPhoneIsValid,
    error: phoneInputHasError,
    onChange: phoneInputChangeHandler,
    onBlur: phoneInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const handleCancel = () => {
    console.log("Reset all inputs");
  };

  const handleSaveAccount = async () => {
    // console.log("Save new account settings");
    // console.log("Save new account settings");

    // console.log("enteredPhone", enteredPhone);
    // console.log("enteredAddress", enteredAddress);

    if (!updateCenterRecord) {
      console.error("updateCenterRecord is not defined in DashboardContext");
      return;
    }

    const updatedDirector: CareProviderProps = {
      ...caredata,
      careProviderName: enteredFirstName,
      telephone: enteredPhone,
      email: enteredEmail,
      // lastName: enteredBio,
      // email: enteredEmail,
      // Add other properties as needed
    };

    console.log(updatedDirector);

    // updateCenterRecord(updatedDirector);
    await updateCenterRecord(updatedDirector);
    toast({
      message: "Profile updated successfully.",
      variant: "success",
    });
    window.location.reload();
  };

  const handleStoreAccount = async () => {
    // console.log("Save new account settings");
    // console.log("Save new account settings");

    // console.log("enteredPhone", enteredPhone);
    // console.log("enteredAddress", enteredAddress);

    if (!updateDirectorRecord) {
      console.error("updateDirectorRecord is not defined in DashboardContext");
      return;
    }

    const updatedDirector: AboutLikeProps = {
      ...caredata,
      // careProviderName: enteredFirstName,
      // telephone: enteredPhone,

      details: enteredAddress,

      // lastName: enteredBio,
      // email: enteredEmail,
      // Add other properties as needed
    };

    console.log(updatedDirector);

    // updateDirectorRecord(updatedDirector);
    await updateDirectorRecord(updatedDirector);
    toast({
      message: "Profile updated successfully.",
      variant: "success",
    });
    window.location.reload();
  };

  return (
    <React.Fragment>
      <Box className="bg-white w-full drop-shadow-lg py-6" id="accountsetting">
        <Box className="pt-3 pl-6 lg:pl-12">
          <Typography
            variant="body1"
            mb={1}
            color="#28404A"
            gutterBottom
            // display={"flex"}
            // alignSelf={"start"}
            fontSize={{ xs: "15px", sm: "15px", md: "15px" }}
            className=""
          >
            Business Owner
          </Typography>
          <Typography
            variant="body1"
            mb={2}
            color="#A9A9A9"
            gutterBottom
            // display={"flex"}
            // alignSelf={"start"}
            fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
            className=""
          >
            The information here can be edited
          </Typography>
        </Box>

        <Divider />

        <Box className=" lg:flex lg:flex-row lg:justify-center gap-6 pt-8 px-4 md:px-4 lg:px-0 space-y-3 md:space-y-0">
          <Box className="col-span-1 md:col-span-1 lg:basis-[28%]">
            <label
              className=""
              style={{
                color: "#21392E",
              }}
            >
              Director / Owner Name
            </label>
            <MHTextInput
              id="firstName"
              type="text"
              // label="First Name"
              placeholder=""
              value={
                enteredFirstName === ""
                  ? directorsBio?.directorsName
                  : enteredFirstName
              }
              onChange={firstNameInputChangeHandler}
              onBlur={firstNameInputBlurHandler}
              disabled={disabledState.fname}
              className={`${
                disabledState.fname === true ? "bg-[#D9D9D9]" : "bg-white"
              }`}
              endAdornment={
                <InputAdornment
                  className="bg-[#D9D9D9] md:h-[43px] text-navy-900 cursor-pointer"
                  onClick={() => {
                    setDisabledState({
                      ...disabledState,
                      fname: !disabledState.fname,
                    });
                  }}
                >
                  <EditIcon className=" text-navy-900 2-6 h-6" />
                </InputAdornment>
              }
              required
              autoFocus
            />
          </Box>

          <Box className=" col-span-1 md:col-span-1 lg:basis-[28%]">
            <label
              className=""
              style={{
                color: "#21392E",
              }}
            >
              Email
            </label>
            <MHTextInput
              id="email"
              type="email"
              // label="Email"
              placeholder=""
              // value={caredata?.email}
              value={enteredEmail === "" ? caredata?.email : enteredEmail}
              onChange={emailInputChangeHandler}
              onBlur={emailInputBlurHandler}
              disabled={disabledState.email}
              className={`${
                disabledState.email === true ? "bg-[#D9D9D9]" : "bg-white"
              }`}
              endAdornment={
                <InputAdornment
                  className="bg-[#D9D9D9] md:h-[43px] text-navy-900 cursor-pointer"
                  onClick={() => {
                    setDisabledState({
                      ...disabledState,
                      email: !disabledState.email,
                    });
                  }}
                >
                  <EditIcon className=" text-navy-900 2-6 h-6" />
                </InputAdornment>
              }
              required
              autoFocus
            />
          </Box>

          <Box className=" col-span-1 md:col-span-1 lg:basis-[28%] -mt-6 lg:mt-0">
            <label
              className=""
              style={{
                color: "#21392E",
              }}
            >
              Phone number
            </label>
            <MHTextInput
              id="text"
              type="text"
              // label="phone"
              placeholder=""
              value={enteredPhone === "" ? caredata?.telephone : enteredPhone}
              onChange={phoneInputChangeHandler}
              onBlur={phoneInputBlurHandler}
              disabled={disabledState.phone}
              className={`${
                disabledState.phone === true ? "bg-[#D9D9D9]" : "bg-white"
              }`}
              endAdornment={
                <InputAdornment
                  className="bg-[#D9D9D9] md:h-[43px] text-navy-900 cursor-pointer"
                  onClick={() => {
                    setDisabledState({
                      ...disabledState,
                      phone: !disabledState.phone,
                    });
                  }}
                >
                  <EditIcon className=" text-navy-900 2-6 h-6" />
                </InputAdornment>
              }
              required
              autoFocus
            />
          </Box>
        </Box>

        <Box
          display={{ xs: "flex", sm: "flex" }}
          justifyContent="end"
          width={{ xs: "full", sm: "full" }}
          className="gap-4 md:!pr-[50px] py-6 px-4 md:px-0 "
        >
          <MHButton
            sx={{ height: "38px", width: "158px", color: "white" }}
            onClick={handleCancel}
            className=" bg-white text-navy-900 ring-1 ring-navy-900 rounded-sm"
            fullWidth
          >
            Cancel
          </MHButton>

          <MHButton
            sx={{ height: "38px", width: { xs: "full", sm: "158px" } }}
            onClick={handleSaveAccount}
            fullWidth
            className="rounded-sm"
          >
            Save Changes
          </MHButton>
        </Box>

        <Box className=" lg:flex lg:flex-row lg:justify-center gap-6 py-8 px-4 md:px-4 lg:px-0 space-y-3 md:space-y-0">
          <Box className=" col-span-1 md:col-span-1 lg:basis-[90%]">
            <label
              className=""
              style={{
                color: "#21392E",
              }}
            >
              Biography
            </label>
            <MHTextInput
              id="text"
              type="text"
              // label="address"
              multiline
              rows={4}
              placeholder=""
              value={
                enteredAddress === "" ? directorsBio?.details : enteredAddress
              }
              onChange={addressInputChangeHandler}
              onBlur={addressInputBlurHandler}
              disabled={disabledState.bio}
              className={`${
                disabledState.bio === true ? "bg-[#D9D9D9]" : "bg-white"
              }`}
              endAdornment={
                <InputAdornment
                  className="bg-[#D9D9D9] md:h-[43px] text-navy-900 cursor-pointer"
                  onClick={() => {
                    setDisabledState({
                      ...disabledState,
                      bio: !disabledState.bio,
                    });
                  }}
                >
                  <EditIcon className=" text-navy-900 2-6 h-6" />
                </InputAdornment>
              }
              required
              autoFocus
            />
          </Box>
        </Box>

        <Divider />

        <Box
          display={{ xs: "flex", sm: "flex" }}
          justifyContent="end"
          width={{ xs: "full", sm: "full" }}
          className="gap-4 md:!pr-[50px] py-6 px-4 md:px-0 "
        >
          <MHButton
            sx={{ height: "38px", width: "158px", color: "white" }}
            onClick={handleCancel}
            className=" bg-white text-navy-900 ring-1 ring-navy-900 rounded-sm"
            fullWidth
          >
            Cancel
          </MHButton>

          <MHButton
            sx={{ height: "38px", width: { xs: "full", sm: "158px" } }}
            onClick={handleStoreAccount}
            fullWidth
            className="rounded-sm"
          >
            Save Changes
          </MHButton>
        </Box>
      </Box>
    </React.Fragment>
  );
};

const AvatarSettings = (props: Props) => {
  const authCtx = React.useContext(AuthContext);
  const { user } = authCtx;

  const snackbarCtx = React.useContext(SnackbarContext);
  const { toast } = snackbarCtx;

  const dashboardCtx = React.useContext(DashboardContext);
  const { carecenter, updateEmployerRecord } = dashboardCtx;

  const centerImages = carecenter?.careCenter?.[0].documentList;

  const firstMatchingDocument = centerImages?.find(
    (document: any) => document.documentType === "PROVIDER-IMAGE-ONBOARDING"
  );

  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();

  return (
    <React.Fragment>
      <Box className="bg-white w-full drop-shadow-lg py-6" id="accountsetting">
        <Box className="pt-3 pl-6 lg:pl-12">
          <Typography
            variant="body1"
            mb={1}
            color="#28404A"
            gutterBottom
            // display={"flex"}
            // alignSelf={"start"}
            fontSize={{ xs: "15px", sm: "15px", md: "15px" }}
            className=""
          >
            Avatar
          </Typography>
          <Typography
            variant="body1"
            mb={2}
            color="#A9A9A9"
            gutterBottom
            // display={"flex"}
            // alignSelf={"start"}
            fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
            className=""
          >
            Select owner's avatar
          </Typography>
        </Box>

        <Divider />

        <Box className=" md:flex md:flex-row md:justify-center gap-6 py-8 px-4 md:px-4 lg:px-0 space-y-3 md:space-y-0">
          <Box className=" col-span-1 md:col-span-1 md:basis-[60%]">
            <Box className=" relative w-full mx-auto md:ml-0 pt-4 md:pt-0 my-4 md:my-4 centerItems">
              <Avatar
                alt="Care Center"
                variant="square"
                // src="https://media.licdn.com/dms/image/D4E22AQHM9V2gh0gdQg/feedshare-shrink_800/0/1713882016047?e=1717632000&v=beta&t=qwUjlo4spoGPLcfGSMovJJVsuKqZ_SuwJFJxiev8w88"
                src={`${
                  firstMatchingDocument &&
                  `https://careafrica.motherhonestly.info/assets/${firstMatchingDocument.documentName}`
                } `}
                sx={{
                  width: { xs: "300px", sm: "258px", md: "358px" },
                  height: { xs: "281px", sm: "258px", md: "358px" },
                }}
                className=" rounded-md"
              />
              {/* <AvatarBadgeIcon
                className=" cursor-pointer absolute bottom-2 right-10"
                onClick={handleOpenDialog}
              /> */}
            </Box>
          </Box>
          <Box className=" col-span-1 md:col-span-1 md:basis-[20%] space-y-6 !mt-5 ">
            <MHButton
              sx={{ height: "38px", width: { xs: "full", sm: "158px" } }}
              onClick={handleOpenDialog}
              fullWidth
              className="rounded-sm"
            >
              Select Image
            </MHButton>

            <MHButton
              sx={{
                height: "38px",
                width: { xs: "full", sm: "158px" },
                color: "white",
              }}
              onClick={handleCloseDialog}
              className=" bg-white text-navy-900 ring-1 ring-navy-900 rounded-sm"
              fullWidth
            >
              Cancel
            </MHButton>
          </Box>
        </Box>

        <Divider />

        {/* <Box
          display={{ xs: "flex", sm: "flex" }}
          justifyContent="end"
          width={{ xs: "full", sm: "full" }}
          className="gap-4 md:!pr-[50px] py-6 px-4 md:px-0 "
        >
          <MHButton
            sx={{ height: "38px", width: "158px", color: "white" }}
            onClick={handleCloseDialog}
            className=" bg-white text-navy-900 ring-1 ring-navy-900 rounded-sm"
            fullWidth
          >
            Cancel
          </MHButton>

          <MHButton
            sx={{ height: "38px", width: { xs: "full", sm: "158px" } }}
            onClick={handleOpenDialog}
            fullWidth
            className="rounded-sm"
          >
            Select Image
          </MHButton>
        </Box> */}
      </Box>

      {openDialog && (
        <CenterAvatarDialog open={openDialog} onClose={handleCloseDialog} />
      )}
    </React.Fragment>
  );
};

const CenterDirectorSettings = (props: Props) => {
  return (
    <React.Fragment>
      <Box className="space-y-8">
        <AccountSettings />
        <AvatarSettings />
        {/* <PinSettings /> */}
      </Box>
    </React.Fragment>
  );
};

export default CenterDirectorSettings;
