export const TestCareWorkerData = [
    {
      customerId: 1,
      careProviderName: "Tope Odejayi",
      slug: "tope-odejayi",
      careProviderBio: "Dedicated and compassionate nanny with a nurturing spirit. Experienced in providing a safe and stimulating environment for children. Patient, reliable, and adept at fostering positive relationships.",
      imgSrc: "https://images.unsplash.com/photo-1606416132922-22ab37c1231e?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8YmxhY2slMjB3b21lbnxlbnwwfHwwfHx8MA%3D%3D",
      language: "English",
      busRating: 4.68,
      city: "Lekki I",
      State: "Lagos",
      careCategory: "childcare, postpartum-care",
      openingTime: "2023-12-12T08:00:00Z",
      closingTime: "2023-12-12T18:00:00Z",
      startDay: "Tue",
      endDay: "Fri",
      nin: true,
      report_file: true,
      charges: 3000,
      feat1: "House sitting",
      feat2: "Potty training",
      feat3: "",
      feat4: "",
      locations: {latitude: 6.4324863, longitude: 3.4761871 }  
    },
    {
      customerId: 2,
      careProviderName: "Uju Elizabeth",
      slug: "uju-elizabeth",
      careProviderBio: "Enthusiastic and caring nanny committed to creating a loving atmosphere for children.",
      imgSrc: "https://plus.unsplash.com/premium_photo-1683134038322-15e02b58ee0c?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8YmxhY2slMjB3b21lbnxlbnwwfHwwfHx8MA%3D%3D",
      language: "English, French",
      busRating: 4,
      city: "Ikeja",
      State: "Lagos",
      careCategory: "afterschool-care, household-care, postpartum-care",
      openingTime: "2023-12-12T08:00:00Z",
      closingTime: "2023-12-12T18:00:00Z",
      startDay: "Mon",
      endDay: "Fri",
      nin: true,
      report_file: true,
      charges: 2000,
      feat1: "House sitting",
      feat2: "Potty training",
      feat3: " Meals and meal prep",
      feat4: "Cleaning and household chores",
      locations: {latitude: 6.575661, 
      longitude: 3.271675 ,
    }  
    },
    {
      customerId: 3,
      careProviderName: "Bianca Onome",
      slug: "bianca-onome",
      careProviderBio: "Patient and reliable childcare provider with a passion for fostering creativity and learning.",
      imgSrc: "https://images.unsplash.com/photo-1606415918835-88d0614e75ad?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fGJsYWNrJTIwd29tZW58ZW58MHx8MHx8fDA%3D",
      language: "English",
      busRating: 3.5,
      city: "VI",
      State: "Lagos",
      careCategory: "childcare, aging-care, postpartum-care",
      openingTime: "2023-12-12T08:00:00Z",
      closingTime: "2023-12-12T18:00:00Z",
      startDay: "Mon",
      endDay: "Fri",
      nin: true,
      report_file: true,
      charges: 5000,
      feat1: "House sitting",
      feat2: "Potty training",
      feat3: " Meals and meal prep",
      feat4: "Cleaning and household chores",
      locations: {latitude: 6.4291199, 
      longitude: 3.4197377,
    }  
    },
    {
      customerId: 4,
      careProviderName: "Shalewa Adeyinka",
      slug: "supplus-care",
      careProviderBio: "Experienced nanny dedicated to promoting a safe and nurturing environment for kids.",
      imgSrc: "https://plus.unsplash.com/premium_photo-1681484355577-9b59d772d780?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTd8fGJsYWNrJTIwd29tZW58ZW58MHx8MHx8fDA%3D",
      language: "English, Yoruba",
      busRating: 4.68,
      city: "Ikeja",
      State: "Lagos",
      careCategory:  "afterschool-care, household-care, aging-care, postpartum-care",
      openingTime: "2023-12-12T08:00:00Z",
      closingTime: "2023-12-12T18:00:00Z",
      startDay: "Mon",
      endDay: "Sat",
      nin: true,
      report_file: true,
      charges: 3000,
      feat1: "House sitting",
      feat2: "Potty training",
      feat3: " Meals and meal prep",
      feat4: "Cleaning and household chores",
      locations: {latitude: 6.6007814, longitude: 3.3506078},
      
    },
    {
        customerId: 5,
        careProviderName: "Joy Obiang",
        slug: "joy-obiang",
        careProviderBio: "Compassionate and organized childcare professional adept at balancing fun and structure.",
        imgSrc: "https://images.unsplash.com/photo-1613876215075-276fd62c89a4?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTl8fGJsYWNrJTIwd29tZW58ZW58MHx8MHx8fDA%3D",
        language: "English, Ijaw",
        busRating: 4.68,
        city: "Ikeja",
        State: "Lagos",
        careCategory: "childcare, afterschool-care, household-care, aging-care",
        openingTime: "2023-12-12T08:00:00Z",
        closingTime: "2023-12-12T18:00:00Z",
        startDay: "Sun",
        endDay: "Fri",
        nin: true,
        report_file: true,
        charges: 3000,
        feat1: "House sitting",
        feat2: "Potty training",
        feat3: " Meals and meal prep",
        feat4: "Cleaning and household chores",
        locations: {latitude: 6.6033148, 
        longitude: 3.3511383,
      }  
      },

      {
        customerId: 6,
        careProviderName: "Chiamaka Festus",
        slug: "chiamaka-festus",
        careProviderBio: "Energetic and responsible nanny with a strong commitment to children's well-being and development.",
        imgSrc: "https://images.unsplash.com/photo-1563132337-f159f484226c?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjZ8fGJsYWNrJTIwd29tZW58ZW58MHx8MHx8fDA%3D",
        language: "English, Igbo",
        busRating: 3.5,
        city: "Ikeja",
        State: "Lagos",
        careCategory: "childcare, aging-care, postpartum-care",
        openingTime: "2023-12-12T08:00:00Z",
        closingTime: "2023-12-12T18:00:00Z",
        startDay: "Mon",
        endDay: "Sun",
        nin: true,
        report_file: true,
        charges: 5000,
        feat1: "House sitting",
        feat2: "Potty training",
        feat3: " Meals and meal prep",
        feat4: "",
        locations: {latitude: 6.611183, longitude: 3.3471546,
        
      }},
    
    {
      customerId: 7,
      careProviderName: "Anthonia Ikechukwu",
      slug: "anthonia-ikechukwu",
      careProviderBio: "Loving and adaptable caregiver skilled in creating engaging activities for children of all ages.",
      imgSrc: "https://plus.unsplash.com/premium_photo-1661389471684-eced3b7984aa?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjF8fGJsYWNrJTIwd29tZW58ZW58MHx8MHx8fDA%3D",
      language: "English",
      busRating: 4,
      city: "VI",
      State: " Lagos",
      careCategory: "afterschool-care, household-care, aging-care, postpartum-care",
      openingTime: "2023-12-12T08:00:00Z",
      closingTime: "2023-12-12T18:00:00Z",
      startDay: "Tue",
      endDay: "Sat",
      nin: true,
      report_file: true,
      charges: 2000,
      feat1: "House sitting",
      feat2: "Potty training",
      feat3: " Meals and meal prep",
      feat4: "Cleaning and household chores",
      locations: {latitude: 6.4313733, 
      longitude: 3.4138709,
    }  
    },
    
    
    {
        customerId: 8,
        careProviderName: "Janet Ajoke",
        slug: "janet-ajoke",
        careProviderBio: "Trustworthy and dependable nanny with a genuine love for helping children grow and thrive.",
        imgSrc: "https://images.unsplash.com/photo-1585890483046-9461ebc1dace?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NDZ8fGJsYWNrJTIwd29tZW58ZW58MHx8MHx8fDA%3D",
        language: "English, Hausa, Yoruba",
        busRating: 4.68,
        city: "Agege",
        State: "Lagos",
        careCategory: " aging-care, postpartum-care",
        openingTime: "2023-12-12T08:00:00Z",
        closingTime: "2023-12-12T18:00:00Z",
        startDay: "Wed",
        endDay: "Sat",
        nin: true,
        report_file: true,
        charges: 3000,
        feat1: "House sitting",
        feat2: "Potty training",
        feat3: " Meals and meal prep",
        feat4: "Cleaning and household chores",
        locations: {latitude: 6.6302803, 
        longitude: 3.322288,
      }},
   
  ];
  


  export const TestCareData = [
    {
      id: 1,
      legalBusinessName: "Mother's Choice Infant Care and preschool",
      slug: "mother-choice",
      busRating: 4.68,
      busLocation: "Lagos, Nigeria",
      ageRange: ["6mo-12mo", "1-5yrs", "6-9yrs"],
      openingTime: "8:00am",
      closingTime: "6:00pm",
      charges: 3000,
      feat1: "Free snacks and lunch",
      feat2: "Indoor and Outdoor games",
      feat3: "Center-based teaching",
      feat4: "Bouncing castles",
      position: { lat: 6.5791922, lng: 3.276604 },
      imageList: [
        {
            imgPath:
              "https://bumo-care.s3.us-west-1.amazonaws.com/Joyce's+Toluca+Lake+Pre-School2.webp",
          },
          
          {
            imgPath:
              "https://bumo-care.s3.us-west-1.amazonaws.com/Toluca+Lake+Preschool2.webp",
          },
          {
            imgPath:
              "https://bumo-care.s3.us-west-1.amazonaws.com/Toluca+Lake+Preschool4.webp",
          },
          {
            imgPath:
              "https://bumo-care.s3.us-west-1.amazonaws.com/Joyce's+Toluca+Lake+Pre-School1.webp",
          },
          {
            imgPath:
              "https://bumo-care.s3.us-west-1.amazonaws.com/Joyce's+Toluca+Lake+Pre-School3.webp",
          },
      ],
    },
    {
      id: 2,
      legalBusinessName: "Children Center and preschool",
      slug: "children-center",
      busRating: 4,
      busLocation: "Lagos, Nigeria",
      ageRange: ["1-5yrs", "6-9yrs"],
      openingTime: "8:00am",
      closingTime: "6:00pm",
      charges: 2000,
      feat1: "Free snacks and lunch",
      feat2: "Indoor and Outdoor games",
      feat3: "Center-based teaching",
      feat4: "Bouncing castles",
      position: { lat: 6.575661, lng: 3.271675 },
      imageList: [
        {
          imgPath:
            "https://bumo-care.s3.us-west-1.amazonaws.com/Toluca+Lake+Preschool2.webp",
        },
        {
            imgPath:
              "https://bumo-care.s3.us-west-1.amazonaws.com/Joyce's+Toluca+Lake+Pre-School2.webp",
          },
          
          
          {
            imgPath:
              "https://bumo-care.s3.us-west-1.amazonaws.com/Toluca+Lake+Preschool4.webp",
          },
          {
            imgPath:
              "https://bumo-care.s3.us-west-1.amazonaws.com/Joyce's+Toluca+Lake+Pre-School1.webp",
          },
          {
            imgPath:
              "https://bumo-care.s3.us-west-1.amazonaws.com/Joyce's+Toluca+Lake+Pre-School3.webp",
          },
      ],
    },
    {
      id: 3,
      legalBusinessName: "Father's Choice Infant Care and preschool",
      slug: "fathers-choice",
      busRating: 3.5,
      busLocation: "Lagos, Nigeria",
      ageRange: ["6mo-12mo", "6-9yrs"],
      openingTime: "8:00am",
      closingTime: "6:00pm",
      charges: 5000,
      feat1: "Free snacks and lunch",
      feat2: "Indoor and Outdoor games",
      feat3: "Center-based teaching",
      feat4: "Bouncing castles",
      position: {lat: 6.5757645, lng: 3.2663401},
      imageList: [
        {
          imgPath:
            "https://bumo-care.s3.us-west-1.amazonaws.com/Joyce's+Toluca+Lake+Pre-School1.webp",
        },
        {
            imgPath:
              "https://bumo-care.s3.us-west-1.amazonaws.com/Joyce's+Toluca+Lake+Pre-School2.webp",
          },
          
          {
            imgPath:
              "https://bumo-care.s3.us-west-1.amazonaws.com/Toluca+Lake+Preschool2.webp",
          },
          {
            imgPath:
              "https://bumo-care.s3.us-west-1.amazonaws.com/Toluca+Lake+Preschool4.webp",
          },
          
          {
            imgPath:
              "https://bumo-care.s3.us-west-1.amazonaws.com/Joyce's+Toluca+Lake+Pre-School3.webp",
          },
      ],
    },
    {
      id: 4,
      legalBusinessName: "Supplus Care and preschool",
      slug: "supplus-care",
      busRating: 4.68,
      busLocation: "Lagos, Nigeria",
      ageRange: ["6mo-12mo", "1-5yrs", "6-9yrs"],
      openingTime: "8:00am",
      closingTime: "6:00pm",
      charges: 3000,
      feat1: "Free snacks and lunch",
      feat2: "Indoor and Outdoor games",
      feat3: "Center-based teaching",
      feat4: "Bouncing castles",
      position: {lat: 6.6007814, lng: 3.3506078},
      imageList: [
        {
          imgPath:
            "https://bumo-care.s3.us-west-1.amazonaws.com/Toluca+Lake+Preschool4.webp",
        },
        {
          imgPath:
            "https://bumo-care.s3.us-west-1.amazonaws.com/Joyce's+Toluca+Lake+Pre-School1.webp",
        },
        {
            imgPath:
              "https://bumo-care.s3.us-west-1.amazonaws.com/Joyce's+Toluca+Lake+Pre-School2.webp",
          },
          
          {
            imgPath:
              "https://bumo-care.s3.us-west-1.amazonaws.com/Toluca+Lake+Preschool2.webp",
          },
          
          
          {
            imgPath:
              "https://bumo-care.s3.us-west-1.amazonaws.com/Joyce's+Toluca+Lake+Pre-School3.webp",
          },
      ],
    },
    {
        id: 5,
        legalBusinessName: "Joy Cometh Care and preschool",
        slug: "joy-cometh",
        busRating: 4.68,
        busLocation: "Lagos, Nigeria",
        ageRange: ["6mo-12mo", "1-5yrs", "6-9yrs"],
        openingTime: "8:00am",
        closingTime: "6:00pm",
        charges: 3000,
        feat1: "Free snacks and lunch",
        feat2: "Indoor and Outdoor games",
        feat3: "Center-based teaching",
        feat4: "Bouncing castles",
        position: {lat: 6.6033148, lng: 3.3511383},
        imageList: [
          {
            imgPath:
              "https://bumo-care.s3.us-west-1.amazonaws.com/Joyce's+Toluca+Lake+Pre-School3.webp",
          },
          {
              imgPath:
                "https://bumo-care.s3.us-west-1.amazonaws.com/Joyce's+Toluca+Lake+Pre-School2.webp",
            },
            
            {
              imgPath:
                "https://bumo-care.s3.us-west-1.amazonaws.com/Toluca+Lake+Preschool2.webp",
            },
            {
              imgPath:
                "https://bumo-care.s3.us-west-1.amazonaws.com/Toluca+Lake+Preschool4.webp",
            },
            {
              imgPath:
                "https://bumo-care.s3.us-west-1.amazonaws.com/Joyce's+Toluca+Lake+Pre-School1.webp",
            },
            
        ],
      },

      {
        id: 6,
        legalBusinessName: "People's Choice Infant Care and preschool",
        slug: "peoples-choice",
        busRating: 3.5,
        busLocation: "Lagos, Nigeria",
        ageRange: ["6mo-12mo", "6-9yrs"],
        openingTime: "8:00am",
        closingTime: "6:00pm",
        charges: 5000,
        feat1: "Free snacks and lunch",
        feat2: "Indoor and Outdoor games",
        feat3: "Center-based teaching",
        feat4: "Bouncing castles",
        position: {lat: 6.611183, lng: 3.3471546},
        imageList: [
          {
              imgPath:
                "https://bumo-care.s3.us-west-1.amazonaws.com/Joyce's+Toluca+Lake+Pre-School2.webp",
            },
            
            {
              imgPath:
                "https://bumo-care.s3.us-west-1.amazonaws.com/Toluca+Lake+Preschool2.webp",
            },
            {
              imgPath:
                "https://bumo-care.s3.us-west-1.amazonaws.com/Toluca+Lake+Preschool4.webp",
            },
            {
              imgPath:
                "https://bumo-care.s3.us-west-1.amazonaws.com/Joyce's+Toluca+Lake+Pre-School1.webp",
            },
            {
              imgPath:
                "https://bumo-care.s3.us-west-1.amazonaws.com/Joyce's+Toluca+Lake+Pre-School3.webp",
            },
        ],
      },
    
    {
      id: 7,
      legalBusinessName: "Blessing Care center and preschool",
      slug: "blessing-center",
      busRating: 4,
      busLocation: "Lagos, Nigeria",
      ageRange: ["1-5yrs", "6-9yrs"],
      openingTime: "8:00am",
      closingTime: "6:00pm",
      charges: 2000,
      feat1: "Free snacks and lunch",
      feat2: "Indoor and Outdoor games",
      feat3: "Center-based teaching",
      feat4: "Bouncing castles",
      position: {lat: 6.6415434, lng: 3.3217438},
      imageList: [
        {
          imgPath:
            "https://bumo-care.s3.us-west-1.amazonaws.com/Toluca+Lake+Preschool2.webp",
        },
        {
            imgPath:
              "https://bumo-care.s3.us-west-1.amazonaws.com/Joyce's+Toluca+Lake+Pre-School2.webp",
          },
          
          
          {
            imgPath:
              "https://bumo-care.s3.us-west-1.amazonaws.com/Toluca+Lake+Preschool4.webp",
          },
          {
            imgPath:
              "https://bumo-care.s3.us-west-1.amazonaws.com/Joyce's+Toluca+Lake+Pre-School1.webp",
          },
          {
            imgPath:
              "https://bumo-care.s3.us-west-1.amazonaws.com/Joyce's+Toluca+Lake+Pre-School3.webp",
          },
      ],
    },
    
    
    {
        id: 8,
        legalBusinessName: "Peter's Infant Care and preschool",
        slug: "peter-infant",
        busRating: 3.68,
        busLocation: "Lagos, Nigeria",
        ageRange: ["6mo-12mo", "1-5yrs", "6-9yrs"],
        openingTime: "8:00am",
        closingTime: "6:00pm",
        charges: 3000,
        feat1: "Free snacks and lunch",
        feat2: "Indoor and Outdoor games",
        feat3: "Center-based teaching",
        feat4: "Bouncing castles",
        position: {lat: 6.6302803, lng: 3.322288},
        imageList: [
          {
            imgPath:
              "https://bumo-care.s3.us-west-1.amazonaws.com/Joyce's+Toluca+Lake+Pre-School1.webp",
          },
          {
            imgPath:
              "https://bumo-care.s3.us-west-1.amazonaws.com/Toluca+Lake+Preschool4.webp",
          },
          {
              imgPath:
                "https://bumo-care.s3.us-west-1.amazonaws.com/Joyce's+Toluca+Lake+Pre-School2.webp",
            },
            
            {
              imgPath:
                "https://bumo-care.s3.us-west-1.amazonaws.com/Toluca+Lake+Preschool2.webp",
            },
            
            
            {
              imgPath:
                "https://bumo-care.s3.us-west-1.amazonaws.com/Joyce's+Toluca+Lake+Pre-School3.webp",
            },
        ],
      },
   
  ];
  
  

  export const singleTester = [
    {
      id: 1,
      legalBusinessName: "Mother's Choice Infant Care and preschool",
      slug: "mother-choice",
      busRating: 4.68,
      busLocation: "Lagos, Nigeria",
      ageRange: ["6mo-12mo", "1-5yrs", "6-9yrs"],
      openingTime: "8:00am",
      closingTime: "6:00pm",
      charges: 3000,
      feat1: "Free snacks and lunch",
      feat2: "Indoor and Outdoor games",
      feat3: "Center-based teaching",
      feat4: "Bouncing castles",
      position: { lat: 6.5791922, lng: 3.276604 },
      imageList: [
        {
            imgPath:
              "https://bumo-care.s3.us-west-1.amazonaws.com/Joyce's+Toluca+Lake+Pre-School2.webp",
          },
          
          {
            imgPath:
              "https://bumo-care.s3.us-west-1.amazonaws.com/Toluca+Lake+Preschool2.webp",
          },
          {
            imgPath:
              "https://bumo-care.s3.us-west-1.amazonaws.com/Toluca+Lake+Preschool4.webp",
          },
          {
            imgPath:
              "https://bumo-care.s3.us-west-1.amazonaws.com/Joyce's+Toluca+Lake+Pre-School1.webp",
          },
          {
            imgPath:
              "https://bumo-care.s3.us-west-1.amazonaws.com/Joyce's+Toluca+Lake+Pre-School3.webp",
          },
      ],
    },
  ]

  export const WEEKDAYS = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];


export const childcareAge = [
  "0 to 6 months",
  "6+ to 12 months",
  "1+ to 3 years",
  "3+ to 5 years",
  "5+ to 7 years",
  "7+ to 9 years",
  "9+ to 11 years",
];

  // Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
export const commonLocation = [
  { label: 'Lagos, Nigeria' },
  { label: 'Ikeja, Lagos' },
  { label: 'Alimosho, Lagos'},
  { label: 'Ikoyi, Lagos'},
  { label: 'Lekki I, Lagos'},
  { label: 'Lekki II, Lagos'},
  
];

export const NavDatas =  [
  {
    title: "Book",
    linkTo: "book",
  },
  {
    title: "About",
    linkTo: "about",
  },
  {
    title: "Location",
    linkTo: "location",
  },
  {
    title: "Director Bio",
    linkTo: "directorbio",
  },
  {
    title: "Review",
    linkTo: "review",
  },
  
  
];


