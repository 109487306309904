import { Box, Divider, Typography } from "@mui/material";
import React from "react";

import { ReactComponent as CafHouse } from "../../../static/svg/categories/household.svg";
import { ReactComponent as CafPotty } from "../../../static/svg/categories/child-care.svg";
import { ReactComponent as CafSchool } from "../../../static/svg/categories/self-care.svg";
import { ReactComponent as Check } from "../../../static/svg/check-filled.svg";

import { AboutLikeProps } from "../../../models/care.details";
import CareIconText from "../global/CareIconText";

const AboutCare: React.FC<AboutLikeProps> = ({
  aboutCareCenter,
  whyWeLikeIt,
  careProviderBio,
}) => {
  return (
    <React.Fragment>
      <Typography
        variant="h2"
        fontSize={{ xs: "20px", sm: "30px", md: "30px" }}
        lineHeight={"120.5%"}
        letterSpacing={"-0.04em"}
        className="pb-4 text-start capitalize mt-5"
      >
        About Care Provider
      </Typography>
      {aboutCareCenter.map((info: any, index: any) => (
        <Typography
          variant="body1"
          color="primary"
          fontSize={{ xs: "12px", sm: "14px", md: "14px" }}
          lineHeight={{ xs: "180%", sm: "195%" }}
          letterSpacing={{ xs: "0.05em", sm: "0.06em" }}
          className="pb-4 text-start"
        >
          {/* Joyce's Preschool is located in North Hollywood. As a licensed childcare
        center, Joyce's offers part time and full time enrollment from
        18months-5 years old. Our school is dedicated to extending and enriching
        the young child’s understanding of the world around him through
        "teaching in the key of life." Each student is given the opportunity to
        grow towards independence and self-discipline under the loving guidance
        of highly trained teachers. */}
          {info.details}
        </Typography>
      ))}

      <Typography
        variant="body1"
        color="primary"
        fontSize={{ xs: "12px", sm: "14px", md: "14px" }}
        lineHeight={{ xs: "180%", sm: "195%" }}
        letterSpacing={{ xs: "0.05em", sm: "0.06em" }}
        className="pb-4 text-start"
      >
        {careProviderBio}
      </Typography>
      <Box className=" space-y-1">
        <CareIconText
          iconClass={"items-center "}
          icon={
            <CafHouse
              className=" -mt-1 p-1 text-[#B4B2D7B2]"
              color="#B4B2D7B2"
              // sx={{ color: "#B4B2D7B2", fontSize: "40px" }}
            />
          }
          text="Provider Type: Licensed Child Care Center"
        />
        <CareIconText
          iconClass={"items-center "}
          icon={
            <CafHouse
              className=" -mt-1"
              // sx={{ color: "#B4B2D7B2", fontSize: "40px" }}
            />
          }
          text="Ages: 18 months to 5 years"
        />
        <CareIconText
          iconClass={"items-center "}
          icon={
            <CafPotty
              className=" -mt-1"
              // sx={{ color: "#B4B2D7B2", fontSize: "40px" }}
            />
          }
          text="Potty Training for Toddlers: Included          "
        />
        <CareIconText
          iconClass={"items-center "}
          icon={
            <CafSchool
              className=" -mt-1"
              // sx={{ color: "#B4B2D7B2", fontSize: "40px" }}
            />
          }
          text="Education: Play-Based          "
        />
        <CareIconText
          iconClass={"items-center "}
          icon={
            <CafHouse
              className=" -mt-1"
              // sx={{ color: "#B4B2D7B2", fontSize: "40px" }}
            />
          }
          text="Food Provided: Meals and Snacks          "
        />
        <CareIconText
          iconClass={"items-center "}
          icon={
            <CafHouse
              className=" -mt-1"
              // sx={{ color: "#B4B2D7B2", fontSize: "40px" }}
            />
          }
          text="Outdoor Space: Yes"
        />
        <CareIconText
          iconClass={"items-center "}
          icon={
            <CafHouse
              className=" -mt-1"
              // sx={{ color: "#B4B2D7B2", fontSize: "40px" }}
            />
          }
          text="Background Check: Yes"
        />
      </Box>
      <Divider className="my-6" />

      {/* Why we like it */}
      <Box className=" space-y-2">
        <Typography
          variant="h2"
          fontSize={{ xs: "20px", sm: "30px", md: "30px" }}
          lineHeight={"120.5%"}
          letterSpacing={"-0.04em"}
          className="pb-4 text-start capitalize mt-5"
        >
          Why we like it
        </Typography>
        {whyWeLikeIt.map((info: any, index: any) => (
          <CareIconText
            iconClass={"items-center "}
            icon={
              <Check
                className=" -mt-1"
                // sx={{ color: "#B4B2D7B2", fontSize: "40px" }}
              />
            }
            text={info.details}
            key={index}
          />
        ))}
        {/* <CareIconText
          iconClass={"items-center "}
          icon={
            <Check
              className=" -mt-1"
              sx={{ color: "#B4B2D7B2", fontSize: "40px" }}
            />
          }
          text="Bilingual Childcare          "
        />
        <CareIconText
          iconClass={"items-center "}
          icon={
            <Check
              className=" -mt-1"
              sx={{ color: "#B4B2D7B2", fontSize: "40px" }}
            />
          }
          text="Arts & Crafts          "
        />
        <CareIconText
          iconClass={"items-center "}
          icon={
            <Check
              className=" -mt-1"
              sx={{ color: "#B4B2D7B2", fontSize: "40px" }}
            />
          }
          text="Indoor & Outdoor Play Area          "
        /> */}
      </Box>
    </React.Fragment>
  );
};

export default AboutCare;
