import { Box, Divider, Typography } from "@mui/material";
import React from "react";

type Props = {
  title: string;
  text: string;
};

const TextContext = ({ title, text }: Props) => {
  return (
    <Box className="w-[100%] py-4">
      <Typography
        variant="h3"
        fontSize={{ xs: "20px", sm: "30px", md: "30px" }}
        lineHeight={"120.5%"}
        letterSpacing={"-0.04em"}
        className="pb-4 text-start capitalize w-[80%]"
      >
        {title}
      </Typography>

      <Typography
        variant="body1"
        color="primary"
        fontSize={{ xs: "12px", sm: "14px", md: "14px" }}
        lineHeight={{ xs: "180%", sm: "180%" }}
        letterSpacing={{ xs: "0.05em", sm: "0.06em" }}
        className="pb-4 text-start w-[80%]"
      >
        {text}
      </Typography>
      <Divider className="my-2" />
    </Box>
  );
};

// TextContext.propTypes = {
//   title: PropTypes.string.isRequired,
//   text: PropTypes.string.isRequired,
// };

const MorePolicy = () => {
  return (
    <React.Fragment>
      <Divider className="my-2" />
      <TextContext
        title="Health and Safety"
        text="Caring Block's providers must abide by specific Health and Safety
          guidelines, which include COVID-19 policies."
      />
      <TextContext
        title="Caring Block Trust Verification"
        text="At Caring Block, your child’s safety is our greatest priority. Through our Caring BlockTrust 50-point review process, we carefully vet each provider before they are allowed to join our network. Caring Block is a curated marketplace of safe, reliable providers."
      />
      <TextContext
        title="Cancellation Policy"
        text="Families must cancel at least 72 hours before the start of their session in order to receive a refund. Rescheduling a session within 72 hours is treated as a cancellation of the original session and will not be eligible for a refund."
      />
    </React.Fragment>
  );
};

export default MorePolicy;
