import { Box, Typography } from "@mui/material";
import { GoogleMap, MarkerF, useLoadScript } from "@react-google-maps/api";
import React, { useMemo } from "react";
import { LocationProps } from "../../../models/care.details";

type Props = {};

const Location = (props: LocationProps) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY || "",
  });
  let latitude = Number(props.latitude);
  let longitude = Number(props.longitude);
  const defaultCenter = {
    lat: latitude,
    lng: longitude,
  };
  // const defaultCenter = {
  //   lat: 6.5791922,
  //   lng: 3.276604,
  // };
  const center = useMemo(() => defaultCenter, []);

  // console.log("Our Location", defaultCenter);

  return (
    <React.Fragment>
      <Box className="h-64 ">
        {!isLoaded ? (
          <h1>Loading...</h1>
        ) : (
          <GoogleMap
            mapContainerClassName="map-container"
            center={center}
            zoom={12} // You might need to adjust the zoom level
          >
            <MarkerF
              position={defaultCenter}
              icon={{
                url: "https://mh-resources-production.s3.us-west-1.amazonaws.com/caf-home-icon.png",
                scaledSize: {
                  width: 50,
                  height: 50,
                  equals: (otherSize: any | null) => {
                    if (!otherSize) return false; // handle null case
                    return otherSize.width === 50 && otherSize.height === 50;
                  },
                },
              }}
            />
          </GoogleMap>
        )}
      </Box>
      <Typography
        variant="body2"
        fontSize={{ xs: "13px", sm: "15px", md: "15px" }}
        lineHeight={"120.5%"}
        letterSpacing={"-0.04em"}
        className="pb-4 text-start capitalize mt-5"
      >
        {/* Lagos, Nigeria */}
        {props.address}
      </Typography>
    </React.Fragment>
  );
};

export default Location;
