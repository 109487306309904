import { Box, Typography } from "@mui/material";
import React from "react";
import MuiLink from "@mui/material/Link";
import { Link } from "react-router-dom";

type Props = {
  imgSrc?: string;
  label?: string;
  caption?: string;
  path?: string;
};

export const HeroCard = (props: Props) => {
  //   let history = useHistory();

  //   const handleLink = (url: string) => {
  //     history.push(url);
  //   };

  return (
    <React.Fragment>
      <Box
        sx={{
          height: { xs: "500px", sm: "500px" },
          width: { xs: "300px", sm: "300px" },
          backgroundColor: "#fff",
          display: "inline-block",
          borderRadius: "16px",
          padding: "12px 12px 46px",
          minHeight: "300px",
          boxShadow: "0 0 14px 0 rgb(0 0 0 / 8%)",
          placeContent: "center",
        }}
      >
        <img
          src={props.imgSrc}
          alt={props.label}
          className=" block w-[276px] h-[252px] object-cover rounded-t-2xl"
        />

        <Typography
          variant="h1"
          color="primary"
          fontSize={{ xs: "22px", sm: "22px", md: "25px" }}
          lineHeight={{ xs: "130%", sm: "160.5%" }}
          letterSpacing={"-4%"}
          className="pt-4 text-left uppercase text-navy-900"
          width={{ xs: "80%", sm: "100%" }}
        >
          {props.label}
        </Typography>
        <Box
          sx={{
            width: "20%",
            borderTop: "2px solid #2e2e2e",
            marginTop: "10px",
            paddingBottom: "16px",
          }}
        />
        <Typography
          variant="subtitle2"
          color="primary"
          fontSize={{ xs: "18px", sm: "18px", md: "20px" }}
          lineHeight={{ xs: "130%", sm: "160.5%" }}
          letterSpacing={"-4%"}
          className="pt-4 text-left text-navy-900 line-clamp-2"
          width={{ xs: "80%", sm: "100%" }}
        >
          {props.caption}
        </Typography>

        <MuiLink
          component={Link}
          underline="always"
          to={props.path || "/"}
          // onClick={handleLink}
          // display="block"
          // align="left"
          className="text-blue-100 underline"
        >
          <Typography
            variant="subtitle2"
            color="primary"
            fontSize={{ xs: "15px", sm: "15px", md: "16px" }}
            lineHeight={{ xs: "130%", sm: "160.5%" }}
            letterSpacing={"-4%"}
            className="pt-6 text-left text-blue-100 underline"
            width={{ xs: "80%", sm: "100%" }}
          >
            Learn more
          </Typography>
        </MuiLink>
      </Box>
    </React.Fragment>
  );
};
