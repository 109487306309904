import { Box, Typography } from "@mui/material";
import React from "react";
import { ReactComponent as Child } from "../../../static/svg/child.svg";
import { ReactComponent as Pin } from "../../../static/svg/pinlocation.svg";
import { ReactComponent as StarRating } from "../../../static/svg/star.svg";
import { ReactComponent as VerifiedIcon } from "../../../static/svg/check-mark-rounded-lg.svg";

import AboutCareTab from "./AboutCareTab";
import dayjs from "dayjs";
import { convertUTCToDateTime } from "../../../utils/utils";
import { CareProviderProps } from "../../../models/care.details";
import CareIconText from "../global/CareIconText";

type Props = {};

const AboutCareProvider = (props: CareProviderProps) => {
  const ageRanges = Array.isArray(props.ageRange)
    ? props.ageRange
    : [props.ageRange];
  const ageRangeString = ageRanges.join(", ");

  return (
    <React.Fragment>
      <Box
        display="flex"
        gap={2}
        alignContent="center"
        alignItems="center"
        pb={4}
      >
        <Typography
          variant="h2"
          fontSize={{ xs: "30px", sm: "35px", md: "35px" }}
          lineHeight={"120.5%"}
          letterSpacing={"-0.04em"}
          className=" text-start capitalize"
        >
          {props.legalBusinessName}
        </Typography>

        {props.verified === true && <VerifiedIcon width={20} height={20} />}
      </Box>

      <Box className=" space-y-1 relative">
        <Box className=" gap-2 items-center">
          <CareIconText
            iconClass={"items-bottom"}
            icon={<Pin />}
            text={props.busLocation}
          />
        </Box>
        <CareIconText
          iconClass={"items-bottom"}
          icon={<Child width={20} height={20} />}
          // text={props.ageRange && props.ageRange!.join(", ")}
          text={ageRangeString}
        />

        <Box className="grid md:flex gap-2">
          <Typography
            variant="subtitle2"
            color="primary.main"
            align="left"
            fontSize=".75rem"
            lineHeight="180%"
            letterSpacing="0.04em"
          >
            Opening Hours{" "}
            {dayjs(convertUTCToDateTime(props.openingTime)).format("h:mm A") +
              " - " +
              dayjs(convertUTCToDateTime(props.closingTime)).format("h:mm A")}
            {/* , &#8358; {props.charges}/hr */}
          </Typography>

          <Typography
            variant="subtitle2"
            color="primary.main"
            align="left"
            fontSize=".75rem"
            lineHeight="180%"
            letterSpacing="0.04em"
          >
            Availability:{" "}
            <b className=" ">
              {props.availability === " - " ? "Mon - Sat" : props.availability}
            </b>
          </Typography>

          <CareIconText
            iconClass={"items-bottom"}
            icon={<StarRating />}
            text={props.busRating ? props.busRating : "3"}
          />
        </Box>

        <Box className=" ">
          <AboutCareTab
            aboutCareCenter={props.aboutCareCenter}
            whyWeLikeIt={props.whyWeLikeIt}
            fullCareData={props.fullCareData}
            directorsBio={props.directorsBio}
            careProviderBio={props.careProviderBio}
            documentList={props.documentList}
            latitude={props.latitude}
            longitude={props.longitude}
            address={props.address}
          />
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default AboutCareProvider;
