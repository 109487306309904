import React from "react";
import { Redirect } from "react-router-dom";

import AuthContext from "../../../store/context/auth-context";
import useHttp from "../../../hooks/use-http";
import { getURLWithQueryParams } from "../../../utils/utils";
import { Employee } from "../../../models/user.model";
import { HttpResponse } from "../../../models/api.interface";
import BackdropLoader from "../../Common/Loading/BackdropLoader";
import DashboardContext from "../../../store/context/dashboard.context";
import { Organization } from "../../../models/employer.model";

const Startup = () => {
  const authCtx = React.useContext(AuthContext);
  const dashboardCtx = React.useContext(DashboardContext);
  const { error, sendHttpRequest: getUser } = useHttp();

  const { userId, token, role, synchronizeUser } = authCtx;
  const {
    fetchOrganizationData,
    fetchOrganizationEmployee,
    fetchCareCenterData,
  } = dashboardCtx;

  // console.log(dashboardCtx);

  const fetchUser = React.useCallback(async () => {
    await getUser(
      getURLWithQueryParams(
        process.env.REACT_APP_API_BASE_URL + "/employer/employer_refId",
        {
          employerRefId: String(userId),
        }
      ),
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token?.accessToken}`,
        },
      },
      (response: HttpResponse<Employee>) => {
        fetchOrganizationData(response.data.employerRefId);
        synchronizeUser(response.data);
        fetchCareCenterData(response.data.businessId);
      }
    );
  }, [
    getUser,
    userId,
    token?.accessToken,
    fetchOrganizationData,
    synchronizeUser,
    fetchCareCenterData,
  ]);

  const fetchHousehold = React.useCallback(async () => {
    await getUser(
      getURLWithQueryParams(
        process.env.REACT_APP_API_BASE_URL + "/employee/getByUuid",
        {
          uuid: String(userId),
        }
      ),
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token?.accessToken}`,
        },
      },
      (response: HttpResponse<Employee>) => {
        fetchOrganizationEmployee(response.data.uuid);
        synchronizeUser(response.data);
      }
    );
  }, [userId, token, getUser, synchronizeUser, fetchOrganizationEmployee]);

  React.useEffect(() => {
    if (role === "Employer") {
      fetchUser();
    } else if (role === "Employee") {
      fetchHousehold();
    }
  }, []);

  if (error) {
    return <Redirect to="/auth/sign-in" />;
  }

  return <BackdropLoader open />;
};

export default Startup;
