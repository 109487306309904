import React from "react";
import { useHistory } from "react-router-dom";

import useTitle from "../../hooks/use-title";

import { FnComponent } from "../../models/component.model";

import Verification from "../../components/Common/CenterOnboarding/Verification";
import Information from "../../components/Common/CenterOnboarding/Information";
import { useOnboardForm } from "../../store/context/onboard-context";
import StepperBar from "../../components/Layout/StepperBar";
import OtherInformation from "../../components/Common/CenterOnboarding/OtherInformation";
import ClaimBusiness from "../../components/Common/CenterOnboarding/ClaimBusiness";

const ClaimCenter: FnComponent<{
  // onRouteChange: (image: BGImage) => void;
  title: string;
}> = (props) => {
  // const [showPassword, setShowPassword] = React.useState<boolean>(false);
  // const { onRouteChange } = props;
  const history = useHistory();
  useTitle(props.title);

  const [activeButton, setActiveButton] = React.useState("");

  React.useEffect(() => {
    console.log("activeButton", activeButton);
  }, [activeButton]);

  // const handleMouseDownPassword = (
  //   event: React.MouseEvent<HTMLButtonElement>
  // ) => {
  //   // preventDefault(event);
  // };

  const { activeStep, form } = useOnboardForm();

  // console.log("typer1", form.type);

  const handleOnboarding = () => {
    if (activeStep === 0) {
      return <Information />;
    } else if (activeStep === 1) {
      return <Verification />;
    } else if (activeStep === 2) {
      return <ClaimBusiness />;
    } else {
      return <OtherInformation />;
    }
  };

  const steps = [
    "Your information",
    "Verification",
    "Claim Business",
    "Business info",
  ];

  return (
    <React.Fragment>
      <StepperBar data={steps} />
      {/* <RoleSelection />
      <Verification />
      <Information /> */}
      {handleOnboarding()}
    </React.Fragment>
  );
};

export default ClaimCenter;
