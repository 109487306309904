import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useHistory, useRouteMatch } from "react-router-dom";
import useDialog from "../../hooks/use-dialog";

// type Props = {}

const GovtBenefitsSection = () => {
  let history = useHistory();

  const handleClickOpen = () => {
    history.push(`/employers`);
  };

  const {
    openDialog: open,
    handleOpenDialog: handleOpen,
    handleCloseDialog,
  } = useDialog();

  const handleClose = () => {
    handleCloseDialog();
  };

  return (
    <React.Fragment>
      <Box className="pt-0">
        <Box
          className="items-center place-content-center "
          sx={{ backgroundColor: "#fff" }}
        >
          {/* <Typography className="uppercase font-areaNorm text-[12px] text-center md:text-[11px]  lg:text-[11px] leading-[102%] tracking-[0.1em] text-navy-900 ">
            THE ENTERPRISE PLAN
          </Typography> */}

          {/* <Typography className="py-8 font-columbia text-[30px] text-center md:text-[40px]  lg:text-[50px] leading-[120.5%] tracking-[-0.04em] text-navy-900 ">
            Join the many forward-
            <br className="md:hidden block" />
            thinking companies <br /> supporting their employee caregivers
          </Typography> */}
          <Typography
            variant="subtitle1"
            width={{ xs: "90%", md: "65%" }}
            fontSize={{ xs: "20px", sm: "20px", md: "20px" }}
            lineHeight={"120.5%"}
            letterSpacing={"-0.04em"}
            mx="auto"
            className="pt-8 pb-6  text-center"
          >
            Partner with Caring Blocks to Build a Better Future
          </Typography>

          <Typography
            variant="subtitle1"
            width={{ xs: "90%", md: "65%" }}
            fontSize={{ xs: "20px", sm: "20px", md: "20px" }}
            lineHeight={"120.5%"}
            letterSpacing={"-0.04em"}
            mx="auto"
            className="pt-4 pb-2  text-center"
          >
            Why Childcare is Key:
          </Typography>

          <Typography
            variant="body1"
            color="primary.main"
            width={{ xs: "93%", md: "60%" }}
            fontSize="13px"
            lineHeight="185%"
            letterSpacing="0.02em"
            align="center"
            mx="auto"
            mb={2}
            paragraph
          >
            Access to affordable, high-quality childcare isn't just a family
            issue—it's an economic one. Families need childcare to work, thrive,
            and contribute to society. Yet, across many regions, limited
            infrastructure, affordability challenges, and lack of regulation
            prevent families from accessing the care they need. Governments have
            the power to bridge this gap by creating sustainable childcare
            ecosystems that unlock economic potential for entire communities. At
            Caring Blocks, we partner with governments to design, implement, and
            scale innovative childcare solutions tailored to the unique needs of
            their citizens.
          </Typography>

          {/* Web */}

          {/* <Box
            className="hidden md:grid grid-cols-3 items-center mx-auto text-center"
            justifyContent="center"
          >
           
            <Box className="ml-8 mr-8 py-8">
              <Typography
                variant="subtitle1"
                fontSize={{ xs: "12px", sm: "14px" }}
                lineHeight={"102%"}
                letterSpacing={"0.1em"}
                className="uppercase text-center"
              >
                Tailored Care Packages
              </Typography>
              <Typography
                variant="body1"
                fontSize={{ xs: "12px", sm: "14px", md: "15px" }}
                lineHeight={"150%"}
                letterSpacing={"0.02em"}
                className="py-4  text-center  "
              >
                Customized childcare solutions designed to meet the <br />{" "}
                unique needs of your diverse workforce, allowing employees to
                choose care that aligns with their family values and schedules.
              </Typography>
            </Box>

            <Box className="ml-8 mr-8 py-8">
              <Typography
                variant="subtitle1"
                fontSize={{ xs: "12px", sm: "14px" }}
                lineHeight={"102%"}
                letterSpacing={"0.1em"}
                className="uppercase text-center"
              >
                Employee Well-being
              </Typography>
              <Typography
                variant="body1"
                fontSize={{ xs: "12px", sm: "14px", md: "15px" }}
                lineHeight={"150%"}
                letterSpacing={"0.02em"}
                className="py-4  text-center  "
              >
                By providing access to trusted caregiver services and <br />{" "}
                household support, you not only help reduce stress and improve
                mental health but also foster a more engaged and productive
                workforce.
              </Typography>
            </Box>
            <Box className="ml-8 mr-8 py-8">
              <Typography
                variant="subtitle1"
                fontSize={{ xs: "12px", sm: "14px" }}
                lineHeight={"102%"}
                letterSpacing={"0.1em"}
                className="uppercase text-center"
              >
                Comprehensive Management
              </Typography>
              <Typography
                variant="body1"
                fontSize={{ xs: "12px", sm: "14px", md: "15px" }}
                lineHeight={"150%"}
                letterSpacing={"0.02em"}
                className="py-4  text-center  "
              >
                We handle every aspect of care—from vetting and <br />{" "}
                onboarding to training and payroll—ensuring that your <br />{" "}
                employees receive the highest quality support for their
                families, with minimal administrative burden on your HR team.
              </Typography>
            </Box>

            &bull;
          </Box> */}

          {/* Mobile */}

          {/* <Box className="md:hidden block items-center place-content-center mx-auto text-center">
            <Box className="ml-8 mr-8 py-2">
              <Typography
                variant="subtitle1"
                fontSize={{ xs: "12px", sm: "14px" }}
                lineHeight={"102%"}
                letterSpacing={"0.1em"}
                className="uppercase text-center"
              >
                Tailored Care Packages
              </Typography>
              <Typography
                variant="body1"
                fontSize={{ xs: "12px", sm: "14px", md: "15px" }}
                lineHeight={"150%"}
                letterSpacing={"0.02em"}
                className="py-4 text-center"
              >
                Start by filling out our online application. Provide your
                details, experience, and any relevant certifications. Once
                submitted, our team will <br /> review your application.
              </Typography>
            </Box>

            <Box className="ml-8 mr-8 py-2">
              <Typography
                variant="subtitle1"
                fontSize={{ xs: "12px", sm: "14px" }}
                lineHeight={"102%"}
                letterSpacing={"0.1em"}
                className="uppercase text-center"
              >
                Employee Well-being
              </Typography>
              <Typography
                variant="body1"
                fontSize={{ xs: "12px", sm: "14px", md: "15px" }}
                lineHeight={"150%"}
                letterSpacing={"0.02em"}
                className="py-4  text-center  "
              >
                After your application is approved, you'll go through
                <br /> our vetting process. This includes a background
                <br />
                check, health assessment, and reference verification to ensure
                you're ready for your new role.
              </Typography>
            </Box>
            <Box className="ml-8 mr-8 py-2">
              <Typography
                variant="subtitle1"
                fontSize={{ xs: "12px", sm: "14px" }}
                lineHeight={"102%"}
                letterSpacing={"0.1em"}
                className="uppercase text-center"
              >
                Comprehensive Management
              </Typography>
              <Typography
                variant="body1"
                fontSize={{ xs: "12px", sm: "14px", md: "15px" }}
                lineHeight={"150%"}
                letterSpacing={"0.02em"}
                className="py-4  text-center  "
              >
                We handle every aspect of care—from vetting and <br />{" "}
                onboarding to training and payroll—ensuring that <br /> your
                employees receive the highest quality support for their
                families, with minimal administrative burden on your HR team.
              </Typography>
            </Box>

          

          <Box className="mx-auto text-center py-10 md:py-0">
            <MHButton
              sx={{
                width: 'fit-content'
              }}
              onClick={handleOpen}>
              Request a Demo
            </MHButton>
          </Box>*/}
        </Box>

        <hr className="hidden md:block w-[90%] mx-auto h-0 border-t-lilac-300 mt-10" />
      </Box>
    </React.Fragment>
  );
};

export default GovtBenefitsSection;
