// BookingDrawer.jsx
import React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import MHButton from "../Common/Button/MHButton";
import { useHistory } from "react-router-dom";

import { ReactComponent as SearchIcon } from "../../static/svg/search.svg";
import { ReactComponent as PlusIcon } from "../../static/svg/plus.svg";
import { ReactComponent as MinusIcon } from "../../static/svg/minus.svg";
import { ReactComponent as Child } from "../../static/svg/child.svg";

import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { childcareAge } from "../../utils/careprovider";
import useDialog from "../../hooks/use-dialog";
import MHFormControl from "../Common/Form/MHFormControl";
import ReusedModal from "../Common/Dialog/ReusedModal";
import { DataContext } from "../../store/context/findcare.context";
import { Option } from "../Common/Form/MHAutocomplete";
import { CenterCategories } from "../../utils/constants";
import MHMultiAutocomplete from "../Common/Form/MHMultiAutocomplete";
import { MHMultiSelect } from "../Common/Form/MHSelect";
import MHSelectMultiple from "../Common/Form/MHSelectMultiple";
import GoogleAutocompleteInput from "../Common/Form/GoogleAutocompleteInput";
import { CareProviderProps } from "../../models/care.details";
import MHGoogleAutocomplete from "../Common/Form/MHGoogleAutocomplete";

interface BookingDrawerProps {
  open: boolean;
  onClose: () => void; // Assuming onClose is a function with no arguments and returns void
}

const BookingDrawer: React.FC<BookingDrawerProps> = ({ open, onClose }) => {
  let history = useHistory();

  const { formData, setFormData } = React.useContext(DataContext);

  const {
    openDialog: childDialog,
    handleOpenDialog: handleChildOpenDialog,
    handleCloseDialog: handleChildCloseDialog,
  } = useDialog();

  const [children, setChildren] = React.useState([
    { id: 1, name: "Child 1", age: "" },
  ]);

  const [careData, setCareData] = React.useState<CareProviderProps[]>([]);

  var careUrl = `${process.env.REACT_APP_CARE_CENTER_API}/search?status=ACTIVE`;

  const getCare = async () => {
    try {
      const response = await fetch(careUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      let jsonData = await response.json();

      setCareData(jsonData.data);
      // console.log("jsonData", jsonData);
    } catch (err) {
      console.error(err);
    }
  };

  React.useEffect(() => {
    getCare();
  }, []);

  const handleCareInputChange = (values: string[] | null) => {
    setFormData({
      ...formData,
      careCategory: values ? values.join(", ") : "", // Convert array back to string
    });
  };

  const handleChildrenInput = (selected: string[]) => {
    setFormData({
      ...formData,
      ageRange: selected ? selected.join(", ") : "", // Convert array back to string
    });
  };

  // console.log("formData", formData);

  const handleAgeChange = (event: any, childId: number) => {
    const updatedChildren = children.map((child) =>
      child.id === childId ? { ...child, age: event.target.value } : child
    );

    // Extract individual ages from updatedChildren
    const ages = updatedChildren.map((child) => child.age);

    // Convert individual ages to a formatted age range string
    const ageRangeString = ages.join(", ");

    // Update state
    setChildren(updatedChildren);
    setFormData({ ...formData, ageRange: ageRangeString });
  };

  const handleAgeSlector = () => {
    // console.log(children);
    handleChildCloseDialog();
  };

  const addMoreChildren = () => {
    if (children.length < 5) {
      const newChildId = children.length + 1;
      setChildren([
        ...children,
        { id: newChildId, name: `Child ${newChildId}`, age: "" },
      ]);
    }
  };

  const removeChild = (childId: number) => {
    const updatedChildren = children.filter((child) => child.id !== childId);
    setChildren(updatedChildren);
  };

  const handleLocationSelected = (location: string) => {
    // console.log("Selected Location:", location);
    setFormData({ ...formData, where: location });
  };

  const handleClickOpen = () => {
    onClose(); // Call the onClose function to close the drawer
    history.push(`/care-center-locations/search`);
  };

  return (
    <Drawer anchor="top" open={open} onClose={onClose} sx={{ zIndex: 200 }}>
      <Box
        sx={{ height: { xs: 450, md: 350 }, padding: 2 }}
        className=" !mx-auto text-center place-content-center"
      >
        <Box
          sx={{
            height: { xs: 10, md: 50 },
            padding: 2,
            mt: 10,
            display: "flex",
            flexDirection: "column",
            alignItems: "center", // Align items in the center horizontally
            justifyContent: "center", // Align items in the center vertically
          }}
          className="!mx-auto text-center"
        >
          <Typography
            variant="h2"
            fontSize={{ xs: "18px", sm: "20px", md: "25px" }}
            textTransform="capitalize"
            className="text-center !mx-auto"
          >
            Find Child Care
          </Typography>
        </Box>
        {/* input here */}
        <Box
          sx={{
            height: { xs: 270, md: 80 },
            display: { xs: "grid", md: "flex" },
            flexDirection: "column",
            alignItems: "center", // Align items in the center horizontally
            justifyContent: "center", // Align items in the center vertically
          }}
          className="!mx-auto text-center place-content-center"
        >
          <Box
            className=""
            display={{ xs: "grid", md: "flex" }}
            alignContent="center"
            sx={{
              background: "secondary.main",
              justifyContent: "center", // Align items in the center vertically
              gap: 2,
              mt: { xs: 1, md: 8 },
            }}
          >
            <Box sx={{ minWidth: "185px", height: "45px" }}>
              <MHSelectMultiple
                label="What"
                options={CenterCategories.map((category) => category.value)}
                selectedOptions={
                  formData.careCategory ? formData.careCategory.split(", ") : []
                }
                onChange={handleCareInputChange}
              />
            </Box>
            {/* <Box sx={{ height: "45px" }}>
              <MHFormControl
                id="text"
                type="text"
                label=""
                placeholder="Where"
                value={formData.where}
                endAdornment={
                  <Box pr={1} className=" opacity-50">
                    <SearchIcon />
                  </Box>
                }
                // autocomplete="on"
                onChange={(e) => {
                  setFormData({ ...formData, where: e.target.value });
                }}
                required
                // autoFocus
              />
            </Box> */}
            <Box sx={{ minWidth: "185px", height: "45px" }}>
              <GoogleAutocompleteInput
                placeholder="Where"
                onLocationSelected={handleLocationSelected}
              />

              {/* <MHGoogleAutocomplete
                placeholder="Where"
                careData={careData}
                onLocationSelected={handleLocationSelected}
              /> */}
            </Box>

            <Box sx={{ minWidth: "185px", height: "45px" }}>
              <MHSelectMultiple
                label="Who"
                options={childcareAge}
                selectedOptions={
                  formData.ageRange ? formData.ageRange.split(", ") : []
                }
                onChange={handleChildrenInput}
              />
            </Box>
            {/* <MHButton
              sx={{ minWidth: "30%", height: "45px" }}
              className=" "
              color="inherit"
              variant="outlined"
              onClick={() => handleChildOpenDialog()}
            >
              {children.length > 0 ? `${children.length} Kid(s)` : "Who"}
            </MHButton> */}

            {/* <FormControl variant="standard" sx={{ minWidth: "35%" }}> */}
            {childDialog && (
              <ReusedModal
                open={childDialog}
                onClose={handleChildCloseDialog}
                maxWidth="xs"
                marginTop={0}
              >
                <Box
                  sx={{
                    marginX: "auto",
                  }}
                >
                  {children.map((child) => (
                    <Box
                      key={child.id}
                      sx={{
                        marginTop: 1,
                        display: "flex",
                        gap: 2,
                        flexDirection: "row",
                        alignItems: "end",
                        justifyContent: "center",
                      }}
                    >
                      <Child width={20} height={20} />

                      <Typography
                        variant="body1"
                        color="primary.main"
                        fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
                        className="text-start"
                      >
                        {child.name}
                      </Typography>

                      <FormControl variant="standard" sx={{ minWidth: 150 }}>
                        <InputLabel id={`select-age-label-${child.id}`}>
                          Age
                        </InputLabel>
                        <Select
                          labelId={`select-age-label-${child.id}`}
                          id={`select-age-${child.id}`}
                          value={child.age}
                          onChange={(event) => handleAgeChange(event, child.id)}
                          MenuProps={{
                            PaperProps: {
                              sx: {
                                zIndex: 1301, // Ensure dropdown is above the modal
                              },
                            },
                          }}
                        >
                          {childcareAge.map((item) => (
                            <MenuItem key={item} value={item}>
                              <Typography
                                variant="body1"
                                color="primary.main"
                                fontSize={{
                                  xs: "12px",
                                  sm: "12px",
                                  md: "12px",
                                }}
                                className="text-start"
                              >
                                {item}
                              </Typography>
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      <Box
                        sx={{
                          backgroundColor: "whitesmoke",
                          paddingBottom: 0.5,
                          paddingX: 1,
                          borderRadius: "50%",
                        }}
                      >
                        {children.length > 1 && (
                          <button onClick={() => removeChild(child.id)}>
                            <MinusIcon />
                          </button>
                        )}
                      </Box>
                    </Box>
                  ))}

                  {children.length < 5 && (
                    <Button
                      onClick={addMoreChildren}
                      sx={{ backgroundColor: "#fff", marginTop: 2, gap: 1 }}
                    >
                      <PlusIcon /> Add More Children
                    </Button>
                  )}

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: 2,
                      marginX: "auto",
                      marginTop: 2,
                    }}
                  >
                    <MHButton
                      onClick={handleAgeSlector}
                      sx={{
                        minWidth: "35%",
                        gap: 2,
                      }}
                      color="primary"
                      className="text-xs"
                    >
                      Confirm
                    </MHButton>
                  </Box>
                </Box>
              </ReusedModal>
            )}

            {/* </FormControl> */}

            <MHButton
              onClick={() => handleClickOpen()}
              sx={{ minWidth: "30%", height: "43px", gap: 1 }}
              className=" "
            >
              {/* <SearchIcon /> */}
              Search
            </MHButton>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
};

export default BookingDrawer;
