import {
  Box,
  CardMedia,
  Chip,
  Divider,
  Grid,
  Typography,
  Zoom,
} from "@mui/material";
import React from "react";

import { ReactComponent as LeftBtn } from "../../../static/svg/mini-left-btn.svg";
import { ReactComponent as RightBtn } from "../../../static/svg/mini-right-btn.svg";
import { ReactComponent as Person } from "../../../static/svg/person.svg";
import { ReactComponent as Clock } from "../../../static/svg/clock.svg";
import { ReactComponent as Calender } from "../../../static/svg/calendar.svg";
import { ReactComponent as Pin } from "../../../static/svg/pinlocation.svg";
import { ReactComponent as VerifiedIcon } from "../../../static/svg/check-mark-rounded-lg.svg";
import { ReactComponent as Check } from "../../../static/svg/check-circle-fill.svg";
import Slider from "react-slick";
import MuiLink from "@mui/material/Link";

import { Link, useHistory } from "react-router-dom";
import { CareProviderProps } from "../../../models/care.details";
import { convertUTCToDateTime, limitString } from "../../../utils/utils";
import dayjs from "dayjs";

type ArrowProps = {
  onClick?: (e: React.MouseEvent) => void;
};

function SampleNextArrow(props: ArrowProps) {
  return (
    <Box className="absolute top-[50%] right-0 md:top-[45%] z-10 md:right-1 lg:right-0 ">
      <RightBtn className="cursor-pointer" onClick={props.onClick} />
    </Box>
  );
}

function SamplePrevArrow(props: ArrowProps) {
  return (
    <Box className="absolute top-[50%] left-0 md:top-[45%] z-10 md:left-1 lg:left-0">
      <LeftBtn className="cursor-pointer" onClick={props.onClick} />
    </Box>
  );
}

const CareMapCard = (props: CareProviderProps) => {
  const [slideIndex, setSlideIndex] = React.useState(0);
  const history = useHistory();

  const [careFeatures, setCareFeatures] = React.useState<CareProviderProps[]>(
    []
  );

  var careUrl = `${process.env.REACT_APP_CARE_CENTER_API}/dashboard?customerId=${props.customerId}`;

  const getFeatures = async () => {
    try {
      const response = await fetch(careUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      let jsonData = await response.json();

      setCareFeatures(jsonData.data.whyWeLikeIt);
      // console.log("jsonData", jsonData);
    } catch (err) {
      console.error(err);
    }
  };
  // console.log("MapFeatures", careFeatures);

  React.useEffect(() => {
    getFeatures();
  }, [props.customerId]);

  const settings = {
    centerMode: true,
    centerPadding: "0px",
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 800,
    autoplay: false,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    // autoplaySpeed: 1500,
    // beforeChange: (current, next) => setSlideIndex(next),
  };

  const ageRanges = Array.isArray(props.ageRange)
    ? props.ageRange
    : [props.ageRange];
  const ageRangeString = ageRanges.join(", ");

  const filteredDocumentList = props.documentList?.filter(
    (document: any) => document.documentType === "PROVIDER-HEADER-IMAGE"
  );

  // console.log("Filtered Document List", filteredDocumentList);

  const limitedMatchingDocuments = filteredDocumentList?.slice(0, 5);

  // console.log("Limited Matching Documents", limitedMatchingDocuments);
  // console.log("Limited Matching Length", limitedMatchingDocuments?.length);

  // console.log("availability", props.availability);
  // console.log("close", props.closingTime);
  return (
    <React.Fragment>
      <Zoom in style={{ transitionDelay: "200ms" }}>
        <Box
          className="w-full md:w-[95%] h-[290px] mx-auto rounded-b-xl md:my-4 my-4"
          id={`careCard_${props.customerId}`}
        >
          <Grid container spacing={2} className="h-[280px]">
            <Grid item xs={6} sm={6} md={6} lg={6} alignSelf="center">
              <Slider
                {...settings}
                beforeChange={(current, next) => setSlideIndex(next)}
              >
                {filteredDocumentList?.length === 0 ? (
                  <>
                    {props.verified === true && (
                      <Chip
                        icon={<VerifiedIcon width={15} height={15} />}
                        className="absolute top-1 left-1 z-10 h-fit py-[2px] bg-green-100"
                        label={
                          <Typography
                            variant="caption"
                            // fontFamily="Area-Normal-Black"
                            color="primary"
                            align="left"
                            fontSize="10px"
                            lineHeight="120%"
                            mb={2}
                            gutterBottom
                            className="uppercase"
                          >
                            CB Verified
                          </Typography>
                        }
                      />
                    )}

                    <CardMedia
                      component="img"
                      image={`https://mh-resources-production.s3.us-west-1.amazonaws.com/CareProviderMobile1.png`}
                      alt="care-providers"
                      className=" h-52 object-cover rounded-md"
                    />
                  </>
                ) : (
                  limitedMatchingDocuments?.map((item: any) => (
                    <React.Fragment key={item.documentName}>
                      <Box className="relative">
                        {props.verified === true && (
                          <Chip
                            icon={<VerifiedIcon width={15} height={15} />}
                            className="absolute top-1 left-1 z-10 h-fit py-[2px] bg-green-100"
                            label={
                              <Typography
                                variant="caption"
                                // fontFamily="Area-Normal-Black"
                                color="primary"
                                align="left"
                                fontSize="10px"
                                lineHeight="120%"
                                mb={2}
                                gutterBottom
                                className="uppercase"
                              >
                                CB Verified
                              </Typography>
                            }
                          />
                        )}
                        <CardMedia
                          component="img"
                          image={`https://careafrica.motherhonestly.info/assets/${item.documentName}`}
                          alt="care-providers"
                          className=" h-52 object-cover rounded-md z-3"
                        />
                      </Box>
                    </React.Fragment>
                  ))
                )}
              </Slider>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} alignSelf="center">
              <MuiLink
                component={Link}
                to="#"
                underline="none"
                className=" no-underline"
                onClick={() => {
                  history.push(`/view-care/${props.slug}/${props.customerId}`);
                }}
              >
                <Box className=" p-4">
                  <Typography
                    variant="caption"
                    fontFamily="Area-Normal-Black"
                    color="primary.main"
                    align="left"
                    fontSize="1.1rem"
                    lineHeight="120%"
                    mb={2}
                    gutterBottom
                    className="-mt-2 line-clamp-2 capitalize"
                  >
                    {props.legalBusinessName}
                  </Typography>

                  <Box className=" space-y-2">
                    <Box className="flex gap-4">
                      {/* <Box
                        className=" gap-1"
                        display={"flex"}
                                         alignContent={"center"}

                      >
                        <StarRating /> {props.busRating ? props.busRating : "3"}
                      </Box> */}
                      <Box
                        className="gap-1 text-start"
                        display={"flex"}
                        alignContent={"left"}
                      >
                        <Box>
                          <Pin />
                        </Box>
                        <Typography
                          variant="body1"
                          color="primary.main"
                          fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
                          // marginTop={0}
                          // textTransform="capitalize"
                          className="text-start line-clamp-2"
                        >
                          {props.address + ", " + props.State}
                        </Typography>
                      </Box>
                    </Box>

                    <Box
                      className=" gap-2"
                      display={"flex"}
                      alignContent={"center"}
                    >
                      {/* <Person /> {props.ageRange && props.ageRange!.join(", ")} */}
                      {/* <Person /> {ageRangeString} */}
                      <Calender className="w-4 h-4" />{" "}
                      {props.availability === " - "
                        ? "Mon - Sat"
                        : props.availability}
                    </Box>

                    <Box
                      className=" gap-2"
                      display={"flex"}
                      alignContent={"center"}
                    >
                      <Clock />

                      {dayjs(convertUTCToDateTime(props.openingTime)).format(
                        "h:mm A"
                      ) +
                        " - " +
                        dayjs(convertUTCToDateTime(props.closingTime)).format(
                          "h:mm A"
                        )}
                    </Box>
                    <Box className=" space-y-2 pt-2">
                      {careFeatures &&
                        careFeatures.map((data, index) => (
                          <Box
                            className=" gap-1 text-start"
                            display={"flex"}
                            alignContent={"center"}
                            key={index}
                          >
                            <Check className="mr-1" />
                            {data?.details && limitString(data?.details, 28)}
                          </Box>
                        ))}
                      {/* <Box
                        className=" gap-1"
                        display={"flex"}
                        alignContent={"center"}
                        alignSelf={"center"}
                        alignItems={"center"}
                      >
                        <Check /> {"Good Environment"}
                      </Box>
                      <Box
                        className=" gap-1"
                        display={"flex"}
                        alignContent={"center"}
                        alignSelf={"center"}
                        alignItems={"center"}
                      >
                        <Check /> {"Good Environment"}
                      </Box>
                      <Box
                        className=" gap-1"
                        display={"flex"}
                        alignContent={"center"}
                        alignSelf={"center"}
                        alignItems={"center"}
                      >
                        <Check /> {"Good Environment"}
                      </Box> */}
                    </Box>
                  </Box>
                </Box>
              </MuiLink>
            </Grid>
          </Grid>
          <Divider />
        </Box>
      </Zoom>
    </React.Fragment>
  );
};

export default CareMapCard;
