/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useState } from "react";
import Grid from "@mui/material/Grid";
import { Box, Typography } from "@mui/material";
import { Link } from "react-scroll"; // Import Link from react-scroll

import { Link as RouterLink } from "react-router-dom";
import MuiLink from "@mui/material/Link";

import {
  GoogleMap,
  InfoWindowF,
  MarkerF,
  useLoadScript,
} from "@react-google-maps/api";
import { CareProviderProps } from "../../models/care.details";
import { compareStartTimeDesc, limitString } from "../../utils/utils";
import { DataContext } from "../../store/context/findcare.context";
import BackdropLoader from "../../components/Common/Loading/BackdropLoader";
import { useHistory } from "react-router-dom";
import CareMapCard from "../../components/CareProvider/global/CareMapCard";
import CareCard from "../../components/CareProvider/global/CareCard";
import MiniFooter from "../Landing/MiniFooter";
import { MHPagination } from "../../components/Common/UI/MHPagination";
import MHButton from "../../components/Common/Button/MHButton";
import { checkSimilarity } from "../../hooks/check-similarity";

type Props = {};

interface YourDataItem {
  State: string;
  city: string;
  age_range: { id: number; name: string; age: string }[];
  ageRange: string;
  // Add other properties as needed
}

const SearchCareProviderMap = (props: Props) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: (process.env.REACT_APP_GOOGLE_API_KEY as string) || "",
  });
  const [isLoading, setIsLoading] = React.useState<boolean>(true); // Track loading state

  // Ikeja Lat & Long
  // const defaultCenter = {
  //   lat: 6.605874,
  //   lng: 3.349149,
  // };

  const [isInfoWindowHovered, setIsInfoWindowHovered] = React.useState(false);

  const [selectedMarkerId, setSelectedMarkerId] = React.useState<number | null>(
    null
  );

  const handleMarkerClick = (id: number) => {
    setSelectedMarkerId(id);

    // Scroll to the corresponding CareMapCard
    document.getElementById(`careCard_${id}`)?.scrollIntoView({
      behavior: "smooth",
    });
  };

  let history = useHistory();

  const { formData } = React.useContext(DataContext);

  const [careData, setCareData] = useState<CareProviderProps[]>([]);

  var careUrl = `${process.env.REACT_APP_CARE_CENTER_API}/search?status=ACTIVE`;

  // const getCare = async () => {
  //   setIsLoading(true); // Show loader when fetching begins
  //   try {
  //     const response = await fetch(careUrl, {
  //       method: "GET",
  //       headers: { "Content-Type": "application/json" },
  //     });

  //     if (!response.ok) {
  //       throw new Error(`HTTP error! Status: ${response.status}`);
  //     }

  //     const jsonData = await response.json();

  //     if (!jsonData.data || !Array.isArray(jsonData.data)) {
  //       throw new Error(
  //         "Invalid API response: 'data' field missing or not an array"
  //       );
  //     }

  //     const { ageRange, careCategory, where } = formData;
  //     let city: string | undefined, state: string | undefined;

  //     if (where.includes(",")) {
  //       [city, state] = where
  //         .split(",")
  //         .map((item: string) => item.trim().toLowerCase());
  //     } else {
  //       city = where.trim().toLowerCase();
  //     }

  //     const agesArray = ageRange
  //       ? ageRange.split(",").map((range: string) => range.trim().toLowerCase())
  //       : [];
  //     const categoriesArray = careCategory
  //       ? careCategory.split(",").map((cat: string) => cat.trim().toLowerCase())
  //       : [];

  //     const filteredData = jsonData.data.filter((item: any) => {
  //       const itemCity = item.address?.toLowerCase() || "";
  //       const itemState = item.State?.toLowerCase() || "";
  //       const itemAges =
  //         item.ageRange
  //           ?.split(",")
  //           .map((range: string) => range.trim().toLowerCase()) || [];
  //       const itemCategories =
  //         item.careCategory
  //           ?.split(",")
  //           .map((cat: string) => cat.trim().toLowerCase()) || [];

  //       const isCityMatch = city ? itemCity.includes(city) : true;
  //       const isStateMatch = state ? itemState.includes(state) : true;
  //       const isAgeRangeMatch = agesArray.length
  //         ? agesArray.some((age: string) => itemAges.includes(age))
  //         : true;
  //       const isCategoryMatch = categoriesArray.length
  //         ? categoriesArray.some((cat: string) => itemCategories.includes(cat))
  //         : true;

  //       return city && state
  //         ? isCityMatch && isStateMatch
  //         : city
  //         ? isCityMatch
  //         : isAgeRangeMatch && isCategoryMatch;
  //     });

  //     setCareData(filteredData);
  //   } catch (err) {
  //     console.error("Error in getCare function:", err);
  //   } finally {
  //     setIsLoading(false); // Hide loader when fetching completes
  //   }
  // };

  const getCare = async () => {
    setIsLoading(true); // Show loader when fetching begins
    const normalize = (str: string) =>
      str?.toLowerCase().replace(/\s+/g, " ").trim() || "";

    try {
      const response = await fetch(careUrl, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const jsonData = await response.json();
      console.log("jsonData", jsonData);

      if (!jsonData.data || !Array.isArray(jsonData.data)) {
        throw new Error(
          "Invalid API response: 'data' field missing or not an array"
        );
      }

      const { where } = formData;
      const normalizedInput = normalize(where);

      const filteredData = jsonData.data
        .map((item: any) => {
          const normalizedDbAddress = normalize(
            (item.address || "") +
              " " +
              (item.city || "") +
              " " +
              (item.State || "")
          );

          // console.log("Normalized DB Address:", normalizedDbAddress);

          const similarityScore = checkSimilarity(
            normalizedInput, // Ensure normalizedInput is correctly normalized
            normalizedDbAddress
          );

          // console.log(
          //   "Similarity Score for",
          //   normalizedInput,
          //   "vs",
          //   normalizedDbAddress,
          //   ":",
          //   similarityScore
          // );

          return {
            ...item,
            similarityScore,
          };
        })
        .filter((item: any) => {
          console.log(
            "Filtering Item:",
            item.similarityScore > 0.2 ? "Included" : "Excluded",
            item
          );
          return item.similarityScore > 0.2; // Adjust threshold if needed
        });

      filteredData.sort(
        (a: any, b: any) => b.similarityScore - a.similarityScore
      );

      console.log("Filtered and Sorted Data:", filteredData);

      setCareData(filteredData);
      setIsLoading(false);
    } catch (err) {
      console.error("Error in getCare function:", err);
    } finally {
      setIsLoading(false); // Hide loader when fetching completes
    }
  };

  // Filtered resources using startTime
  const dateFilteredData = careData.sort(compareStartTimeDesc).sort((a, b) => {
    // Check if both careProviders are verified or not
    if (a.verified === b.verified) return 0; // If both are same, maintain their order
    return a.verified ? -1 : 1; // Verified first, then unverified
  });

  const [currentPage, setCurrentPage] = React.useState(1);
  const [postsPerPage, setPostsPerPage] = React.useState(30);
  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const currentPosts = dateFilteredData.slice(firstPostIndex, lastPostIndex);

  let itemsPerPage = 30;
  let noOfPage = Math.ceil((dateFilteredData?.length || 0) / itemsPerPage);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
  };

  // console.log("careData", careData);

  // const { lat, lng } = useGeolocation();
  // const center = useMemo(() => ({ lat: lat, lng: lng }), [lat, lng]);

  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [zoomLevel, setZoomLevel] = useState(12); // Initial zoom level, can be any value
  // const [locationAvailable, setLocationAvailable] = useState(false);
  // const [center, setCenter] = useState({ lat: 6.605874, lng: 3.349149 }); // Default center (e.g., Ikeja)

  const [center, setCenter] = useState<{ lat: number; lng: number }>({
    lat: 0,
    lng: 0,
  });

  // const existingLat = Number(getCookie("_mhLatCookie"));
  // const existingLng = Number(getCookie("_mhLngCookie"));

  // const center = useMemo(
  //   () => ({ lat: existingLat, lng: existingLng }),
  //   [existingLat, existingLng]
  // );

  const resolveCoordinates = async (address: string) => {
    try {
      const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
        address
      )}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`;
      const response = await fetch(geocodeUrl);

      if (!response.ok) {
        throw new Error(`Geocode error: ${response.status}`);
      }

      const data = await response.json();
      if (data.results && data.results.length > 0) {
        const location = data.results[0].geometry.location;
        setCenter(location);
      } else {
        console.error("No results found for the given address.");
      }
    } catch (error) {
      console.error("Error in resolveCoordinates function:", error);
    }
  };

  useEffect(() => {
    if (formData.where) {
      resolveCoordinates(formData.where);
    }
    getCare();
  }, [formData.where]);

  // console.log("center", center);

  // const onLoad = (map: any) => {
  //   const bounds = new google.maps.LatLngBounds();
  //   careData.forEach(({ locations }) => {
  //     if (locations) {
  //       // Assuming locations is an array of LocationProps
  //       locations.forEach((location: LocationProps) => {
  //         if (location.latitude && location.longitude) {
  //           const latLng = new google.maps.LatLng(
  //             Number(center.lat),
  //             Number(center.lng)
  //           );
  //           bounds.extend(latLng);
  //         }
  //       });
  //     }
  //   });
  //   map.fitBounds(bounds);
  // };

  // const onLoad = (mapInstance: google.maps.Map) => {
  //   setMap(mapInstance);

  //   if (formData.where) {
  //     const bounds = new google.maps.LatLngBounds();
  //     careData.forEach(({ locations }) => {
  //       if (locations) {
  //         locations.forEach((location: LocationProps) => {
  //           if (location.latitude && location.longitude) {
  //             const latLng = new google.maps.LatLng(
  //               Number(center.lat),
  //               Number(center.lng)
  //             );
  //             bounds.extend(latLng);
  //           }
  //         });
  //       }
  //     });

  //     mapInstance.fitBounds(bounds);

  //     // Delayed center setting after the map has fully loaded and the bounds are set
  //     setTimeout(() => {
  //       mapInstance.setCenter(center);
  //     }, 500); // Adjust the delay as needed
  //   }
  // };

  // const onMapIdle = () => {
  //   // Set the zoom level after the map has fully loaded and the bounds are set
  //   if (formData.where !== "" && map) {
  //     map.setZoom(zoomLevel);
  //     // setZoomLevel(12)
  //   }
  // };

  const onLoad = (mapInstance: google.maps.Map) => {
    setMap(mapInstance);
    setZoomLevel(12); // Explicitly set zoom level to 12 on load

    // Set the mapId after loading the map instance
    mapInstance.setOptions({
      mapId: "9979eef602f240c8", // Your custom Map ID
    });
  };

  const onZoomChanged = () => {
    if (map) {
      const newZoomLevel = map.getZoom() || 12; // Get the current zoom level
      setZoomLevel(newZoomLevel); // Update the zoomLevel state dynamically
    }
  };

  // console.log("Care Data Length", careData.length);

  // console.log("Load Data", existingLat, existingLng, center.lat, center.lng);

  if (formData.where === "") {
    history.push("/");
  }
  // if (careData.length === 0 || !isLoaded) {
  //   // console.log("Data", careData.length, existingLat, existingLng, !isLoaded);
  //   return <BackdropLoader open={true} />;
  // }

  if (isLoading) {
    return <BackdropLoader open={true} />;
  }

  if (careData.length === 0) {
    return (
      <Box textAlign="center" mt={4}>
        <Typography variant="h2" color="primary" className=" py-4">
          Invalid Location
        </Typography>
        <Typography
          variant="body2"
          fontSize={{ xs: "15px", sm: "15px", md: "15px" }}
          lineHeight={"130%"}
          letterSpacing={"-0.04em"}
          color="error"
          className=" my-4"
        >
          We couldn't find a location that matches{" "}
          <i className=" text-blue-100 font-areaBold">{formData.where}</i>.
          <br />
          Please verify your spelling or try a different location.
        </Typography>

        <Box display="flex" justifyContent="center" gap={2}>
          <MHButton
            color="primary"
            sx={{}}
            type="submit"
            className="mb-12  lg:mb-24 uppercase w-full md:w-[220px] h-[54px]"
            onClick={() => history.push("/")}
          >
            <Typography
              variant="caption"
              // color="#fff"
              fontSize={{ xs: "14px", sm: "14px", md: "14px" }}
              lineHeight={{ xs: "200%", sm: "120%" }}
              className=" capitalize text-white hover:text-navy-900"
            >
              Go to homepage
            </Typography>
          </MHButton>
        </Box>
      </Box>
    );
  }

  return (
    <React.Fragment>
      {/* Web */}

      <Grid container spacing={0} className=" hidden md:flex">
        <Grid
          item
          xs={8}
          sm={8}
          md={6}
          lg={6}
          style={{ height: "100vh", overflowY: "auto" }}
          className=" border-r-2 border-blue-300"
        >
          <Box mt={2} className=" md:ml-4 lg:ml-[75px]">
            <Typography
              variant="h1"
              fontSize={{ xs: "20px", sm: "30px", md: "30px" }}
              textTransform="capitalize"
              sx={{
                mb: 1,
              }}
            >
              {careData && careData.length} Results
            </Typography>
            <Typography
              variant="body1"
              color="primary.main"
              fontSize={{ sm: "14px", md: "16px" }}
              lineHeight={"150%"}
              letterSpacing={"0.02em"}
              mx="auto"
            >
              Find and book the best child care near you
            </Typography>
          </Box>
          <Box className="h-fit">
            <Grid
              container
              //   spacing={{ xs: 0, sm: 0, md: 0 }}
              className=" text-center py-4 mx-auto place-content-center items-center"
              alignContent={"center"}
              alignItems={"center"}
              alignSelf={"center"}
            >
              {currentPosts &&
                currentPosts.map((data: any, index: any) => (
                  <Grid item xs={12} sm={12} md={10} lg={10} key={index}>
                    <Box
                      key={data.customerId}
                      id={`careCard_${data.customerId}`}
                    >
                      {/* Add a unique id for each CareMapCard */}
                      <Link
                        to={`careCard_${data.customerId}`}
                        smooth
                        className="no-underline"
                      >
                        {/* Create a Link to scroll to the CareMapCard */}
                        <CareMapCard
                          id={data.id}
                          legalBusinessName={data.legalBusinessName}
                          busRating={data.busRating}
                          city={data.city}
                          address={data.address}
                          State={data.State}
                          availability={data.startDay + " - " + data.endDay}
                          ageRange={limitString(data.ageRange, 35)}
                          openingTime={data.openingTime}
                          closingTime={data.closingTime}
                          charges={data.charges}
                          slug={data.slug}
                          customerId={data.customerId}
                          documentList={data.documentList}
                          verified={data.verified}
                        />
                      </Link>
                    </Box>
                  </Grid>
                ))}
            </Grid>
          </Box>

          <Box display={"flex"} justifyContent={"center"} my={2}>
            <MHPagination
              count={noOfPage}
              variant="outlined"
              shape="rounded"
              page={currentPage}
              onChange={handlePageChange}
              color={"primary"}
            />
          </Box>
        </Grid>
        <Grid item xs={4} sm={4} md={6} lg={6}>
          {/* <Box className="h-full bg-green-200"></Box> */}
          {!isLoaded ? (
            <h1>Loading...</h1>
          ) : (
            <GoogleMap
              mapContainerClassName="map-container"
              onLoad={onLoad}
              onIdle={onZoomChanged} // Call onZoomChanged whenever the user zooms
              center={center}
              zoom={zoomLevel}
              mapTypeId="terrain" // Set the map type to terrain
            >
              {/* Add a pin at the center location */}
              <MarkerF
                position={center}
                icon={{
                  url: "https://maps.google.com/mapfiles/ms/icons/red-dot.png", // Default Google Maps red pin
                  scaledSize: {
                    width: 40,
                    height: 40,
                    equals: (otherSize: any | null) => {
                      if (!otherSize) return false; // handle null case
                      return otherSize.width === 40 && otherSize.height === 40;
                    },
                  },
                }}
              />

              {currentPosts.map(
                ({
                  locations,
                  customerId,
                  legalBusinessName,
                  verified,
                  State,
                  slug,
                }) => {
                  const markers = locations?.map((location, index) => {
                    const position = {
                      lat: Number(location.latitude),
                      lng: Number(location.longitude),
                    };

                    return (
                      <MarkerF
                        key={`${customerId}_${index}`}
                        position={position}
                        onClick={() => handleMarkerClick(customerId)}
                        onMouseOver={() => setSelectedMarkerId(customerId)}
                        onMouseOut={() => {
                          if (!isInfoWindowHovered) setSelectedMarkerId(null);
                        }}
                        icon={{
                          url:
                            verified === true
                              ? "https://mh-resources-production.s3.us-west-1.amazonaws.com/caf-home-icon.png"
                              : "https://mh-resources-production.s3.us-west-1.amazonaws.com/cb-home-icon2.png",
                          scaledSize: {
                            width: 40,
                            height: 40,
                            equals: (otherSize: any | null) => {
                              if (!otherSize) return false; // handle null case
                              return (
                                otherSize.width === 40 &&
                                otherSize.height === 40
                              );
                            },
                          },
                        }}
                      >
                        {selectedMarkerId === customerId && (
                          <div
                            onMouseEnter={() => setSelectedMarkerId(customerId)}
                            onMouseLeave={() => setSelectedMarkerId(null)}
                          >
                            <InfoWindowF
                              position={position}
                              options={{
                                disableAutoPan: true, // Prevent auto-panning
                                pixelOffset: new google.maps.Size(0, -40), // Adjust the offset to move the InfoWindow above the marker
                              }}
                            >
                              <Box width="200px" height="fit">
                                <MuiLink
                                  component={RouterLink}
                                  to="#"
                                  underline="none"
                                  onClick={() => {
                                    history.push(
                                      `/view-care/${slug}/${customerId}`
                                    );
                                  }}
                                  className="removeUnderline"
                                >
                                  <Box
                                    sx={{
                                      backgroundColor: "white",
                                      padding: "8px",
                                      borderRadius: "8px",
                                      textDecoration: "none",
                                    }}
                                  >
                                    <Typography
                                      variant="caption"
                                      fontSize={{
                                        xs: "13px",
                                        sm: "13px",
                                        md: "13px",
                                      }}
                                      lineHeight={"130%"}
                                      letterSpacing={"-0.04em"}
                                      sx={{
                                        fontWeight: "bold",
                                        marginBottom: "4px",
                                        textDecoration: "none",
                                      }}
                                      className="removeUnderline line-clamp-2 hover:text-blue-100"
                                    >
                                      {legalBusinessName}
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      color="textSecondary"
                                      fontSize={{
                                        xs: "14px",
                                        sm: "14px",
                                        md: "14px",
                                      }}
                                      lineHeight={"130%"}
                                      letterSpacing={"-0.04em"}
                                      className="removeUnderline"
                                    >
                                      {State}
                                    </Typography>
                                  </Box>
                                </MuiLink>
                              </Box>
                            </InfoWindowF>
                          </div>
                        )}
                      </MarkerF>
                    );
                  });

                  return (
                    <React.Fragment key={customerId}>{markers}</React.Fragment>
                  );
                }
              )}
            </GoogleMap>
          )}
        </Grid>
      </Grid>

      {/* Mobile */}
      <Grid
        container
        //   spacing={{ xs: 0, sm: 0, md: 0 }}
        className=" text-center px-6 py-4 mx-auto place-content-center items-center md:hidden"
        alignContent={"center"}
        alignItems={"center"}
        alignSelf={"center"}
      >
        <Box mt={5} className="">
          <Typography
            variant="h1"
            fontSize={{ xs: "25px", sm: "30px", md: "40px" }}
            textTransform="capitalize"
            sx={{
              mb: 1,
            }}
          >
            {careData && careData.length} Results
          </Typography>
          <Typography
            variant="body1"
            color="primary.main"
            fontSize={{ sm: "14px", md: "16px" }}
            lineHeight={"150%"}
            letterSpacing={"0.02em"}
            mx="auto"
          >
            Find and book the best child care near you
          </Typography>
        </Box>
        {currentPosts &&
          currentPosts.map((data, index) => (
            <Grid item xs={12} sm={6} md={3} lg={3} key={index}>
              <CareCard
                legalBusinessName={data.legalBusinessName}
                busRating={data.busRating}
                city={data.city}
                address={data.address}
                State={data.State}
                ageRange={limitString(data.ageRange, 30)}
                availability={data.startDay + " - " + data.endDay}
                openingTime={data.openingTime}
                closingTime={data.closingTime}
                charges={data.charges}
                slug={data.slug}
                customerId={data.customerId}
                // feat1={data.feat1}
                // feat2={data.feat2}
                // feat3={data.feat3}
                // feat4={data.feat4}
                documentList={data.documentList}
                verified={data.verified}
              />
            </Grid>
          ))}
        {/* <Pagination
          totalPosts={careData.length}
          postsPerPage={postsPerPage}
          onClick={() => {
            setCurrentPage(currentPage - 1);
          }}
          onClicked={() => {
            setCurrentPage(currentPage + 1);
          }}
          currentPage={currentPage}
        /> */}
        <Box display={"flex"} justifyContent={"center"} my={2}>
          <MHPagination
            count={noOfPage}
            variant="outlined"
            shape="rounded"
            page={currentPage}
            onChange={handlePageChange}
            color={"primary"}
          />
        </Box>
      </Grid>
      <MiniFooter />
    </React.Fragment>
  );
};

export default SearchCareProviderMap;
