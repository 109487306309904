import { Box, Typography } from "@mui/material";
import React from "react";

type Props = {
  icon: React.ReactElement;
  text: string | any;
  iconClass?: string | any;
};

const CareIconText = (props: Props) => {
  return (
    <Box className={`gap-2  flex  ${props.iconClass}`}>
      <Box>{props.icon}</Box>
      <Box>
        <Typography
          variant="subtitle2"
          color="primary"
          align="left"
          fontSize=".8rem"
          lineHeight="180%"
          letterSpacing="0.04em"
        >
          {props.text}
        </Typography>
      </Box>
    </Box>
  );
};

export default CareIconText;
