import React from "react";

import OldMHLogo from "../../theme/icons/OldMHLogo";
import OldMHWhiteLogo from "../../theme/icons/OldMHWhiteLogo";
import { Grid, Typography } from "@mui/material";

type Props = {};

const MiniFooter = (props: Props) => {
  return (
    <React.Fragment>
      <Grid
        item
        xs={12}
        pt={{ xs: "0px", sm: "0px" }}
        mt={{ xs: "60px", sm: "0px" }}
        bgcolor={{ xs: "#194049", sm: "#ffffff" }}
        // className="footerBg"
      >
        <div className="!bg-[#A9A9A9] w-[90%] md:w-[98%] h-[1px] mx-auto" />

        <Grid
          container
          spacing={2}
          sx={{
            justifyContent: "center",
            paddingTop: { xs: "20px", sm: "50px" },
            paddingBottom: { xs: "20px", sm: "30px" },
            textAlign: { xs: "center", sm: "inherit" },
          }}
        >
          <Grid item xs={10} sm={3.5} md={3}>
            <Typography
              variant="body1"
              color={"primary"}
              fontSize={{ xs: "22px", sm: "18px", md: "25px" }}
              lineHeight={{ xs: "160.5%", sm: "160.5%" }}
              letterSpacing={"-4%"}
              className=" capitalize hidden md:block"
            >
              <strong className=" font-areaBlack">Care,</strong> Manage,
              Succeed.
            </Typography>
            <Typography
              variant="body1"
              color={"#fff"}
              fontSize={{ xs: "22px", sm: "18px", md: "25px" }}
              lineHeight={{ xs: "160.5%", sm: "160.5%" }}
              letterSpacing={"-4%"}
              className=" capitalize md:hidden"
            >
              <strong className=" font-areaBlack">Care,</strong> Manage,
              Succeed.
            </Typography>
          </Grid>

          {/* Divider */}
          <div className="bg-[#A9A9A9] w-[90%] md:w-[98%] my-3 h-[1px] mx-auto md:hidden" />
          <Grid item xs={9} sm={2} md={2}>
            <Typography
              variant="body1"
              color={"primary"}
              fontSize={{ xs: "10px", sm: "10px", md: "12px" }}
              lineHeight={{ xs: "160.5%", sm: "160.5%" }}
              letterSpacing={"-4%"}
              className=" hidden md:block"
            >
              <strong className=" font-areaBlack">
                Email Us: <br />
              </strong>
              hello@caringblocks.com
            </Typography>
            <Typography
              variant="body1"
              color={"#fff"}
              fontSize={{ xs: "10px", sm: "10px", md: "12px" }}
              lineHeight={{ xs: "160.5%", sm: "160.5%" }}
              letterSpacing={"-4%"}
              className=" text-start md:hidden my-3"
            >
              <strong className=" font-areaBlack">
                Email Us: <br />
              </strong>
              hello@caringblocks.com
            </Typography>
          </Grid>
          <Grid item xs={9} sm={1.5} md={2}>
            <Typography
              variant="body1"
              color={"primary"}
              fontSize={{ xs: "10px", sm: "10px", md: "12px" }}
              lineHeight={{ xs: "160.5%", sm: "160.5%" }}
              letterSpacing={"-4%"}
              className="hidden md:block "
            >
              <strong className=" font-areaBlack">
                Call Us: <br />
              </strong>
              +234-704-459-5899
            </Typography>
            <Typography
              variant="body1"
              color={"#fff"}
              fontSize={{ xs: "10px", sm: "10px", md: "12px" }}
              lineHeight={{ xs: "160.5%", sm: "160.5%" }}
              letterSpacing={"-4%"}
              className=" md:hidden my-3 text-start"
            >
              <strong className=" font-areaBlack">
                Call Us: <br />
              </strong>
              +234-704-459-5899
            </Typography>
          </Grid>
          <Grid item xs={9} sm={3.5} md={2.5}>
            <Typography
              variant="body1"
              color={"primary"}
              fontSize={{ xs: "10px", sm: "10px", md: "12px" }}
              lineHeight={{ xs: "160.5%", sm: "160.5%" }}
              letterSpacing={"-4%"}
              className=" hidden md:block"
            >
              <strong className=" font-areaBlack">
                Visit Us: <br />
              </strong>
              VI, Lagos, Nigeria
            </Typography>
            <Typography
              variant="body1"
              color={"#fff"}
              fontSize={{ xs: "10px", sm: "10px", md: "12px" }}
              lineHeight={{ xs: "160.5%", sm: "160.5%" }}
              letterSpacing={"-4%"}
              className=" text-start  md:hidden my-3"
            >
              <strong className=" font-areaBlack">
                Visit Us: <br />
              </strong>
              VI, Lagos, Nigeria
            </Typography>
          </Grid>
        </Grid>

        {/* Divider */}
        <div className="bg-[#A9A9A9] w-[90%] md:w-[98%] h-[1px] mx-auto" />
      </Grid>

      <Grid
        item
        xs={12}
        sm={10.5}
        md={9.5}
        bgcolor={{ xs: "#194049", sm: "#ffffff" }}
      >
        <Grid
          container
          spacing={2}
          sx={{
            justifyContent: { sm: "space-between" },
            textAlign: { xs: "center", sm: "inherit" },
          }}
          py={{ xs: 2, sm: 0 }}
        >
          <Grid item xs={12} sm={5} md={4}>
            <Typography
              variant="body1"
              color={"primary"}
              fontSize={{ xs: "10px", sm: "10px", md: "12px" }}
              lineHeight={{ xs: "160.5%", sm: "160.5%" }}
              letterSpacing={"-4%"}
              className=" hidden md:block capitalize"
            >
              2024 Caring Blocks, All Rights Reserved | Privacy Policy
            </Typography>
            <Typography
              variant="body1"
              color={"#fff"}
              fontSize={{ xs: "10px", sm: "10px", md: "12px" }}
              lineHeight={{ xs: "160.5%", sm: "160.5%" }}
              letterSpacing={"-4%"}
              className="  md:hidden capitalize"
            >
              2024 Caring Blocks, All Rights Reserved | Privacy Policy
            </Typography>
          </Grid>
          <Grid item xs={12} sm={5} md={3} sx={{ textAlign: { sm: "end" } }}>
            <Typography
              variant="body1"
              color={"primary"}
              fontSize={{ xs: "10px", sm: "10px", md: "12px" }}
              lineHeight={{ xs: "160.5%", sm: "160.5%" }}
              letterSpacing={"-4%"}
              className="hidden md:block capitalize"
            >
              Powered by &nbsp;
              <OldMHLogo
                style={{
                  width: "4rem",
                  display: "inline",
                  verticalAlign: "middle",
                }}
              />
            </Typography>
            <Typography
              variant="body1"
              color={"#fff"}
              fontSize={{ xs: "10px", sm: "10px", md: "12px" }}
              lineHeight={{ xs: "160.5%", sm: "160.5%" }}
              letterSpacing={"-4%"}
              className=" md:hidden capitalize"
            >
              Powered by &nbsp;
              <OldMHWhiteLogo
                style={{
                  width: "4rem",
                  display: "inline",
                  verticalAlign: "middle",
                }}
              />
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default MiniFooter;
