import React from "react";
import MHButton from "../../components/Common/Button/MHButton";
import { Box, Grid, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";

type Props = {};

export const ClaimPage = (props: Props) => {
  let history = useHistory();

  const handleNext = () => {
    history.push("/auth/center/claim");
  };
  return (
    <React.Fragment>
      <Grid
        container
        spacing={2}
        sx={{
          //   paddingTop: "20px",
          justifyContent: "center",
          alignItems: "center",

          // paddingTop: "80px",
          // paddingBottom: "100px",
          height: { xs: "full", sm: "520px", md: "700px" },
          backgroundColor: "#ffffff",
          overflow: "hidden",
          overscrollBehaviorY: "hidden",
        }}
      >
        <Grid
          item
          xs={10.5}
          sm={5.5}
          md={4.8}
          className=" flex items-center order-last md:order-first"
        >
          <Box>
            <Typography
              variant="subtitle2"
              color="primary"
              fontSize={{ xs: "24px", sm: "26px", md: "26px" }}
              lineHeight={{ xs: "120%", sm: "130.5%" }}
              letterSpacing={"-4%"}
              textAlign={{ xs: "center", sm: "start" }}
              className=" capitalize"
            >
              Solutions for Care Providers
            </Typography>

            <Typography
              variant="body1"
              color="primary"
              fontSize={{ xs: "14px", sm: "12px", md: "14px" }}
              lineHeight={{ xs: "200%", sm: "200%" }}
              letterSpacing={"-4%"}
              className="pt-3 pb-6 mx-auto capitalize text-center md:text-start"
              width={{ xs: "90%", sm: "100%" }}
            >
              Boost you ennrollments and speed up tasks using our free & paid
              tools.
              <br />
              <br />
              Focus on what matters most — providing quality care.
            </Typography>

            <MHButton
              color="primary"
              // sx={{}}
              // type="submit"
              className="mt-10 mb-12 md:mb-24  w-full md:w-[280px] h-[54px]"
              // startIcon={<PlusIcon />}
              // onClick={handleDownload}
              onClick={handleNext}
            >
              Claim your free page
            </MHButton>
          </Box>
        </Grid>

        <Grid
          item
          xs={10.5}
          sm={5.5}
          md={4.8}
          sx={{ display: { xs: "grid", sm: "grid" } }}
          height={{ xs: "full", sm: "500px", md: "680px" }}
          className=" items-center  order-first md:order-last"
        >
          <img
            src="https://careafrica.motherhonestly.info/assets/1705026291409741.jpg"
            alt="Care Providers"
            className=" rounded-xl object-cover h-fit md:h-[400px] lg:h-[520px]"
            draggable={false}
          />
        </Grid>
      </Grid>
      <hr className="w-48 text-[#194049]" />
    </React.Fragment>
  );
};
