import * as React from "react";
import { styled } from "@mui/system";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { grey, blue } from "@mui/material/colors";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      // maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      maxHeight: "100%",
      width: 250,
    },
  },
};

// Styled Select Component
const StyledSelect = styled(Select)(({ theme }) => ({
  fontFamily: "Area-Normal-Semibold",
  fontSize: "12px",
  boxSizing: "border-box",
  height: "calc(1.5em + 26px)",
  width: "185px",
  border: "none",
  borderRadius: "inherit",
  background: theme.palette.mode === "dark" ? grey[900] : "#fff",
  marginBottom: "1.25rem",
  padding: "12px 12px",
  textAlign: "left",
  lineHeight: 1.5,
  color: theme.palette.mode === "dark" ? grey[300] : grey[900],
  display: "flex",
  alignItems: "center",
  overflowX: "hidden",
  "&:hover": {
    borderColor: theme.palette.mode === "dark" ? grey[700] : grey[300],
  },
}));

// Custom Styled InputLabel with updated font family and size
const CustomInputLabel = styled(InputLabel)(({ theme }) => ({
  fontFamily: "Area-Normal-Semibold", // Change to your desired font family
  fontSize: "12px", // Adjust the font size here
  color: grey[700],
}));

interface MHSelectMultipleProps {
  label: string;
  options: string[];
  selectedOptions: string[];
  startAdornment?: React.ReactElement;
  endAdornment?: React.ReactElement;
  onChange: (selected: string[]) => void;
}

const MHSelectMultiple: React.FC<MHSelectMultipleProps> = ({
  label,
  options,
  selectedOptions,
  startAdornment,
  endAdornment,
  onChange,
}) => {
  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const {
      target: { value },
    } = event;

    // Type assertion to ensure value is a string array
    onChange(
      typeof value === "string" ? value.split(",") : (value as string[])
    );
  };

  return (
    <FormControl>
      <CustomInputLabel id="multiple-select-checkbox-label">
        {label}
      </CustomInputLabel>
      <StyledSelect
        labelId="multiple-select-checkbox-label"
        id="multiple-select-checkbox"
        multiple
        displayEmpty
        value={selectedOptions}
        startAdornment={startAdornment}
        endAdornment={endAdornment}
        onChange={handleChange}
        input={<OutlinedInput label={label} />}
        renderValue={(selected) => (selected as string[]).join(", ")} // Cast selected as string[]
        MenuProps={MenuProps}
      >
        {options.map((option) => (
          <MenuItem
            key={option}
            value={option}
            sx={{ textTransform: "capitalize" }}
          >
            <Checkbox checked={selectedOptions.includes(option)} />
            <ListItemText primary={option} />
          </MenuItem>
        ))}
      </StyledSelect>
    </FormControl>
  );
};

export default MHSelectMultiple;
