import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { HeroCard } from "../../components/Features/Landing/HeroCard";

type Props = {};

export const Categories = (props: Props) => {
  const catData = [
    {
      imgSrc:
        "https://mh-resources-production.s3.us-west-1.amazonaws.com/18.png",
      label: "Families",
      caption: "Your trusted partner for safe, reliable care",
      path: "families",
    },
    {
      imgSrc:
        "https://mh-resources-production.s3.us-west-1.amazonaws.com/Competitive+Pay+And+Benefits.png",
      label: "Caregivers",
      caption: "Raising the expectations and standard of care",
      path: "caregivers",
    },
    {
      imgSrc:
        "https://mh-resources-production.s3.us-west-1.amazonaws.com/Providers1.jpg",
      label: "Providers",
      caption: "Elevating the expectations and standard of care",
      path: "providers",
    },
    {
      imgSrc:
        "https://mh-resources-production.s3.us-west-1.amazonaws.com/For+Corporate.png",
      label: "Corporate",
      caption: "Employee benefits that solves for childcare",
      path: "corporate",
    },
    {
      imgSrc:
        "https://mh-resources-production.s3.us-west-1.amazonaws.com/Childcare+as+a+Catalyst+for+Growth11.png",
      label: "Government",
      caption: "Impact on Communities and Societies",
      path: "government",
    },
  ];
  return (
    <React.Fragment>
      <Box
        sx={{
          paddingY: "50px",
          justifyContent: "center",
          alignItems: "center",
          height: { xs: "100%", sm: "100%", md: "100%" },
          overflowX: "hidden",
          position: "relative",
        }}
        className="subscriptionPlan mb-5"
      >
        <div id="categories"></div>

        {/* <Typography
          variant="subtitle2"
          color="primary"
          fontSize={{ xs: "28px", sm: "27px", md: "29px" }}
          lineHeight={{ xs: "130%", sm: "160.5%" }}
          letterSpacing={"-4%"}
          className="py-4 text-center mx-auto md:mx-0 capitalize text-navy-900"
          width={{ xs: "80%", sm: "100%" }}
        >
          Choose Your Subscription Plan
        </Typography>

        <Typography
          variant="body1"
          color="primary"
          fontSize={{ xs: "14px", sm: "12px", md: "14px" }}
          lineHeight={{ xs: "200%", sm: "200%" }}
          letterSpacing={"-4%"}
          className="py-4 text-center w-[80%] md:w-[35%] capitalize mx-auto text-navy-900"
        >
          We believe managing your household employees should be
          straightforward, transparent, and secure.
        </Typography> */}

        {/* <Grid
          container
          spacing={0}
          justifyContent="center"
          alignItems="center"
          className=" z-20 bg-inherit"
        > */}
        <Box
          className="flex flex-row lg:pl-8 gap-4 pl-8"
          sx={{
            display: "flex",
            gap: "16px",
            whiteSpace: "nowrap", // Keeps the container in one line
            animation: "scroll 40s linear infinite", // Slower scroll
            willChange: "transform",
            "& > *": {
              whiteSpace: "normal", // Allow child content to wrap
            },
            "&:hover": {
              animationPlayState: "paused", // Pause animation on hover
            },
          }}
        >
          {catData.concat(catData).map((item, index) => (
            <Box
              className="mt-5 basis-1/4"
              key={index}
              // sx={{
              //   flex: "0 0 auto",
              // }}
            >
              <HeroCard
                imgSrc={item.imgSrc}
                label={item.label}
                caption={item.caption}
                path={item.path}
              />
            </Box>
          ))}
          {/* </Grid> */}
        </Box>
      </Box>

      {/* <Box className=" lg:hidden grid grid-cols-1 md:grid-cols-2 gap-2 pl-12 md:pl-20 py-8">
        {catData &&
          catData.map((item, index) => (
            <Box className="mt-5" key={index}>
              <HeroCard
                imgSrc={item.imgSrc}
                label={item.label}
                caption={item.caption}
                path={item.path}
              />
            </Box>
          ))}
      </Box> */}

      <style>
        {`
          @keyframes scroll {
            from {
              transform: translateX(0);
            }
            to {
              transform: translateX(-100%);
            }
          }
        `}
      </style>
    </React.Fragment>
  );
};
