import { Box, Grid, Tab, Tabs } from "@mui/material";
import React from "react";
import ProfileSettings from "./ProfileSettings";
import EmployerWithdrawal from "./EmployerWithdrawal";

import { ReactComponent as VisibilityIcon } from "../../../static/svg/visibility-grey.svg";
import { ReactComponent as VisibilityOffIcon } from "../../../static/svg/visibility-grey-off.svg";

import { ReactComponent as WalletsIcon } from "../../../static/svg/wallets.svg";
import { ReactComponent as WalletIcon } from "../../../static/svg/wallet.svg";

import RoundedLogoIcon from "../../../theme/icons/RoundedLogo";
import { WalletStatProps } from "./WalletStatDesign";
import { useOnboardForm } from "../../../store/context/onboard-context";
import CenterSettings from "./CenterSettings";
import CenterDirectorSettings from "./CenterDirectorSettings";
import DashboardContext from "../../../store/context/dashboard.context";

export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

export function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

type Props = {
  // tabValue: number;
  // handleTabChange: (event: React.SyntheticEvent, newValue: number) => void;
};

const WalletBalanceStatsGrid = () => {
  // const authCtx = React.useContext(AuthContext);

  const [toggleState, setToggleState] = React.useState(false);

  const handleToggle = () => {
    setToggleState(!toggleState);
    // console.log(toggleState);
  };

  const [totalSalary, setTotalSalary] = React.useState<number>(0); // State to hold the total salary

  const { accountDetails, getAcctDetails } = useOnboardForm();

  let currentFund = accountDetails?.balance ?? 0;

  let expensesMonthly = 0;

  const balance =
    currentFund === 0 ? 0 : toggleState === true ? currentFund : "******";
  // const balance = toggleState === true ? currentFund : "******"; // Assuming this is a number representing a balance

  const STATS: WalletStatProps[] = [
    {
      theme: "default",
      icon: (
        <RoundedLogoIcon
          sx={{
            p: 0.6,
            mb: 2,
            backgroundColor: currentFund === 0 ? "#EEEEEE" : "#C7D8E4",
          }}
        >
          {currentFund === 0 ? (
            <WalletIcon className="w-6 h-6" />
          ) : (
            <WalletsIcon className="w-6 h-6" />
          )}
        </RoundedLogoIcon>
      ),
      icon2: (
        <RoundedLogoIcon sx={{ backgroundColor: "transparent" }}>
          {toggleState === false ? (
            <VisibilityIcon className="w-6 h-6" />
          ) : (
            <VisibilityOffIcon className="w-6 h-6" />
          )}
        </RoundedLogoIcon>
      ),
      title: "Household Payroll Wallet",
      stat: balance,
      role: "Employer",
      account: 0,
      onClick: handleToggle,
    },
  ];

  return (
    <React.Fragment>
      <Grid
        container
        spacing={0}
        className=" place-content-start md:justify-between gap-2 md:gap-0"
      >
        {STATS.map((stat) => (
          <Grid
            item
            xs={12}
            sm={12}
            md={9}
            key={stat.title}
            p={4}
            // onClick={handleOpen}
            sx={{
              // cursor: "pointer",
              backgroundColor: "#F9F9F7",
              borderRadius: 3,
              height: "300px",
            }}
            className=" lg:flex gap-3"
          >
            <Box className=" w-full" position={"relative"}>
              <EmployerWithdrawal {...stat} />
            </Box>
          </Grid>
        ))}
      </Grid>
    </React.Fragment>
  );
};

const ProfileOverview = (props: Props) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const [indicatorWidth, setIndicatorWidth] = React.useState(0);
  const tabIndicatorRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (tabIndicatorRef.current) {
      const selectedTab = document.querySelector(
        `.MuiTab-root:nth-child(${value + 1})`
      ) as HTMLElement;
      setIndicatorWidth(selectedTab.offsetWidth);
    }
  }, [value]);

  const dashboardCtx = React.useContext(DashboardContext);
  const { organization } = dashboardCtx;

  // console.log(organization);

  return (
    <Box>
      <Tabs
        value={value}
        className="font-areaNorm"
        // onChange={props.handleTabChange}
        onChange={handleChange}
        aria-label="basic tabs example-1-1"
      >
        <Tab
          className=" md:mr-0 -ml-4 text-xs capitalize leading-[102%] tracking-[0.04em]"
          label="Account Settings"
          disableRipple
          {...a11yProps(0)}
        />
        <Tab
          className=" md:mr-0 text-xs capitalize leading-[102%] tracking-[0.04em]"
          label="Withdraw"
          disableRipple
          {...a11yProps(1)}
        />
        {organization?.businessId !== 0 ? (
          <Tab
            className=" md:mr-0 text-xs capitalize leading-[102%] tracking-[0.04em]"
            label="Business Information"
            disableRipple
            {...a11yProps(2)}
          />
        ) : null}
        {organization?.businessId !== 0 ? (
          <Tab
            className=" md:mr-0 text-xs capitalize leading-[102%] tracking-[0.04em]"
            label="Director Information"
            disableRipple
            {...a11yProps(3)}
          />
        ) : null}
      </Tabs>

      <Box my={3}>
        <TabPanel value={value} index={0}>
          <ProfileSettings />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <WalletBalanceStatsGrid />
        </TabPanel>
        {organization?.businessId !== 0 ? (
          <TabPanel value={value} index={2}>
            <CenterSettings />
          </TabPanel>
        ) : null}
        {organization?.businessId !== 0 ? (
          <TabPanel value={value} index={3}>
            <CenterDirectorSettings />
          </TabPanel>
        ) : null}
      </Box>
    </Box>
  );
};

export default ProfileOverview;
