import React from "react";
import { useParams } from "react-router-dom";
import {
  AboutLikeProps,
  CareProviderProps,
  FullCareProps,
  LocationProps,
} from "../../models/care.details";
import MegaGallery from "../../components/CareProvider/ViewCare/MegaGallery";
import AboutCareProvider from "../../components/CareProvider/ViewCare/AboutCareProvider";
import { Box, Divider, Grid } from "@mui/material";
// import CustomCareCalendar from "../../components/CareProvider/ViewCare/CareCenterCalendar";
import { DataContext } from "../../store/context/findcare.context";
import CareLocations from "./CareLocations";
import MiniFooter from "../Landing/MiniFooter";
import RequestCare from "../../components/CareProvider/ViewCare/RequestCare";
import NewMegaGallery from "../../components/CareProvider/ViewCare/NewMegaGallery";

const ViewCare = () => {
  const params = useParams<any>();

  const { formData, setFormData } = React.useContext(DataContext);

  const [careData, setCareData] = React.useState<CareProviderProps[]>([]);
  const [fullCareData, setFullCareData] = React.useState<FullCareProps[]>([]);
  const [aboutCareData, setAboutCareData] = React.useState<AboutLikeProps[]>(
    []
  );
  const [likeData, setLikeData] = React.useState<AboutLikeProps[]>([]);
  const [directorData, setDirectorData] = React.useState<AboutLikeProps[]>([]);
  const [latitudeData, setLatitudeData] = React.useState<LocationProps[]>([]);
  const [longitudeData, setLongitudeData] = React.useState<LocationProps[]>([]);

  let userId = 1234;

  var careUrl = `${process.env.REACT_APP_CARE_CENTER_API}/dashboard?customerId=${params.customerId}`;

  const viewCare = async () => {
    try {
      const response = await fetch(careUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      let jsonData = await response.json();

      setCareData(jsonData.data.careCenter);
      setFullCareData(jsonData.data.careCenter[0]);
      setAboutCareData(jsonData.data.aboutCareCenter);
      setLikeData(jsonData.data.whyWeLikeIt);
      setDirectorData(jsonData.data.directorsBio[0]);
      setLatitudeData(jsonData.data.careCenterLocation[0].latitude);
      setLongitudeData(jsonData.data.careCenterLocation[0].longitude);
      // console.log("formData", formData);
    } catch (err) {
      console.error(err);
    }
  };
  React.useEffect(() => {
    if (careData && careData[0]) {
      // Set FormData for findcare.context
      setFormData({
        ...formData,
        careProviderName: careData[0].legalBusinessName,
        careProviderId: careData[0].customerId,
        startDay: careData[0].startDay,
        endDay: careData[0].endDay,
        userId: userId,
      });
    }
  }, [careData]);

  React.useEffect(() => {
    viewCare();
  }, [params.customerId]);

  // console.log("Single careData", careData);
  // console.log("Params ID", params.customerId);
  // console.log("Director Care Data", directorData);
  // console.log("Location Data", { latitudeData, longitudeData });

  const docList = careData[0]?.documentList || [];
  const imageList =
    "https://careafrica.motherhonestly.info/assets/1705026291409741.jpg@@@@https://careafrica.motherhonestly.info/assets/1705026341657664.jpg@@@@https://careafrica.motherhonestly.info/assets/1705026370312444.jpg@@@@https://careafrica.motherhonestly.info/assets/1705026383010010.jpg@@@@https://careafrica.motherhonestly.info/assets/1705047954526186.jpg";

  // const imageList = careData[0]?.centerImage || [];

  const imageArray = imageList.split("@@@@");

  // console.log("imageArray", imageArray);
  return (
    <React.Fragment>
      <Box className="px-4 md:px-32" bgcolor="#f5f5f5">
        <MegaGallery documentList={docList} />
        {/* <NewMegaGallery imageList={imageArray} /> */}

        <Grid
          container
          //   spacing={{ xs: 0, sm: 0, md: 0 }}
          className=" text-center py-4 mx-auto place-content-center justify-between md:gap-4 lg:gap-0"
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={7.5}
            lg={7.5}
            // className="md:order-2 lg:order-1"
            bgcolor="#ffffff"
            p={4}
          >
            {careData && careData.length > 0 && (
              <AboutCareProvider
                legalBusinessName={careData[0].legalBusinessName}
                busLocation={careData[0].address + ", " + careData[0].State}
                ageRange={careData[0].ageRange}
                openingTime={careData[0].openingTime}
                closingTime={careData[0].closingTime}
                availability={careData[0].startDay + " - " + careData[0].endDay}
                verified={careData[0].verified}
                careProviderBio={careData[0].careProviderBio}
                documentList={careData[0].documentList}
                address={careData[0].address + ", " + careData[0].State}
                fullCareData={fullCareData}
                aboutCareCenter={aboutCareData}
                directorsBio={directorData}
                whyWeLikeIt={likeData}
                latitude={latitudeData}
                longitude={longitudeData}
              />
            )}
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            // className="md:order-1 lg:order-2"
            bgcolor="#ffffff"
            p={2}
          >
            {/* <Box className=" md:grid hidden px-4 "> */}
            <RequestCare
              careCenterId={
                careData && careData.length > 0 ? careData[0].id : undefined
              }
            />

            {/* </Box> */}
          </Grid>
        </Grid>
        <Divider />
      </Box>
      <Box bgcolor="#f5f5f5" className=" py-4">
        <CareLocations />
      </Box>
      <MiniFooter />
    </React.Fragment>
  );
};

export default ViewCare;
