import React from "react";
import { singleTester } from "../../../utils/careprovider";
import { Box, Card, CardMedia, Grid, Zoom } from "@mui/material";
import { useHistory } from "react-router-dom";

import { ReactComponent as LeftBtn } from "../../../static/svg/mini-left-btn.svg";
import { ReactComponent as RightBtn } from "../../../static/svg/mini-right-btn.svg";
import Slider from "react-slick";
import { CareProviderProps } from "../../../models/care.details";

type Props = {};

type ArrowProps = {
  onClick?: (e: React.MouseEvent) => void;
};

function SampleNextArrow(props: ArrowProps) {
  return (
    <Box className="absolute top-[50%] right-0 md:top-[45%] z-10 md:right-1 lg:right-0 ">
      <RightBtn className="cursor-pointer" onClick={props.onClick} />
    </Box>
  );
}

function SamplePrevArrow(props: ArrowProps) {
  return (
    <Box className="absolute top-[50%] left-0 md:top-[45%] z-10 md:left-1 lg:left-0">
      <LeftBtn className="cursor-pointer" onClick={props.onClick} />
    </Box>
  );
}

const MegaGallery = (props: CareProviderProps) => {
  const [slideIndex, setSlideIndex] = React.useState(0);
  const history = useHistory();

  const settings = {
    centerMode: true,
    centerPadding: "0px",
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 800,
    autoplay: false,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    // autoplaySpeed: 1500,
    // beforeChange: (current, next) => setSlideIndex(next),
  };

  // console.log("Document Care Data", props.documentList);

  const filteredDocumentList = props.documentList?.filter(
    (document: any) => document.documentType === "PROVIDER-HEADER-IMAGE"
  );

  // console.log("Filtered Document List", filteredDocumentList);

  const limitedMatchingDocuments = filteredDocumentList?.slice(0, 5);

  // console.log("Limited Matching Documents", limitedMatchingDocuments);
  // console.log("Limited Matching Length", limitedMatchingDocuments?.length);

  if (limitedMatchingDocuments?.length === 0) {
    return (
      <React.Fragment>
        {/* Web Dummy Image */}

        <Box className="hidden lg:block">
          <Grid container spacing={2} className="pt-5">
            <Grid item xs={12} md={12} lg={12}>
              <Zoom in style={{ transitionDelay: "200ms" }}>
                <Card className={`relative w-auto h-[355px] object-cover `}>
                  <CardMedia
                    className={`object-cover ${"h-[355px]"} rounded-md`}
                    component="img"
                    image={`https://mh-resources-production.s3.us-west-1.amazonaws.com/CareProvider1.png`}
                    alt="Care Provider"
                  />
                </Card>
              </Zoom>
            </Grid>
          </Grid>
        </Box>

        {/* Mobile Dummy Image */}

        <Box className="lg:hidden block">
          <Grid container spacing={2} className="pt-5">
            <Grid item xs={12} md={12} lg={12}>
              <Zoom in style={{ transitionDelay: "200ms" }}>
                <Card className={`relative w-auto h-[355px] object-cover `}>
                  <CardMedia
                    className={`object-cover ${"h-[355px]"} rounded-md`}
                    component="img"
                    image={`https://mh-resources-production.s3.us-west-1.amazonaws.com/CareProviderMobile1.png`}
                    alt="Care Provider Mobile"
                  />
                </Card>
              </Zoom>
            </Grid>
          </Grid>
        </Box>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      {/* Web */}
      <Box className="hidden md:block">
        <Grid container spacing={2} className="mt-5">
          {limitedMatchingDocuments &&
            limitedMatchingDocuments?.map((data: any, index: any) => (
              <React.Fragment key={index}>
                {index === 0 && (
                  <Grid item xs={12} md={12} lg={6}>
                    <Zoom in style={{ transitionDelay: "200ms" }}>
                      <Card
                        className={`relative w-auto h-[355px] object-cover `}
                      >
                        <CardMedia
                          className={`object-cover ${
                            index === 0 ? "h-[355px]" : "h-[170px]"
                          } rounded-md`}
                          component="img"
                          image={`https://careafrica.motherhonestly.info/assets/${data.documentName}`}
                          alt={data.documentName}
                          key={index}
                        />
                      </Card>
                    </Zoom>
                  </Grid>
                )}
              </React.Fragment>
            ))}
          {/* Second grid with 4 items */}
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Grid container spacing={2}>
              {limitedMatchingDocuments &&
                limitedMatchingDocuments
                  .slice(1)
                  .map((data: any, index: any) => (
                    <Grid item xs={12} sm={6} md={6} lg={6} key={index}>
                      <Zoom in style={{ transitionDelay: "200ms" }}>
                        <Card
                          className={`relative w-auto h-[170px] object-cover`}
                        >
                          <CardMedia
                            className="object-cover h-full rounded-md"
                            component="img"
                            image={`https://careafrica.motherhonestly.info/assets/${data.documentName}`}
                            alt={data.documentName}
                            key={index}
                          />
                        </Card>
                      </Zoom>
                    </Grid>
                  ))}
            </Grid>
          </Grid>
        </Grid>
      </Box>

      {/* Mobile */}
      <Box className="block md:hidden w-full mx-auto bg-[#F9F9F7] rounded-b-xl md:my-4 my-4">
        <Slider
          {...settings}
          beforeChange={(current, next) => setSlideIndex(next)}
        >
          {/* {singleTester[0].imageList.map((item: any) => ( */}
          {limitedMatchingDocuments?.map((item: any) => (
            <CardMedia
              component="img"
              image={`https://careafrica.motherhonestly.info/assets/${item.documentName}`}
              alt={item.documentName}
              className=" h-52 object-cover rounded-md"
            />
          ))}
        </Slider>
      </Box>
    </React.Fragment>
  );
};

export default MegaGallery;
