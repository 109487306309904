import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { CareProviderProps } from "../../models/care.details";
import CareCard from "../../components/CareProvider/global/CareCard";
import { limitString } from "../../utils/utils";
import MHButton from "../../components/Common/Button/MHButton";
import {
  fetchState,
  getStateFromCoordinates,
  useGeolocation,
} from "../../hooks/get-location";
import { getCookie } from "../../hooks/use-cookie";

const imageListArray = [
  {
    imgPath:
      "https://bumo-care.s3.us-west-1.amazonaws.com/Joyce's+Toluca+Lake+Pre-School2.webp",
  },

  {
    imgPath:
      "https://bumo-care.s3.us-west-1.amazonaws.com/Toluca+Lake+Preschool2.webp",
  },
  {
    imgPath:
      "https://bumo-care.s3.us-west-1.amazonaws.com/Toluca+Lake+Preschool4.webp",
  },
  {
    imgPath:
      "https://bumo-care.s3.us-west-1.amazonaws.com/Joyce's+Toluca+Lake+Pre-School1.webp",
  },
  {
    imgPath:
      "https://bumo-care.s3.us-west-1.amazonaws.com/Joyce's+Toluca+Lake+Pre-School3.webp",
  },
];

const CareLocations = () => {
  const { lat, lng } = useGeolocation();
  if (lat === null || lng === null) {
    // Geolocation data is not available yet, you can render a loading state or nothing
    // return null;
    console.log("Lat and Long is null");
  }

  let history = useHistory();

  const handleClickOpen = () => {
    history.push(`/care-center-locations`);
  };

  const { path } = useRouteMatch();

  const [careData, setCareData] = useState<CareProviderProps[]>([]);

  const [noOfElement, setnoOfElement] = useState(3);

  var careUrl = `${process.env.REACT_APP_CARE_CENTER_API}/search?status=ACTIVE`;

  const getCare = async () => {
    try {
      const response = await fetch(careUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      let jsonData = await response.json();

      setCareData(jsonData.data);
      // console.log("jsonData", jsonData);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getCare();
  }, []);
  // console.log("careData", careData);

  const existingLat = getCookie("_mhLatCookie");
  const existingLng = getCookie("_mhLngCookie");
  const userState = localStorage.getItem("userState");

  fetchState(Number(existingLat), Number(existingLng));
  // console.log("userState", userState);

  // Filter careData based on userState
  const filteredCareData = careData.filter(
    (careProvider) =>
      careProvider.State === userState && careProvider.verified === true
  );

  const slice = filteredCareData.slice(0, noOfElement);

  // console.log("filteredCareData", filteredCareData);

  if (careData.length === 0) {
    return null;
  }

  return (
    <React.Fragment>
      <Box className=" w-[100%] lg:w-[80%] py-6 md:py-12 px-2 rounded-xl shadow-xl place-content-center mx-auto items-center bg-white  mb-16">
        <Box className=" flex w-[98%] lg:w-[74%] mx-auto">
          <Typography
            variant="h2"
            fontSize={{ xs: "14px", sm: "16px", md: "18px" }}
            lineHeight={"169%"}
            letterSpacing={"0.02em"}
            className=" self-center"
          >
            {path.includes("view-care") ? "Other Locations" : "Care Centers"}
          </Typography>
          <a
            href="/care-center-locations"
            className="ml-auto mr-0 no-underline mt-2"
          >
            <Typography
              variant="caption"
              textTransform="uppercase"
              fontSize={{ xs: "0.40rem", sm: "0.65rem", md: ".65rem" }}
              sx={{
                opacity: ".8",
              }}
              className=" ml-auto mr-0 self-center"
            >
              View All
              <Box className="bg-green-200 h-1 w-full "> </Box>
            </Typography>
          </a>
        </Box>

        <Grid
          container
          //   spacing={{ xs: 0, sm: 0, md: 0 }}
          className=" text-center py-4 mx-auto place-content-center items-center"
          alignContent={"center"}
          alignItems={"center"}
          alignSelf={"center"}
        >
          {slice &&
            slice?.map((data, index) => (
              <Grid item xs={12} sm={6} md={3} lg={3} key={index}>
                <CareCard
                  legalBusinessName={data.legalBusinessName}
                  busRating={data.busRating}
                  city={data.city}
                  address={data.address}
                  State={data.State}
                  availability={data.startDay + " - " + data.endDay}
                  ageRange={limitString(data.ageRange, 30)}
                  openingTime={data.openingTime}
                  closingTime={data.closingTime}
                  charges={data.charges}
                  slug={data.slug}
                  customerId={data.customerId}
                  // careProviderBio={data.careProviderBio}
                  // feat1={data.feat1}
                  // feat2={data.feat2}
                  // feat3={data.feat3}
                  // feat4={data.feat4}
                  documentList={data.documentList}
                  verified={data.verified}
                />
              </Grid>
            ))}
        </Grid>
        <Box className="pl-2 mx-auto place-content-center items-center ml-0 mr-0 text-center">
          <MHButton
            onClick={handleClickOpen}
            sx={{ width: "210px", height: "52px" }}
            color="primary"
            className="text-xs"
          >
            View More Locations
          </MHButton>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default CareLocations;
