import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import AboutCare from "./AboutCare";
import DirectorBio from "./DirectorBio";
import Location from "./Location";
import MorePolicy from "./MorePolicy";
import CareCenterCalendar from "./CareCenterCalendar";
import { CareProviderProps } from "../../../models/care.details";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ textAlign: "start" }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function AboutCareTab(props: CareProviderProps) {
  // const [value, setValue] = React.useState(1);

  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down("sm"));
  const [value, setValue] = React.useState(isPhone ? 0 : 0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  // console.log("Full Care Data", props.fullCareData);
  // console.log("Document Care Data", props.documentList);

  // const filteredDocumentList = props.documentList?.filter(
  //   (document: any) => document.documentType === "PROVIDER-IMAGE-ONBOARDING"
  // );

  // console.log("Filtered Document List", filteredDocumentList);

  const firstMatchingDocument = props.documentList?.find(
    (document: any) => document.documentType === "PROVIDER-IMAGE-ONBOARDING"
  );

  // console.log("First Matching Document", firstMatchingDocument);

  // console.log("props", props);

  return (
    <Box className="">
      <Box className=" ">
        <Tabs
          value={value}
          className=" -ml-5"
          onChange={handleChange}
          scrollButtons="auto"
          variant="scrollable"
          aria-label="scrollable auto tabs example"
          TabIndicatorProps={{
            sx: {
              // height: "1.2px !important",
              maxWidth: "50px",
              marginLeft: "18px !important",
              width: "50px !important",
              marginBottom: "10px !important",
              backgroundColor: "#194049 !important",
              // objectPosition: "center",
            },
          }}
        >
          <Tab
            className="font-areaExt "
            label="About"
            disableRipple
            {...a11yProps(0)}
          />

          <Tab
            className="font-areaExt "
            label="Director Bio"
            disableRipple
            {...a11yProps(1)}
          />
          <Tab
            className="font-areaExt "
            label="Location"
            disableRipple
            {...a11yProps(2)}
          />
          <Tab
            className="font-areaExt"
            label="More"
            disableRipple
            {...a11yProps(3)}
          />
        </Tabs>
      </Box>
      <Box className="grid h-fit">
        <TabPanel value={value} index={0}>
          {/* <AboutCare /> */}
          <AboutCare
            whyWeLikeIt={props.whyWeLikeIt}
            aboutCareCenter={props.aboutCareCenter}
            careProviderBio={props.careProviderBio}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <DirectorBio
            directorsAvatar={firstMatchingDocument}
            directorsBio={props.directorsBio}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Location
            address={props.address}
            latitude={props.latitude}
            longitude={props.longitude}
          />
          {/* <Location /> */}
        </TabPanel>
        <TabPanel value={value} index={3}>
          <MorePolicy />
        </TabPanel>
      </Box>
    </Box>
  );
}
