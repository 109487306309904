import React from "react";
import { Link, useHistory, useLocation } from "react-router-dom";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import MuiLink from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";

import MHFormControl from "../Form/MHFormControl";
import MHButton from "../Button/MHButton";
import InputAdornment from "../Form/InputAdornment";
import IconButton from "../Button/IconButtonStyled";
import useHttp from "../../../hooks/use-http";
import useTitle from "../../../hooks/use-title";

import { ReactComponent as ArrowLeftIcon } from "../../../static/svg/arrow-left.svg";
import { TextField } from "@mui/material";
import { useOnboardForm } from "../../../store/context/onboard-context";
import SnackbarContext from "../../../store/context/snackbar.context";

const Verification = () => {
  const { loading, error, sendHttpRequest: signIn } = useHttp();

  const snackbarCtx = React.useContext(SnackbarContext);
  const { toast } = snackbarCtx;

  const { form, setActiveStep, addToOnboard } = useOnboardForm();

  console.log("typer", form.type);

  const [otp, setOtp] = React.useState(["", "", "", ""]);
  const [dataResponse, setDataResponse] = React.useState(0);

  const handleVerifyChange = (index: number, value: string) => {
    if (value.match(/^\d*$/)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      if (index < otp.length - 1 && value.length === 1) {
        const nextInput = document.getElementById(`otp-input-${index + 1}`);
        nextInput?.focus();
      }
    }
  };

  const handleVerifyPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("Text");
    if (pastedData.match(/^\d{4}$/)) {
      const newOtp = pastedData.split("");
      setOtp(newOtp);
    }
  };

  const handleResend = async () => {
    await signIn(
      process.env.REACT_APP_API_BASE_URL + "/employer/verification-code",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          whatsAppNo: form.whatsapp,
        }),
      },
      (response) => {
        // setActiveStep((prevActiveStep) => prevActiveStep + 1);
        console.log(response);
        if (response.status === 200) {
          toast({
            message: "OTP sent to your Whatsapp number",
            variant: "success",
          });
        }
      }
    );
  };

  const verifyOtpHandler = () => {
    return new Promise((resolve) => {
      const verifiedOtp = otp.join("");
      signIn(
        process.env.REACT_APP_API_BASE_URL + "/employer/verify",
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            whatsAppNo: form.whatsapp,
            code: Number(verifiedOtp),
          }),
        },
        (response) => {
          setDataResponse(response.status);
          resolve(response.status); // Resolve the promise with the response status
          console.log(response);
          if (response.status === 200) {
            toast({
              message: "OTP Valid",
              variant: "success",
            });
            console.log("statuss", response.status);

            setActiveStep((prevActiveStep) => prevActiveStep + 1);
          } else {
            console.log("statuss", response.status);
          }
        }
      );
    });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    localStorage.removeItem("subscriptionType");
  };

  const handleVerify = async () => {
    const verifiedOtp = otp.join("");

    if (verifiedOtp.length !== 4) {
      toast({
        message: "OTP has to be 4 digits",
        variant: "error",
      });
      return; // Exit the function early if OTP length is not 4
    }

    const status = await verifyOtpHandler(); // Await the result of verifyOtpHandler

    if (dataResponse !== 200) {
      // toast({
      //   message: "Invalid OTP",
      //   variant: "error",
      // });
      console.log("status", status);
    } else {
      // console.log("Verified OTP:", verifiedOtp.length);
      // addToOnboard({ otp: Number(verifiedOtp) });
      // setActiveStep((prevActiveStep) => prevActiveStep + 1);
      console.log("status", dataResponse);
    }
  };

  // const verifyOtpHandler = () => {
  //   const verifiedOtp = otp.join("");
  //   signIn(
  //     process.env.REACT_APP_API_BASE_URL + "/employer/verify",
  //     {
  //       method: "PATCH",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         whatsAppNo: form.whatsapp,
  //         code: Number(verifiedOtp),
  //       }),
  //     },
  //     (response) => {
  //       setDataResponse(response.status);
  //       console.log(response);
  //       if (response.status === 200) {
  //         toast({
  //           message: "OTP Valid",
  //           variant: "success",
  //         });
  //         setActiveStep((prevActiveStep) => prevActiveStep + 1);
  //       }
  //     }
  //   );
  // };

  // const handleVerify = async () => {
  //   const verifiedOtp = otp.join("");

  //   if (verifiedOtp.length !== 4) {
  //     toast({
  //       message: "Invalid OTP 2",
  //       variant: "error",
  //     });
  //   }

  //   await verifyOtpHandler();

  //   if (dataResponse !== 200) {
  //     toast({
  //       message: "Invalid OTP",
  //       variant: "error",
  //     });
  //   } else {
  //     // console.log("Verified OTP:", verifiedOtp.length);
  //     // addToOnboard({ otp: Number(verifiedOtp) });
  //     setActiveStep((prevActiveStep) => prevActiveStep + 1);
  //   }
  // };

  // React.useEffect(() => {
  //   verifyOtpHandler();
  // }, [form]);

  return (
    <React.Fragment>
      <Box className="text-center">
        <Typography
          variant="h1"
          fontSize={{ xs: "24px", sm: "28px", md: "28px" }}
          component="div"
          gutterBottom
          mb={5}
        >
          {/* Verify your email */}
          OTP Confirmation
        </Typography>
      </Box>
      <Paper
        sx={{
          px: 3,
          py: 3,
          boxShadow: "0px 10px 16px rgba(154, 154, 154, 0.13)",
        }}
        className="h-[full] max-w-md mx-auto"
      >
        {error && (
          <Alert
            severity="error"
            sx={{
              mb: 3,
            }}
          >
            {error.message}
          </Alert>
        )}

        {/* autoComplete="off"
            noValidate */}
        <Box>
          <Box className=" text-center w-full">
            <Box display="flex" alignItems="center" justifyContent="center">
              <Typography
                variant="body1"
                fontSize={{ xs: "10px", sm: "10px", md: "10px" }}
                lineHeight={"19px"}
                component="div"
                gutterBottom
                mb={1.5}
                className="text-center mx-auto w-[90%]"
              >
                A confirmation code has been sent to,{" "}
                <Box
                  component="span"
                  className="text-[10px] text-[#0079D0] underline"
                >
                  {form.whatsapp}
                </Box>
                <br /> Please check whatsapp & enter the code promptly, as it
                will expire soon.
              </Typography>
            </Box>

            <Typography
              variant="body1"
              fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
              component="div"
              gutterBottom
              mb={1.5}
              className=" text-center mx-auto mt-6 w-[85%]"
            >
              Enter Code
            </Typography>
          </Box>
          <Box className=" mt-8 mb-14 mx-auto place-content-center text-center rounded-xl">
            {otp.map((value, index) => (
              <TextField
                key={index}
                id={`otp-input-${index}`}
                variant="outlined"
                value={value}
                onChange={(e) => handleVerifyChange(index, e.target.value)}
                onPaste={handleVerifyPaste}
                inputProps={{ maxLength: 1 }}
                style={{
                  marginRight: "10px",
                  width: "50px",
                }}
              />
            ))}
          </Box>

          <Box display="flex" alignItems="center" justifyContent="center">
            <Typography
              variant="body1"
              fontSize={{ xs: "10px", sm: "10px", md: "10px" }}
              component="div"
              gutterBottom
              mb={1.5}
              className="text-center mx-auto w-[100%]"
            >
              Not received? check spam folder or{" "}
              <MuiLink
                component={Link}
                underline="always"
                to="#"
                onClick={handleResend}
                // display="block"
                // align="left"
                className="text-blue-100 removeUnderline"
              >
                Resend it
              </MuiLink>
              {/* <br /> Already have an account ?{" "}
              <MuiLink
                component={Link}
                underline="always"
                to="/auth/sign-in"
                // display="block"
                // align="left"
                className="text-blue-100 removeUnderline"
              >
                Sign In
              </MuiLink> */}
            </Typography>
          </Box>

          <Box className="w-full flex  mt-6">
            <Box className=" ">
              <MHButton
                color="info"
                sx={{}}
                type="submit"
                className=" w-[120px] "
                startIcon={<ArrowLeftIcon />}
                onClick={handleBack}
              >
                Back
              </MHButton>
            </Box>

            <Box className=" mr-0 ml-auto">
              <MHButton sx={{}} onClick={handleVerify} className="  w-[120px] ">
                Next
              </MHButton>
            </Box>
          </Box>
        </Box>
      </Paper>
    </React.Fragment>
  );
};

export default Verification;
