// UploadDialog.tsx
import React from "react";
import MHDialog from "../../Common/Dialog/MHDialog";
import { Box, Button, Typography, IconButton, Avatar } from "@mui/material";
import MHButton from "../../Common/Button/MHButton";
import RoundedLogoIcon from "../../../theme/icons/RoundedLogo";

import MHFormControl from "../../Common/Form/MHFormControl";
import InputAdornment from "../../Common/Form/InputAdornment";
import * as validators from "../../../utils/validators";

import useInput from "../../../hooks/use-input";
import DashboardContext from "../../../store/context/dashboard.context";
import SnackbarContext from "../../../store/context/snackbar.context";
import AuthContext from "../../../store/context/auth-context";

import { ReactComponent as AvatarIcon } from "../../../static/svg/user-black.svg";
import { ReactComponent as ReceiptIcon } from "../../../static/svg/receipts.svg";
import UploadButton from "../../Common/Form/UploadButton";

interface CenterAvatarDialogProps {
  open: boolean;
  onClose: () => void;
}

const UploadWidget = () => (
  <Box>
    {/* <Typography variant="h3" align="center" paragraph>
      Upload Employee Spreadsheet
    </Typography> */}
    <Box display="flex" justifyContent="center">
      <Box width="full">
        <Avatar
          sx={{
            bgcolor: "#fff",
            width: { xs: "256px", sm: "200px", lg: "200px" },
            height: { xs: "256px", sm: "200px", lg: "200px" },
          }}
          variant="rounded"
        >
          <AvatarIcon className="avatarWH" />
        </Avatar>
      </Box>
    </Box>

    <Typography
      variant="subtitle1"
      fontSize={{ xs: "10px", sm: "10px" }}
      lineHeight="150%"
      align="center"
      color="#194049"
    >
      Drag or Drop your image here
    </Typography>
    <Typography
      variant="body1"
      fontSize={{ xs: "8px", sm: "8px" }}
      lineHeight="150%"
      align="center"
      color="#6B6B6B"
      gutterBottom
    >
      support jpg, png, jpeg, heic, webp
    </Typography>
    <Typography
      variant="body1"
      fontSize={{ xs: "8px", sm: "8px" }}
      lineHeight="150%"
      align="center"
      color="#6B6B6B"
    >
      (maximum size 2mb)
    </Typography>
  </Box>
);

const CenterAvatarDialog: React.FC<CenterAvatarDialogProps> = ({
  open,
  onClose,
}) => {
  const {
    value: enteredAmount,
    valid: enteredAmountIsValid,
    error: amountInputHasError,
    onChange: amountInputChangeHandler,
    onBlur: amountInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredTitle,
    valid: enteredTitleIsValid,
    error: titleInputHasError,
    onChange: titleInputChangeHandler,
    onBlur: titleInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredImage,
    valid: enteredImageIsValid,
    error: imageInputHasError,
    onChange: imageInputChangeHandler,
    onBlur: imageInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const authCtx = React.useContext(AuthContext);
  const { user } = authCtx;
  const snackbarCtx = React.useContext(SnackbarContext);
  const { toast } = snackbarCtx;

  const uploadBtnRef = React.useRef<any>(null);

  const [uploadedFile, setUploadedFile] = React.useState<File | null>(null);
  const [isDragActive, setIsDragActive] = React.useState<boolean>(false);

  const uploadChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const [file] = Array.from(e.target.files as FileList);
    validateUploadedFile(file);
  };

  const dragFileInHandler = (e: React.DragEvent<HTMLLabelElement>) => {
    setIsDragActive(true);
  };

  const dragFileOutHandler = (e: React.DragEvent<HTMLLabelElement>) => {
    setIsDragActive(false);
  };

  const dropFileHandler = (e: React.DragEvent<HTMLLabelElement>) => {
    const [file] = Array.from(e.dataTransfer.files as FileList);
    validateUploadedFile(file);
    setIsDragActive(false);
  };

  const validateUploadedFile = (file: File) => {
    const fileRef = uploadBtnRef.current;

    if (!file) {
      return;
    }

    setUploadedFile(file);
  };

  const deleteFileHandler = () => {
    setUploadedFile(null);
  };

  const submitHandler = () => {
    if (!uploadedFile) {
      return;
    }

    const formData = new FormData();
    formData.append("employerRefId", String(user?.employerRefId));
    formData.append("employeeFile", uploadedFile, uploadedFile.name);

    // sendHttpRequest(
    //   process.env.REACT_APP_API_BASE_URL + "/employer/getall",
    //   {
    //     method: "POST",
    //     headers: {
    //       // 'Content-Type': 'multipart/form-data'
    //     },
    //     body: formData,
    //   },
    //   (response: HttpResponse<unknown>) => {
    //     deleteFileHandler();
    //     toast({
    //       variant: "success",
    //       message: "Spreadsheet uploaded successfully",
    //     });
    //   }
    // );
    console.log("uploadedFile", uploadedFile);
    console.log("FileName", uploadedFile.name);
  };

  return (
    <MHDialog
      open={open}
      title={` `}
      handleClose={onClose}
      maxWidth="md"
      // actions={}
      scroll="paper"
    >
      <Box
        mb={0.6}
        height={{ xs: "full", sm: "500px" }}
        width={{ xs: "full", sm: "403px" }}
        className=" ml-0 mr-0 place-content-center items-center mx-auto "
      >
        <Box
          display="flex"
          alignItems="center"
          // alignSelf="center"
          // alignContent="center"
          justifyContent="center"
          mt={6}
          mb={3}
        >
          <RoundedLogoIcon sx={{ backgroundColor: "#C7D8E4" }}>
            <AvatarIcon className="w-6 h-6" />
          </RoundedLogoIcon>
          <Typography
            variant="h1"
            fontSize={{ xs: "18px", sm: "20px" }}
            align="center"
            gutterBottom
            display={"flex"}
            alignSelf={"center"}
            ml={2}
          >
            Choose a Profile Picture
          </Typography>
        </Box>

        <Box display="flex" justifyContent="center">
          <Box width="256px">
            <UploadButton
              htmlFor="csv-upload"
              file={uploadedFile}
              onChange={uploadChangeHandler}
              element={<UploadWidget />}
              onDragEnter={dragFileInHandler}
              onDragLeave={dragFileOutHandler}
              onDrop={dropFileHandler}
              accept="image/*, application/pdf"
              // accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              isDragActive={isDragActive}
              ref={uploadBtnRef}
              containerSx={{
                flexGrow: 1,
              }}
              onSubmitFile={submitHandler}
              onRemoveFile={deleteFileHandler}
              showUploadBtn={false}
            />
          </Box>
        </Box>

        <Box display="flex" justifyContent="center">
          <Box my={4} width={{ xs: "100%", sm: "256px" }}>
            <MHButton
              color="primary"
              sx={{}}
              type="submit"
              className=" "
              fullWidth
              // startIcon={<PlusIcon />}
              onClick={submitHandler}

              // onClick={() =>
              //   setActiveStep((prevActiveStep) => prevActiveStep - 1)
              // }
            >
              Upload
            </MHButton>
          </Box>
        </Box>
      </Box>
    </MHDialog>
  );
};

export default CenterAvatarDialog;
