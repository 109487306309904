import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useHistory, useRouteMatch } from "react-router-dom";
import useDialog from "../../hooks/use-dialog";

// type Props = {}

const GovtApproachSection = () => {
  let history = useHistory();

  const handleClickOpen = () => {
    history.push(`/employers`);
  };

  const {
    openDialog: open,
    handleOpenDialog: handleOpen,
    handleCloseDialog,
  } = useDialog();

  const handleClose = () => {
    handleCloseDialog();
  };

  return (
    <React.Fragment>
      <Box className="pt-10">
        <Box className="items-center place-content-center ">
          {/* <Typography className="uppercase font-areaNorm text-[12px] text-center md:text-[11px]  lg:text-[11px] leading-[102%] tracking-[0.1em] text-navy-900 ">
            THE ENTERPRISE PLAN
          </Typography> */}

          {/* <Typography className="py-8 font-columbia text-[30px] text-center md:text-[40px]  lg:text-[50px] leading-[120.5%] tracking-[-0.04em] text-navy-900 ">
            Join the many forward-
            <br className="md:hidden block" />
            thinking companies <br /> supporting their employee caregivers
          </Typography> */}
          <Typography
            variant="h2"
            width={{ xs: "90%", md: "65%" }}
            fontSize={{ xs: "30px", sm: "40px", md: "50px" }}
            lineHeight={"120.5%"}
            letterSpacing={"-0.04em"}
            mx="auto"
            className="py-8  text-center"
          >
            How We Support Governments
          </Typography>

          {/* Web */}

          <Box
            className="hidden md:grid grid-cols-3  mx-auto text-center"
            justifyContent="center"
          >
            {/* <Typography className="ml-8 mr-8 py-8 font-areaSemi text-[14px] text-center md:text-[14px]  lg:text-[16px] leading-[170%] md:leading-[150%] tracking-[0.02em] text-navy-900 ">
              Personalized Employee Work- <br /> Life Care Solutions
            </Typography> */}
            <Box className="ml-8 mr-8 py-8">
              <Typography
                variant="subtitle1"
                fontSize={{ xs: "12px", sm: "14px" }}
                lineHeight={"102%"}
                letterSpacing={"0.1em"}
                className="uppercase text-center"
              >
                Data-Driven Insights
              </Typography>
              <Typography
                variant="body1"
                fontSize={{ xs: "12px", sm: "14px", md: "14px" }}
                lineHeight={"150%"}
                letterSpacing={"0.02em"}
                className="py-4  text-center  "
              >
                &bull; Leverage our research and analytics to understand
                childcare gaps and opportunities in your communities.
                <br /> <br />
                &bull; Receive actionable data on how childcare impacts
                workforce participation, gender equity, and economic output.
              </Typography>
            </Box>

            <Box className="ml-8 mr-8 py-8">
              <Typography
                variant="subtitle1"
                fontSize={{ xs: "12px", sm: "14px" }}
                lineHeight={"102%"}
                letterSpacing={"0.1em"}
                className="uppercase text-center"
              >
                Community-Engaged Childcare Programs
              </Typography>
              <Typography
                variant="body1"
                fontSize={{ xs: "12px", sm: "14px", md: "14px" }}
                lineHeight={"150%"}
                letterSpacing={"0.02em"}
                className="py-4  text-center  "
              >
                &bull; Develop childcare models that align with local needs and
                cultural contexts.
                <br /> <br />
                &bull; Support the implementation of family-focused initiatives,
                including community childcare hubs, after-school care, and early
                childhood development programs
              </Typography>
            </Box>

            <Box className="ml-8 mr-8 py-8">
              <Typography
                variant="subtitle1"
                fontSize={{ xs: "12px", sm: "14px" }}
                lineHeight={"102%"}
                letterSpacing={"0.1em"}
                className="uppercase text-center"
              >
                Infrastructure Development
              </Typography>
              <Typography
                variant="body1"
                fontSize={{ xs: "12px", sm: "14px", md: "14px" }}
                lineHeight={"150%"}
                letterSpacing={"0.02em"}
                className="py-4  text-center  "
              >
                &bull; Collaborate with governments to establish licensed
                childcare centers and family-care facilities.
                <br /> <br />
                &bull; Provide frameworks for training and certifying
                caregivers, ensuring quality and safety standards.
              </Typography>
            </Box>
            <Box className="ml-8 mr-8 py-8">
              <Typography
                variant="subtitle1"
                fontSize={{ xs: "12px", sm: "14px" }}
                lineHeight={"102%"}
                letterSpacing={"0.1em"}
                className="uppercase text-center"
              >
                Subsidy and Support Systems
              </Typography>
              <Typography
                variant="body1"
                fontSize={{ xs: "12px", sm: "14px", md: "15px" }}
                lineHeight={"150%"}
                letterSpacing={"0.02em"}
                className="py-4  text-center  "
              >
                &bull; Design and implement effective childcare subsidy programs
                that reduce financial barriers for families.
                <br /> <br />
                &bull; Ensure equitable access to childcare across diverse
                socioeconomic groups.
              </Typography>
            </Box>

            <Box className="ml-8 mr-8 py-8">
              <Typography
                variant="subtitle1"
                fontSize={{ xs: "12px", sm: "14px" }}
                lineHeight={"102%"}
                letterSpacing={"0.1em"}
                className="uppercase text-center"
              >
                Policy Recommendations
              </Typography>
              <Typography
                variant="body1"
                fontSize={{ xs: "12px", sm: "14px", md: "14px" }}
                lineHeight={"150%"}
                letterSpacing={"0.02em"}
                className="py-4  text-center  "
              >
                &bull; Partner with policymakers to craft impactful legislation
                that supports childcare and caregiving infrastructure.
                <br /> <br />
                &bull; Provide advisory on programs such as paid parental leave,
                tax credits for childcare expenses, and social protections for
                working parents.
              </Typography>
            </Box>

            <Box className="ml-8 mr-8 py-8">
              <Typography
                variant="subtitle1"
                fontSize={{ xs: "12px", sm: "14px" }}
                lineHeight={"102%"}
                letterSpacing={"0.1em"}
                className="uppercase text-center"
              >
                Care Workforce Development
              </Typography>
              <Typography
                variant="body1"
                fontSize={{ xs: "12px", sm: "14px", md: "14px" }}
                lineHeight={"150%"}
                letterSpacing={"0.02em"}
                className="py-4  text-center  "
              >
                &bull; Build a sustainable care workforce by providing training,
                certification, and career pathways for caregivers.
                <br />
                <br />
                &bull; Elevate the caregiving profession through skill
                development programs, ensuring families have access to
                competent, reliable care providers.
                <br /> <br />
                &bull; Strengthen labor markets by creating formal jobs in the
                care sector, boosting local employment and economic resilience.
              </Typography>
            </Box>

            {/* &bull; */}
          </Box>

          {/* Mobile */}

          <Box className="md:hidden block items-center place-content-center mx-auto text-center">
            <Box className="ml-8 mr-8 py-8">
              <Typography
                variant="subtitle1"
                fontSize={{ xs: "12px", sm: "14px" }}
                lineHeight={"102%"}
                letterSpacing={"0.1em"}
                className="uppercase text-center"
              >
                Data-Driven Insights
              </Typography>
              <Typography
                variant="body1"
                fontSize={{ xs: "12px", sm: "14px", md: "14px" }}
                lineHeight={"150%"}
                letterSpacing={"0.02em"}
                className="py-4  text-center  "
              >
                &bull; Leverage our research and analytics to understand
                childcare gaps and opportunities in your communities.
                <br /> <br />
                &bull; Receive actionable data on how childcare impacts
                workforce participation, gender equity, and economic output.
              </Typography>
            </Box>

            <Box className="ml-8 mr-8 py-8">
              <Typography
                variant="subtitle1"
                fontSize={{ xs: "12px", sm: "14px" }}
                lineHeight={"102%"}
                letterSpacing={"0.1em"}
                className="uppercase text-center"
              >
                Community-Engaged Childcare Programs
              </Typography>
              <Typography
                variant="body1"
                fontSize={{ xs: "12px", sm: "14px", md: "14px" }}
                lineHeight={"150%"}
                letterSpacing={"0.02em"}
                className="py-4  text-center  "
              >
                &bull; Develop childcare models that align with local needs and
                cultural contexts.
                <br /> <br />
                &bull; Support the implementation of family-focused initiatives,
                including community childcare hubs, after-school care, and early
                childhood development programs
              </Typography>
            </Box>

            <Box className="ml-8 mr-8 py-8">
              <Typography
                variant="subtitle1"
                fontSize={{ xs: "12px", sm: "14px" }}
                lineHeight={"102%"}
                letterSpacing={"0.1em"}
                className="uppercase text-center"
              >
                Infrastructure Development
              </Typography>
              <Typography
                variant="body1"
                fontSize={{ xs: "12px", sm: "14px", md: "14px" }}
                lineHeight={"150%"}
                letterSpacing={"0.02em"}
                className="py-4  text-center  "
              >
                &bull; Collaborate with governments to establish licensed
                childcare centers and family-care facilities.
                <br /> <br />
                &bull; Provide frameworks for training and certifying
                caregivers, ensuring quality and safety standards.
              </Typography>
            </Box>
            <Box className="ml-8 mr-8 py-8">
              <Typography
                variant="subtitle1"
                fontSize={{ xs: "12px", sm: "14px" }}
                lineHeight={"102%"}
                letterSpacing={"0.1em"}
                className="uppercase text-center"
              >
                Subsidy and Support Systems
              </Typography>
              <Typography
                variant="body1"
                fontSize={{ xs: "12px", sm: "14px", md: "15px" }}
                lineHeight={"150%"}
                letterSpacing={"0.02em"}
                className="py-4  text-center  "
              >
                &bull; Design and implement effective childcare subsidy programs
                that reduce financial barriers for families.
                <br /> <br />
                &bull; Ensure equitable access to childcare across diverse
                socioeconomic groups.
              </Typography>
            </Box>

            <Box className="ml-8 mr-8 py-8">
              <Typography
                variant="subtitle1"
                fontSize={{ xs: "12px", sm: "14px" }}
                lineHeight={"102%"}
                letterSpacing={"0.1em"}
                className="uppercase text-center"
              >
                Policy Recommendations
              </Typography>
              <Typography
                variant="body1"
                fontSize={{ xs: "12px", sm: "14px", md: "14px" }}
                lineHeight={"150%"}
                letterSpacing={"0.02em"}
                className="py-4  text-center  "
              >
                &bull; Partner with policymakers to craft impactful legislation
                that supports childcare and caregiving infrastructure.
                <br /> <br />
                &bull; Provide advisory on programs such as paid parental leave,
                tax credits for childcare expenses, and social protections for
                working parents.
              </Typography>
            </Box>

            <Box className="ml-8 mr-8 py-8">
              <Typography
                variant="subtitle1"
                fontSize={{ xs: "12px", sm: "14px" }}
                lineHeight={"102%"}
                letterSpacing={"0.1em"}
                className="uppercase text-center"
              >
                Care Workforce Development
              </Typography>
              <Typography
                variant="body1"
                fontSize={{ xs: "12px", sm: "14px", md: "14px" }}
                lineHeight={"150%"}
                letterSpacing={"0.02em"}
                className="py-4  text-center  "
              >
                &bull; Build a sustainable care workforce by providing training,
                certification, and career pathways for caregivers.
                <br />
                <br />
                &bull; Elevate the caregiving profession through skill
                development programs, ensuring families have access to
                competent, reliable care providers.
                <br /> <br />
                &bull; Strengthen labor markets by creating formal jobs in the
                care sector, boosting local employment and economic resilience.
              </Typography>
            </Box>

            {/* <Typography
              variant="body1"
              fontSize={{ xs: "12px" }}
              lineHeight={"170%"}
              letterSpacing={"0.02em"}
              className=" py-3 text-center"
            >
              Employees need help navigating life hurdles and
              <br /> the daily transitions between caregiving,
              <br /> work and life.
            </Typography> */}
          </Box>

          {/* <Box className="mx-auto text-center py-10 md:py-0">
            <MHButton
              sx={{
                width: 'fit-content'
              }}
              onClick={handleOpen}>
              Request a Demo
            </MHButton>
          </Box> */}
        </Box>

        <hr className="hidden md:block w-[90%] mx-auto h-0 border-t-lilac-300 mt-10" />
      </Box>
    </React.Fragment>
  );
};

export default GovtApproachSection;
