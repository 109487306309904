import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";

import MHFormControl from "../Form/MHFormControl";
import MHButton from "../Button/MHButton";
import InputAdornment from "../Form/InputAdornment";
import IconButton from "../Button/IconButtonStyled";
import useHttp from "../../../hooks/use-http";

import * as validators from "../../../utils/validators";

import { useOnboardForm } from "../../../store/context/onboard-context";

import SnackbarContext from "../../../store/context/snackbar.context";

import { Divider } from "@mui/material";
import { calculateSimilarity } from "../../../hooks/check-similarity";
import { splitName } from "../../../hooks/split-name";
import BackdropLoader from "../Loading/BackdropLoader";
import { MHPagination } from "../UI/MHPagination";
// import BackdropLoader from "../Loading/BackdropLoader";

// interface SelectOption<T> {
//   label: string;
//   value: T;
// }

// const possibleMatches = [
//   {
//     centerId: 1000,
//     title: "Baby Lounge Magodo",
//     address: "3 salvation street, Magodo, Lagos 23401",
//   },
//   {
//     centerId: 1224,
//     title: "Baby Lounge VI",
//     address: "3 salvation street, VI, Lagos 23401",
//   },
//   {
//     centerId: 3200,
//     title: "Lo Studio",
//     address: "3 salvation street, VI, Lagos 23401",
//   },
// ];

const ClaimBusiness = () => {
  const { loading, error, sendHttpRequest: onboardEmployee } = useHttp();

  const snackbarCtx = React.useContext(SnackbarContext);
  const { toast } = snackbarCtx;

  const { form, setActiveStep, addToOnboard } = useOnboardForm();

  // console.log("whats", form.whatsapp);
  // console.log("finalSubType", finalSubType);

  const handleClaim = (center: any) => {
    const { firstName, lastName } = splitName(center.legalBusinessName);
    // console.log(center);
    addToOnboard({
      centerId: center.customerId,
      firstname: firstName,
      lastname: lastName,
    });
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleNoClaim = () => {
    addToOnboard({
      centerId: 0,
      firstname: "",
      lastname: "",
    });
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  // const onboardEmployeeHandler = async (
  //   event: React.ChangeEvent<HTMLFormElement>
  // ) => {
  //   // emailInputRef.current!.focus();
  //   event.preventDefault();

  //   // console.log("Complete Form");
  //   toast({
  //     message: "Please input all required field",
  //     variant: "warning",
  //   });

  //   // let newPhoneNo = enteredPhone;

  //   // if (newPhoneNo?.startsWith("0")) {
  //   //   newPhoneNo = "+234" + newPhoneNo.slice(1);
  //   // } else if (newPhoneNo?.startsWith("234")) {
  //   //   newPhoneNo = "+234" + newPhoneNo.slice(3);
  //   // } else if (newPhoneNo?.startsWith("+234")) {
  //   //   return newPhoneNo;
  //   // } else {
  //   //   // Handle other cases if necessary
  //   //   return;
  //   // }

  //   // console.log("employeeInfo", employeeInfo);
  //   // console.log("formIsValid", formIsValid);

  //   onboardEmployee(
  //     process.env.REACT_APP_API_BASE_URL + "/employee/caringblock/employee",
  //     {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       // body: JSON.stringify(employeeInfo),
  //     },
  //     () => {
  //       // onClose();
  //       // eslint-disable-next-line @typescript-eslint/no-unused-expressions

  //       toast({
  //         message: "Successfully onboarded employee.",
  //         variant: "success",
  //       });
  //     }
  //   );
  // };

  const [inputAddress, setInputAddress] = useState({
    address: form.address,
    state: form.state,
  });

  const [loader, setLoader] = useState<boolean>(false);

  const [matchedAddresses, setMatchedAddresses] = useState<any[]>([]);

  const searchAddress = async (inputAddress: any) => {
    setLoader(true);
    const API_URL =
      process.env.REACT_APP_CARE_CENTER_API + "/search?status=ACTIVE"; // Replace with your API endpoint

    try {
      // Fetch addresses from your database API
      const response = await fetch(API_URL, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`API error: ${response.status} ${response.statusText}`);
      }

      const databaseAddresses = await response.json();

      if (!databaseAddresses?.data || !Array.isArray(databaseAddresses.data)) {
        setLoader(false);
        throw new Error("Unexpected API response structure");
      }

      // Normalize a string: lowercase, trimmed, no extra spaces
      const normalize = (str: string) =>
        str?.toLowerCase().replace(/\s+/g, " ").trim() || "";

      const normalizedInput = {
        address: normalize(inputAddress.address),
        state: normalize(inputAddress.state),
      };

      // console.log("Normalized Input:", normalizedInput);

      // Filter database addresses for matches based on merged address (address + city/lcda)
      const matchedAddresses = databaseAddresses.data
        .map((dbAddress: any) => {
          const normalizedDbAddress = {
            fullAddress: normalize(dbAddress.address + " " + dbAddress.city), // Merged address + city (lcda)
            state: normalize(dbAddress.State),
          };

          // Partial match: check if input address is part of the full merged address
          const addressPartialMatch = normalizedDbAddress.fullAddress.includes(
            normalizedInput.address
          );

          // Calculate similarity score if needed
          const similarityScore = calculateSimilarity(
            normalizedInput.address,
            normalizedDbAddress.fullAddress
          );

          // Check if state matches exactly
          const isStateMatch =
            normalizedDbAddress.state === normalizedInput.state;

          return {
            ...dbAddress,
            addressPartialMatch,
            similarityScore,
            isStateMatch,
          };
        })
        .filter(
          (result: any) =>
            result.isStateMatch &&
            (result.addressPartialMatch || result.similarityScore > 0.2) // Include partial match or if similarity score is above threshold
        );

      // Sort by similarity score (highest first)
      matchedAddresses.sort(
        (a: any, b: any) => b.similarityScore - a.similarityScore
      );

      const filteredAdds = matchedAddresses.filter(
        (center: any) => center.verified === false
      );

      console.log("Filtered Addresses:", filteredAdds);

      // Save matched addresses to state
      setMatchedAddresses(filteredAdds);
      setLoader(false);
    } catch (error: any) {
      setLoader(false);
      console.error("Error searching address:", error.message || error);
    }
  };

  // Trigger the search when input address changes
  React.useEffect(() => {
    searchAddress(inputAddress);
  }, [inputAddress]);

  const [currentPage, setCurrentPage] = React.useState(1);
  const [postsPerPage, setPostsPerPage] = React.useState(4);
  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const currentPosts = matchedAddresses.slice(firstPostIndex, lastPostIndex);

  let itemsPerPage = 4;
  let noOfPage = Math.ceil((matchedAddresses?.length || 0) / itemsPerPage);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
  };

  return (
    <React.Fragment>
      <BackdropLoader open={loader} />

      <Box className="text-center">
        <Typography
          variant="h1"
          fontSize={{ xs: "24px", sm: "28px", md: "28px" }}
          component="div"
          gutterBottom
          mb={5}
        >
          Claim your business
        </Typography>
      </Box>
      <Paper
        sx={{
          px: 3,
          py: 3,
          boxShadow: "0px 10px 16px rgba(154, 154, 154, 0.13)",
        }}
        className="h-full max-w-md mx-auto"
      >
        {error && (
          <Alert
            severity="error"
            sx={{
              mb: 3,
            }}
          >
            {error.message}
          </Alert>
        )}

        {/* autoComplete="off"
            noValidate */}
        <Box>
          {currentPosts.length > 0 ? (
            currentPosts.map((center, index) => (
              <>
                <Box className=" flex flex-row items-center" key={index}>
                  <Box className=" basis-[80%]">
                    <Typography
                      variant="subtitle1"
                      fontSize={{ xs: "16px", sm: "16px", md: "16px" }}
                      component="div"
                      gutterBottom
                      // mb={5}
                      className=" line-clamp-2"
                    >
                      {center.legalBusinessName}
                    </Typography>
                    <Typography
                      variant="body1"
                      fontSize={{ xs: "15px", sm: "15px", md: "15px" }}
                      component="div"
                      gutterBottom
                      // mb={5}
                      className=" line-clamp-2"
                    >
                      {`${center.address}, ${center.State}`}
                    </Typography>
                  </Box>
                  <Box className=" basis-[20%] text-end">
                    <MHButton
                      sx={{
                        borderRadius: "25px",
                        height: "35px",
                        width: "80px",
                      }}
                      type="button"
                      // loading={loading}
                      onClick={(e) => handleClaim(center)}
                      fullWidth
                    >
                      Claim
                    </MHButton>
                  </Box>
                </Box>
                <Divider className=" w-full mb-8 mt-3" />
              </>
            ))
          ) : (
            <>
              <Box className=" flex flex-row items-center">
                <Box className=" basis-[80%]">
                  <Typography
                    variant="subtitle1"
                    fontSize={{ xs: "16px", sm: "16px", md: "16px" }}
                    component="div"
                    gutterBottom
                    // mb={5}
                    className=" line-clamp-2"
                  >
                    Match not Found
                  </Typography>
                  <Typography
                    variant="body1"
                    fontSize={{ xs: "15px", sm: "15px", md: "15px" }}
                    component="div"
                    gutterBottom
                    // mb={5}
                    className=" line-clamp-2"
                  >
                    Possible matches: 0
                  </Typography>
                </Box>
              </Box>
            </>
          )}

          {/* <MHButton sx={{}} type="submit" loading={loading} fullWidth>
            Continue
          </MHButton> */}

          <Box className=" text-center">
            <MHButton
              type="button"
              onClick={handleNoClaim}
              variant="text"
              fullWidth
              className="text-blue-100 removeUnderline text-center"
            >
              <Typography
                variant="subtitle2"
                color="primary"
                fontSize={{ xs: "14px", sm: "14px", md: "14px" }}
                lineHeight={{ xs: "130%", sm: "160.5%" }}
                letterSpacing={"-4%"}
                className="pt-6 text-center text-blue-100 removeUnderline"
                width={{ xs: "80%", sm: "100%" }}
              >
                No, my business is not listed here
              </Typography>
            </MHButton>
          </Box>
        </Box>

        <Box display={"flex"} justifyContent={"center"} my={2}>
          <MHPagination
            count={noOfPage}
            variant="outlined"
            shape="rounded"
            page={currentPage}
            onChange={handlePageChange}
            color={"primary"}
          />
        </Box>
      </Paper>
    </React.Fragment>
  );
};

export default ClaimBusiness;
